import React, { createContext, useContext, useState } from 'react';

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
  const [currentColor, setCurrentColor] = useState('#ECCB7B');

  // Declare as funções antes do objeto do StateContext.Provider
  const contextValues = {
    currentColor,
    setCurrentColor,
  };

  return (
      <StateContext.Provider value={contextValues}>
        {children}
      </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
