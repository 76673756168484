import React, {useEffect, useState} from "react";
import {cadeiraOcupada, cadeiraSelecionada, cadeiraVazia} from "../../images";
import {View, FlatList, TouchableOpacity, Image} from "react-native";
import * as row1 from "react-bootstrap/ElementChildren";

import {database} from "../../firebase";
import {ref, onValue} from 'firebase/database';

const PlantaCard = ({handleEscolhaChange, notifica}) => {

    const [lugar01, setLugar01] = useState("");
    const [lugar02, setLugar02] = useState("");
    const [lugar03, setLugar03] = useState("");
    const [lugar04, setLugar04] = useState("");
    const [lugar05, setLugar05] = useState("");
    const [lugar06, setLugar06] = useState("");
    const [lugar07, setLugar07] = useState("");
    const [lugar08, setLugar08] = useState("");
    const [lugar09, setLugar09] = useState("");
    const [lugar10, setLugar10] = useState("");
    const [lugar11, setLugar11] = useState("");
    const [lugar12, setLugar12] = useState("");
    const [lugar13, setLugar13] = useState("");
    const [lugar14, setLugar14] = useState("");
    const [lugar15, setLugar15] = useState("");
    const [lugar16, setLugar16] = useState("");
    const [lugar17, setLugar17] = useState("");
    const [lugar18, setLugar18] = useState("");
    const [lugar19, setLugar19] = useState("");
    const [lugar20, setLugar20] = useState("");
    const [lugar21, setLugar21] = useState("");
    const [lugar22, setLugar22] = useState("");
    const [lugar23, setLugar23] = useState("");
    const [lugar24, setLugar24] = useState("");
    const [lugar25, setLugar25] = useState("");
    const [lugar26, setLugar26] = useState("");
    const [lugar27, setLugar27] = useState("");
    const [lugar28, setLugar28] = useState("");
    const [lugar29, setLugar29] = useState("");

    const plantaRef = ref(database, "Planta/-CoworkFundao");

    useEffect(() => {
        onValue(plantaRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setLugar01(data.lugar01);
                setLugar02(data.lugar02);
                setLugar03(data.lugar03);
                setLugar04(data.lugar04);
                setLugar05(data.lugar05);
                setLugar06(data.lugar06);
                setLugar07(data.lugar07);
                setLugar08(data.lugar08);
                setLugar09(data.lugar09);
                setLugar10(data.lugar10);
                setLugar11(data.lugar11);
                setLugar12(data.lugar12);
                setLugar13(data.lugar13);
                setLugar14(data.lugar14);
                setLugar15(data.lugar15);
                setLugar16(data.lugar16);
                setLugar17(data.lugar17);
                setLugar18(data.lugar18);
                setLugar19(data.lugar19);
                setLugar20(data.lugar20);
                setLugar21(data.lugar21);
                setLugar22(data.lugar22);
                setLugar23(data.lugar23);
                setLugar24(data.lugar24);
                setLugar25(data.lugar25);
                setLugar26(data.lugar26);
                setLugar27(data.lugar27);
                setLugar28(data.lugar28);
                setLugar29(data.lugar29);
            } else {
                console.log("No such document!");
            }
        });
    }, []);


    const [oneSelected, setOneSelected] = useState(false);

    const handleSelect = () => {
        setOneSelected(true);
    };

    console.log(lugar01)

    const [row0col0, setrow0col0] = useState([
        {lugar:16, empty: lugar16, selected: false},
        {lugar:14, empty: lugar14, selected: false},
    ]);
    useEffect(() => {
        setrow0col0((prevRow0col0) => {
            const updatedRow0col0 = [...prevRow0col0];
            updatedRow0col0[0].empty = lugar16;
            updatedRow0col0[1].empty = lugar14;
            return updatedRow0col0;
        });
    }, [lugar16, lugar14]);
    const [row0col1, setrow0col1] = useState([
        {lugar:13,empty: lugar13, selected: false},
    ]);
    useEffect(() => {
        setrow0col1((prevRow0col1) => {
            const updatedRow0col1 = [...prevRow0col1];
            updatedRow0col1[0].empty = lugar13;
            return updatedRow0col1;
        });
    }, [lugar13]);
    const [row0col2, setrow0col2] = useState([
        {lugar:9,empty: lugar09, selected: false},
        {lugar:8,empty: lugar08, selected: false},
    ]);
    useEffect(() => {
        setrow0col2((prevRow0col2) => {
            const updatedRow0col2 = [...prevRow0col2];
            updatedRow0col2[0].empty = lugar09;
            updatedRow0col2[1].empty = lugar08;
            return updatedRow0col2;
        });
    }, [lugar09, lugar08]);
    const [row0col3, setrow0col3] = useState([
        {lugar:7,empty: lugar07, selected: false},
        {lugar:6,empty: lugar06, selected: false},
    ]);
    useEffect(() => {
        setrow0col3((prevRow0col3) => {
            const updatedRow0col3 = [...prevRow0col3];
            updatedRow0col3[0].empty = lugar07;
            updatedRow0col3[1].empty = lugar06;
            return updatedRow0col3;
        });
    }, [lugar07, lugar06]);
    const [row1col0, setrow1col0] = useState([
        {lugar:17,empty: lugar17, selected: false},
        {lugar:15,empty: lugar15, selected: false},
    ]);
    useEffect(() => {
        setrow1col0((prevRow1col0) => {
            const updatedRow1col0 = [...prevRow1col0];
            updatedRow1col0[0].empty = lugar17;
            updatedRow1col0[1].empty = lugar15;
            return updatedRow1col0;
        });
    }, [lugar17, lugar15]);
    const [row1col1, setrow1col1] = useState([
        {lugar:12,empty: lugar12, selected: false},
    ]);
    useEffect(() => {
        setrow1col1((prevRow1col1) => {
            const updatedRow1col1 = [...prevRow1col1];
            updatedRow1col1[0].empty = lugar12;
            return updatedRow1col1;
        });
    }, [lugar12]);
    const [row1col2, setrow1col2] = useState([
        {lugar:11,empty: lugar11, selected: false},
        {lugar:10,empty: lugar10, selected: false},
    ]);
    useEffect(() => {
        setrow1col2((prevRow1col2) => {
            const updatedRow1col2 = [...prevRow1col2];
            updatedRow1col2[0].empty = lugar11;
            updatedRow1col2[1].empty = lugar10;
            return updatedRow1col2;
        });
    }, [lugar11, lugar10]);
    const [row1col3, setrow1col3] = useState([
        {lugar:5,empty: lugar05, selected: false},
    ]);
    useEffect(() => {
        setrow1col3((prevRow1col3) => {
            const updatedRow1col3 = [...prevRow1col3];
            updatedRow1col3[0].empty = lugar05;
            return updatedRow1col3;
        });
    }, [lugar05]);
    const [row2col0, setrow2col0] = useState([
        {lugar:18,empty: lugar18, selected: false},
        {lugar:19,empty: lugar19, selected: false},
        {lugar:20,empty: lugar20, selected: false},
    ]);
    useEffect(() => {
        setrow2col0((prevRow2col0) => {
            const updatedRow2col0 = [...prevRow2col0];
            updatedRow2col0[0].empty = lugar18;
            updatedRow2col0[1].empty = lugar19;
            updatedRow2col0[2].empty = lugar20;
            return updatedRow2col0;
        });
    }, [lugar18, lugar19, lugar20]);
    const [row2col1, setrow2col1] = useState([
        {lugar:27,empty: lugar27, selected: false},
    ]);
    useEffect(() => {
        setrow2col1((prevRow2col1) => {
            const updatedRow2col1 = [...prevRow2col1];
            updatedRow2col1[0].empty = lugar27;
            return updatedRow2col1;
        });
    }, [lugar27]);
    const [row2col2, setrow2col2] = useState([
        {lugar:29,empty: lugar29, selected: false},
    ]);
    useEffect(() => {
        setrow2col2((prevRow2col2) => {
            const updatedRow2col2 = [...prevRow2col2];
            updatedRow2col2[0].empty = lugar29;
            return updatedRow2col2;
        });
    }, [lugar29]);
    const [row2col3, setrow2col3] = useState([
        {lugar:4,empty: lugar04, selected: false},
        {lugar:3,empty: lugar03, selected: false},
    ]);
    useEffect(() => {
        setrow2col3((prevRow2col3) => {
            const updatedRow2col3 = [...prevRow2col3];
            updatedRow2col3[0].empty = lugar04;
            updatedRow2col3[1].empty = lugar03;
            return updatedRow2col3;
        });
    }, [lugar04, lugar03]);
    const [row3col0, setrow3col0] = useState([
        {lugar:21,empty: lugar21, selected: false},
        {lugar:22,empty: lugar22, selected: false},
        {lugar:23,empty: lugar23, selected: false},
    ]);
    useEffect(() => {
        setrow3col0((prevRow3col0) => {
            const updatedRow3col0 = [...prevRow3col0];
            updatedRow3col0[0].empty = lugar21;
            updatedRow3col0[1].empty = lugar22;
            updatedRow3col0[2].empty = lugar23;
            return updatedRow3col0;
        });
    }, [lugar21, lugar22, lugar23]);
    const [row3col1, setrow3col1] = useState([
        {lugar:26,empty: lugar26, selected: false},
    ]);
    useEffect(() => {
        setrow3col1((prevRow3col1) => {
            const updatedRow3col1 = [...prevRow3col1];
            updatedRow3col1[0].empty = lugar26;
            return updatedRow3col1;
        });
    }, [lugar26]);
    const [row3col2, setrow3col2] = useState([
        {lugar:28,empty: lugar28, selected: false},
    ]);
    useEffect(() => {
        setrow3col2((prevRow3col2) => {
            const updatedRow3col2 = [...prevRow3col2];
            updatedRow3col2[0].empty = lugar28;
            return updatedRow3col2;
        });
    }, [lugar28]);
    const [row3col3, setrow3col3] = useState([
        {lugar:2,empty: lugar02, selected: false},
        {lugar:1,empty: lugar01, selected: false},
    ]);
    useEffect(() => {
        setrow3col3((prevRow3col3) => {
            const updatedRow3col3 = [...prevRow3col3];
            updatedRow3col3[0].empty = lugar02;
            updatedRow3col3[1].empty = lugar01;
            return updatedRow3col3;
        });
    }, [lugar01, lugar02]);

    const [row4col0, setrow4col0] = useState([
        {lugar:24,empty: lugar24, selected: false},
        {lugar:25,empty: lugar25, selected: false},
    ]);
    useEffect(() => {
        setrow4col0((prevRow4col0) => {
            const updatedRow4col0 = [...prevRow4col0];
            updatedRow4col0[0].empty = lugar24;
            updatedRow4col0[1].empty = lugar25;
            return updatedRow4col0;
        });
    }, [lugar24, lugar25]);

    const onSelectrow0col0 = (index) => {
        let tempRow = [];
        tempRow = row0col0;
        tempRow.map((item, i) => {
            if (i === index) {
                if (item.selected === true && oneSelected === true){
                    item.selected = false;
                    item.empty = true;
                    setOneSelected(false);
                }else if (item.selected === false && oneSelected === false){
                    item.selected = true;
                    item.empty = false;
                } else {
                    notifica("warning", "Já tem um lugar marcado, se pretender alterar pressione no mesmo e altere para um lugar disponivel.", 4000);
                }
            }
        })

        let tempSeats = [];
        tempRow.map(item => {
            tempSeats.push(item);
        });
        setrow0col0(tempSeats);
    };
    const onSelectrow0col1 = (index) => {
        let tempRow = [];
        tempRow = row0col1;
        tempRow.map((item, i) => {
            if (i === index) {
                if (item.selected === true && oneSelected === true){
                    item.selected = false;
                    item.empty = true;
                    setOneSelected(false);
                }else if (item.selected === false && oneSelected === false){
                    item.selected = true;
                    item.empty = false;
                } else {
                    notifica("warning", "Já tem um lugar marcado, se pretender alterar pressione no mesmo e altere para um lugar disponivel.", 4000);
                }
            }
        });

        let tempSeats = [];
        tempRow.map(item => {
            tempSeats.push(item);
        });
        setrow0col1(tempSeats);
    };
    const onSelectrow0col2 = (index) => {
        let tempRow = [];
        tempRow = row0col2;
        tempRow.map((item, i) => {
            if (i === index) {
                if (item.selected === true && oneSelected === true){
                    item.selected = false;
                    item.empty = true;
                    setOneSelected(false);
                }else if (item.selected === false && oneSelected === false){
                    item.selected = true;
                    item.empty = false;
                } else {
                    notifica("warning", "Já tem um lugar marcado, se pretender alterar pressione no mesmo e altere para um lugar disponivel.", 4000);
                }
            }
        });

        let tempSeats = [];
        tempRow.map(item => {
            tempSeats.push(item);
        });
        setrow0col2(tempSeats);
    };
    const onSelectrow0col3 = (index) => {
        let tempRow = [];
        tempRow = row0col3;
        tempRow.map((item, i) => {
            if (i === index) {
                if (item.selected === true && oneSelected === true){
                    item.selected = false;
                    item.empty = true;
                    setOneSelected(false);
                }else if (item.selected === false && oneSelected === false){
                    item.selected = true;
                    item.empty = false;
                } else {
                    notifica("warning", "Já tem um lugar marcado, se pretender alterar pressione no mesmo e altere para um lugar disponivel.", 4000);
                }
            }
        });

        let tempSeats = [];
        tempRow.map(item => {
            tempSeats.push(item);
        });
        setrow0col3(tempSeats);
    };
    const onSelectrow1col0 = (index) => {
        let tempRow = [];
        tempRow = row1col0;
        tempRow.map((item, i) => {
            if (i === index) {
                if (item.selected === true && oneSelected === true){
                    item.selected = false;
                    item.empty = true;
                    setOneSelected(false);
                }else if (item.selected === false && oneSelected === false){
                    item.selected = true;
                    item.empty = false;
                } else {
                    notifica("warning", "Já tem um lugar marcado, se pretender alterar pressione no mesmo e altere para um lugar disponivel.", 4000);
                }
            }
        });

        let tempSeats = [];
        tempRow.map(item => {
            tempSeats.push(item);
        });
        setrow1col0(tempSeats);
    };
    const onSelectrow1col1 = (index) => {
        let tempRow = [];
        tempRow = row1col1;
        tempRow.map((item, i) => {
            if (i === index) {
                if (item.selected === true && oneSelected === true){
                    item.selected = false;
                    item.empty = true;
                    setOneSelected(false);
                }else if (item.selected === false && oneSelected === false){
                    item.selected = true;
                    item.empty = false;
                } else {
                    notifica("warning", "Já tem um lugar marcado, se pretender alterar pressione no mesmo e altere para um lugar disponivel.", 4000);
                }
            }
        });

        let tempSeats = [];
        tempRow.map(item => {
            tempSeats.push(item);
        });
        setrow1col1(tempSeats);
    };
    const onSelectrow1col2 = (index) => {
        let tempRow = [];
        tempRow = row1col2;
        tempRow.map((item, i) => {
            if (i === index) {
                if (item.selected === true && oneSelected === true){
                    item.selected = false;
                    item.empty = true;
                    setOneSelected(false);
                }else if (item.selected === false && oneSelected === false){
                    item.selected = true;
                    item.empty = false;
                } else {
                    notifica("warning", "Já tem um lugar marcado, se pretender alterar pressione no mesmo e altere para um lugar disponivel.", 4000);
                }
            }
        });

        let tempSeats = [];
        tempRow.map(item => {
            tempSeats.push(item);
        });
        setrow1col2(tempSeats);
    };
    const onSelectrow1col3 = (index) => {
        let tempRow = [];
        tempRow = row1col3;
        tempRow.map((item, i) => {
            if (i === index) {
                if (item.selected === true && oneSelected === true){
                    item.selected = false;
                    item.empty = true;
                    setOneSelected(false);
                }else if (item.selected === false && oneSelected === false){
                    item.selected = true;
                    item.empty = false;
                } else {
                    notifica("warning", "Já tem um lugar marcado, se pretender alterar pressione no mesmo e altere para um lugar disponivel.", 4000);
                }
            }
        });

        let tempSeats = [];
        tempRow.map(item => {
            tempSeats.push(item);
        });
        setrow1col3(tempSeats);
    };
    const onSelectrow2col0 = (index) => {
        let tempRow = [];
        tempRow = row2col0;
        tempRow.map((item, i) => {
            if (i === index) {
                if (item.selected === true && oneSelected === true){
                    item.selected = false;
                    item.empty = true;
                    setOneSelected(false);
                }else if (item.selected === false && oneSelected === false){
                    item.selected = true;
                    item.empty = false;
                } else {
                    notifica("warning", "Já tem um lugar marcado, se pretender alterar pressione no mesmo e altere para um lugar disponivel.", 4000);
                }
            }
        });

        let tempSeats = [];
        tempRow.map(item => {
            tempSeats.push(item);
        });
        setrow2col0(tempSeats);
    };
    const onSelectrow2col1 = (index) => {
        let tempRow = [];
        tempRow = row2col1;
        tempRow.map((item, i) => {
            if (i === index) {
                if (item.selected === true && oneSelected === true){
                    item.selected = false;
                    item.empty = true;
                    setOneSelected(false);
                }else if (item.selected === false && oneSelected === false){
                    item.selected = true;
                    item.empty = false;
                } else {
                    notifica("warning", "Já tem um lugar marcado, se pretender alterar pressione no mesmo e altere para um lugar disponivel.", 4000);
                }
            }
        });

        let tempSeats = [];
        tempRow.map(item => {
            tempSeats.push(item);
        });
        setrow2col1(tempSeats);
    };
    const onSelectrow2col2 = (index) => {
        let tempRow = [];
        tempRow = row2col2;
        tempRow.map((item, i) => {
            if (i === index) {
                if (item.selected === true && oneSelected === true){
                    item.selected = false;
                    item.empty = true;
                    setOneSelected(false);
                }else if (item.selected === false && oneSelected === false){
                    item.selected = true;
                    item.empty = false;
                } else {
                    notifica("warning", "Já tem um lugar marcado, se pretender alterar pressione no mesmo e altere para um lugar disponivel.", 4000);
                }
            }
        });

        let tempSeats = [];
        tempRow.map(item => {
            tempSeats.push(item);
        });
        setrow2col2(tempSeats);
    };
    const onSelectrow2col3 = (index) => {
        let tempRow = [];
        tempRow = row2col3;
        tempRow.map((item, i) => {
            if (i === index) {
                if (item.selected === true && oneSelected === true){
                    item.selected = false;
                    item.empty = true;
                    setOneSelected(false);
                }else if (item.selected === false && oneSelected === false){
                    item.selected = true;
                    item.empty = false;
                } else {
                    notifica("warning", "Já tem um lugar marcado, se pretender alterar pressione no mesmo e altere para um lugar disponivel.", 4000);
                }
            }
        });

        let tempSeats = [];
        tempRow.map(item => {
            tempSeats.push(item);
        });
        setrow2col3(tempSeats);
    };
    const onSelectrow3col0 = (index) => {
        let tempRow = [];
        tempRow = row3col0;
        tempRow.map((item, i) => {
            if (i === index) {
                if (item.selected === true && oneSelected === true){
                    item.selected = false;
                    item.empty = true;
                    setOneSelected(false);
                }else if (item.selected === false && oneSelected === false){
                    item.selected = true;
                    item.empty = false;
                } else {
                    notifica("warning", "Já tem um lugar marcado, se pretender alterar pressione no mesmo e altere para um lugar disponivel.", 4000);
                }
            }
        });

        let tempSeats = [];
        tempRow.map(item => {
            tempSeats.push(item);
        });
        setrow3col0(tempSeats);
    };
    const onSelectrow3col1 = (index) => {
        let tempRow = [];
        tempRow = row3col1;
        tempRow.map((item, i) => {
            if (i === index) {
                if (item.selected === true && oneSelected === true){
                    item.selected = false;
                    item.empty = true;
                    setOneSelected(false);
                }else if (item.selected === false && oneSelected === false){
                    item.selected = true;
                    item.empty = false;
                } else {
                    notifica("warning", "Já tem um lugar marcado, se pretender alterar pressione no mesmo e altere para um lugar disponivel.", 4000);
                }
            }
        });

        let tempSeats = [];
        tempRow.map(item => {
            tempSeats.push(item);
        });
        setrow3col1(tempSeats);
    };
    const onSelectrow3col2 = (index) => {
        let tempRow = [];
        tempRow = row3col2;
        tempRow.map((item, i) => {
            if (i === index) {
                if (item.selected === true && oneSelected === true){
                    item.selected = false;
                    item.empty = true;
                    setOneSelected(false);
                }else if (item.selected === false && oneSelected === false){
                    item.selected = true;
                    item.empty = false;
                } else {
                    notifica("warning", "Já tem um lugar marcado, se pretender alterar pressione no mesmo e altere para um lugar disponivel.", 4000);
                }
            }
        });

        let tempSeats = [];
        tempRow.map(item => {
            tempSeats.push(item);
        });
        setrow3col2(tempSeats);
    };
    const onSelectrow3col3 = (index) => {
        let tempRow = [];
        tempRow = row3col3;
        tempRow.map((item, i) => {
            if (i === index) {
                if (item.selected === true && oneSelected === true){
                    item.selected = false;
                    item.empty = true;
                    setOneSelected(false);
                }else if (item.selected === false && oneSelected === false){
                    item.selected = true;
                    item.empty = false;
                } else {
                    notifica("warning", "Já tem um lugar marcado, se pretender alterar pressione no mesmo e altere para um lugar disponivel.", 4000);
                }
            }
        });

        let tempSeats = [];
        tempRow.map(item => {
            tempSeats.push(item);
        });
        setrow3col3(tempSeats);
    };
    const onSelectrow4col0 = (index) => {
        let tempRow = [];
        tempRow = row4col0;
        tempRow.map((item, i) => {
            if (i === index) {
                if (item.selected === true && oneSelected === true){
                    item.selected = false;
                    item.empty = true;
                    setOneSelected(false);
                }else if (item.selected === false && oneSelected === false){
                    item.selected = true;
                    item.empty = false;
                } else {
                    notifica("warning", "Já tem um lugar marcado, se pretender alterar pressione no mesmo e altere para um lugar disponivel.", 4000);
                }
            }
        });

        let tempSeats = [];
        tempRow.map(item => {
            tempSeats.push(item);
        });
        setrow4col0(tempSeats);
    };

    const selectedSeats = [];

    const getAllSeats = () => {
        row1.map(item => {
            if (item.selected === true) {
                selectedSeats.push(1);
            }
        });
    };

    return (
        <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
            <View style={{width: "100%", height: "100%", borderWidth: 1, borderRadius: 5, borderColor: "#8e8e8e"}}>
                <View style={{justifyContent: "center", marginTop: 20}}>
                    <View style={{flexDirection: 'row'}}>
                        <FlatList
                            style={{width: '100%', left: 22}}
                            data={row0col0}
                            horizontal
                            renderItem={({item, index}) => {
                                return (
                                    <TouchableOpacity
                                        style={index !== 0 && {marginLeft: 64}}
                                        onPress={() => {
                                            if (item.selected === false && item.empty === false) {
                                                notifica("warning", "O lugar já está reservado", 3000);
                                            } else {
                                                handleSelect();
                                                handleEscolhaChange(item.lugar);
                                                onSelectrow0col0(index);
                                            }
                                        }}
                                    >
                                        {
                                            item.empty === false && item.selected === true ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraSelecionada}/>
                                            ) : item.empty === true && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraVazia}/>
                                            ) : item.empty === false && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraOcupada}/>
                                            ) : null
                                        }
                                    </TouchableOpacity>
                                );
                            }}
                        />
                        <FlatList
                            style={{width: '100%', left: 35}}
                            data={row0col1}
                            horizontal
                            renderItem={({item, index}) => {
                                return (
                                    <TouchableOpacity
                                        onPress={() => {
                                            if (item.selected === false && item.empty === false) {
                                                notifica("warning", "O lugar já está reservado", 3000);
                                            } else {
                                                handleSelect();
                                                handleEscolhaChange(item.lugar);
                                                onSelectrow0col1(index);
                                            }
                                        }}
                                    >
                                        {
                                            item.empty === false && item.selected === true ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraSelecionada}/>
                                            ) : item.empty === true && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraVazia}/>
                                            ) : item.empty === false && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraOcupada}/>
                                            ) : null
                                        }
                                    </TouchableOpacity>
                                );
                            }}
                        />
                        <FlatList
                            style={{width: '100%', right: 18}}
                            data={row0col2}
                            horizontal
                            renderItem={({item, index}) => {
                                return (
                                    <TouchableOpacity
                                        style={index !== 0 && {marginLeft: 58}}
                                        onPress={() => {
                                            if (item.selected === false && item.empty === false) {
                                                notifica("warning", "O lugar já está reservado", 3000);
                                            } else {
                                                handleSelect();
                                                handleEscolhaChange(item.lugar);
                                                onSelectrow0col2(index);
                                            }
                                        }}
                                    >
                                        {
                                            item.empty === false && item.selected === true ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraSelecionada}/>
                                            ) : item.empty === true && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraVazia}/>
                                            ) : item.empty === false && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraOcupada}/>
                                            ) : null
                                        }
                                    </TouchableOpacity>
                                );
                            }}
                        />
                        <FlatList
                            style={{width: '100%', left: -14}}
                            data={row0col3}
                            horizontal
                            renderItem={({item, index}) => {
                                return (
                                    <TouchableOpacity
                                        style={index !== 0 && {marginLeft: 55}}
                                        onPress={() => {
                                            if (item.selected === false && item.empty === false) {
                                                notifica("warning", "O lugar já está reservado", 3000);
                                            } else {
                                                handleSelect();
                                                handleEscolhaChange(item.lugar);
                                                onSelectrow0col3(index);
                                            }
                                        }}
                                    >
                                        {
                                            item.empty === false && item.selected === true ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraSelecionada}/>
                                            ) : item.empty === true && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraVazia}/>
                                            ) : item.empty === false && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraOcupada}/>
                                            ) : null
                                        }
                                    </TouchableOpacity>
                                );
                            }}
                        />
                    </View>

                    <View style={{flexDirection: 'row'}}>
                        <FlatList
                            style={{marginTop:17,marginBottom:44, width: '100%', left: 22}}
                            data={row1col0}
                            horizontal
                            renderItem={({item, index}) => {
                                return (
                                    <TouchableOpacity
                                        style={index !== 0 && {marginLeft: 64}}
                                        onPress={() => {
                                            if (item.selected === false && item.empty === false) {
                                                notifica("warning", "O lugar já está reservado", 3000);
                                            } else {
                                                handleSelect();
                                                handleEscolhaChange(item.lugar);
                                                onSelectrow1col0(index);
                                            }
                                        }}
                                    >
                                        {
                                            item.empty === false && item.selected === true ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraSelecionada}/>
                                            ) : item.empty === true && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraVazia}/>
                                            ) : item.empty === false && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraOcupada}/>
                                            ) : null
                                        }
                                    </TouchableOpacity>
                                );
                            }}
                        />
                        <FlatList
                            style={{marginTop:61, width: '100%', left: 35}}
                            data={row1col1}
                            horizontal
                            renderItem={({item, index}) => {
                                return (
                                    <TouchableOpacity
                                        onPress={() => {
                                            if (item.selected === false && item.empty === false) {
                                                notifica("warning", "O lugar já está reservado", 3000);
                                            } else {
                                                handleSelect();
                                                handleEscolhaChange(item.lugar);
                                                onSelectrow1col1(index);
                                            }
                                        }}
                                    >
                                        {
                                            item.empty === false && item.selected === true ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraSelecionada}/>
                                            ) : item.empty === true && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraVazia}/>
                                            ) : item.empty === false && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraOcupada}/>
                                            ) : null
                                        }
                                    </TouchableOpacity>
                                );
                            }}
                        />
                        <FlatList
                            style={{marginTop:61, width: '100%', right: 18}}
                            data={row1col2}
                            horizontal
                            renderItem={({item, index}) => {
                                return (
                                    <TouchableOpacity
                                        style={index !== 0 && {marginLeft: 58}}
                                        onPress={() => {
                                            if (item.selected === false && item.empty === false) {
                                                notifica("warning", "O lugar já está reservado", 3000);
                                            } else {
                                                handleSelect();
                                                handleEscolhaChange(item.lugar);
                                                onSelectrow1col2(index);
                                            }
                                        }}
                                    >
                                        {
                                            item.empty === false && item.selected === true ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraSelecionada}/>
                                            ) : item.empty === true && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraVazia}/>
                                            ) : item.empty === false && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraOcupada}/>
                                            ) : null
                                        }
                                    </TouchableOpacity>
                                );
                            }}
                        />
                        <FlatList
                            style={{marginTop:61, width: '100%', left: 10}}
                            data={row1col3}
                            horizontal
                            renderItem={({item, index}) => {
                                return (
                                    <TouchableOpacity
                                        onPress={() => {
                                            if (item.selected === false && item.empty === false) {
                                                notifica("warning", "O lugar já está reservado", 3000);
                                            } else {
                                                handleSelect();
                                                handleEscolhaChange(item.lugar);
                                                onSelectrow1col3(index);
                                            }
                                        }}
                                    >
                                        {
                                            item.empty === false && item.selected === true ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraSelecionada}/>
                                            ) : item.empty === true && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraVazia}/>
                                            ) : item.empty === false && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraOcupada}/>
                                            ) : null
                                        }
                                    </TouchableOpacity>
                                );
                            }}
                        />
                    </View>

                    <View style={{flexDirection: 'row'}}>
                        <FlatList
                            style={{marginTop:28, width: '100%', left: 4}}
                            data={row2col0}
                            horizontal
                            renderItem={({item, index}) => {
                                return (
                                    <TouchableOpacity
                                        style={index !== 0 && {marginLeft: 15}}
                                        onPress={() => {
                                            if (item.selected === false && item.empty === false) {
                                                notifica("warning", "O lugar já está reservado", 3000);
                                            } else {
                                                handleSelect();
                                                handleEscolhaChange(item.lugar);
                                                onSelectrow2col0(index);
                                            }
                                        }}
                                    >
                                        {
                                            item.empty === false && item.selected === true ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraSelecionada}/>
                                            ) : item.empty === true && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraVazia}/>
                                            ) : item.empty === false && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraOcupada}/>
                                            ) : null
                                        }
                                    </TouchableOpacity>
                                );
                            }}
                        />
                        <FlatList
                            style={{marginTop:26, width: '100%', left: 35}}
                            data={row2col1}
                            horizontal
                            renderItem={({item, index}) => {
                                return (
                                    <TouchableOpacity
                                        onPress={() => {
                                            if (item.selected === false && item.empty === false) {
                                                notifica("warning", "O lugar já está reservado", 3000);
                                            } else {
                                                handleSelect();
                                                handleEscolhaChange(item.lugar);
                                                onSelectrow2col1(index);
                                            }
                                        }}
                                    >
                                        {
                                            item.empty === false && item.selected === true ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraSelecionada}/>
                                            ) : item.empty === true && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraVazia}/>
                                            ) : item.empty === false && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraOcupada}/>
                                            ) : null
                                        }
                                    </TouchableOpacity>
                                );
                            }}
                        />
                        <FlatList
                            style={{marginTop:26, width: '100%', right: -38}}
                            data={row2col2}
                            horizontal
                            renderItem={({item, index}) => {
                                return (
                                    <TouchableOpacity
                                        onPress={() => {
                                            if (item.selected === false && item.empty === false) {
                                                notifica("warning", "O lugar já está reservado", 3000);
                                            } else {
                                                handleSelect();
                                                handleEscolhaChange(item.lugar);
                                                onSelectrow2col2(index);
                                            }
                                        }}
                                    >
                                        {
                                            item.empty === false && item.selected === true ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraSelecionada}/>
                                            ) : item.empty === true && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraVazia}/>
                                            ) : item.empty === false && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraOcupada}/>
                                            ) : null
                                        }
                                    </TouchableOpacity>
                                );
                            }}
                        />
                        <FlatList
                            style={{marginTop:28, width: '100%', left: 45}}
                            data={row2col3}
                            horizontal
                            renderItem={({item, index}) => {
                                return (
                                    <TouchableOpacity
                                        style={index === 0 && {marginRight: 16}}
                                        onPress={() => {
                                            if (item.selected === false && item.empty === false) {
                                                notifica("warning", "O lugar já está reservado", 3000);
                                            } else {
                                                handleSelect();
                                                handleEscolhaChange(item.lugar);
                                                onSelectrow2col3(index);
                                            }
                                        }}
                                    >
                                        {
                                            item.empty === false && item.selected === true ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraSelecionada}/>
                                            ) : item.empty === true && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraVazia}/>
                                            ) : item.empty === false && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraOcupada}/>
                                            ) : null
                                        }
                                    </TouchableOpacity>
                                );
                            }}
                        />
                    </View>

                    <View style={{flexDirection: 'row'}}>
                        <FlatList
                            style={{marginTop:71, width: '100%', left: 4}}
                            data={row3col0}
                            horizontal
                            renderItem={({item, index}) => {
                                return (
                                    <TouchableOpacity
                                        style={index !== 0 && {marginLeft: 15}}
                                        onPress={() => {
                                            if (item.selected === false && item.empty === false) {
                                                notifica("warning", "O lugar já está reservado", 3000);
                                            } else {
                                                handleSelect();
                                                handleEscolhaChange(item.lugar);
                                                onSelectrow3col0(index);
                                            }
                                        }}
                                    >
                                        {
                                            item.empty === false && item.selected === true ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraSelecionada}/>
                                            ) : item.empty === true && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraVazia}/>
                                            ) : item.empty === false && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraOcupada}/>
                                            ) : null
                                        }
                                    </TouchableOpacity>
                                );
                            }}
                        />
                        <FlatList
                            style={{marginTop:18, marginBottom:53, width: '100%', left: 35}}
                            data={row3col1}
                            horizontal
                            renderItem={({item, index}) => {
                                return (
                                    <TouchableOpacity
                                        onPress={() => {
                                            if (item.selected === false && item.empty === false) {
                                                notifica("warning", "O lugar já está reservado", 3000);
                                            } else {
                                                handleSelect();
                                                handleEscolhaChange(item.lugar);
                                                onSelectrow3col1(index);
                                            }
                                        }}
                                    >
                                        {
                                            item.empty === false && item.selected === true ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraSelecionada}/>
                                            ) : item.empty === true && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraVazia}/>
                                            ) : item.empty === false && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraOcupada}/>
                                            ) : null
                                        }
                                    </TouchableOpacity>
                                );
                            }}
                        />
                        <FlatList
                            style={{marginTop:18, marginBottom:53, width: '100%', right: -38}}
                            data={row3col2}
                            horizontal
                            renderItem={({item, index}) => {
                                return (
                                    <TouchableOpacity
                                        onPress={() => {
                                            if (item.selected === false && item.empty === false) {
                                                notifica("warning", "O lugar já está reservado", 3000);
                                            } else {
                                                handleSelect();
                                                handleEscolhaChange(item.lugar);
                                                onSelectrow3col2(index);
                                            }
                                        }}
                                    >
                                        {
                                            item.empty === false && item.selected === true ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraSelecionada}/>
                                            ) : item.empty === true && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraVazia}/>
                                            ) : item.empty === false && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraOcupada}/>
                                            ) : null
                                        }
                                    </TouchableOpacity>
                                );
                            }}
                        />
                        <FlatList
                            style={{marginTop:71, width: '100%', left: 45}}
                            data={row3col3}
                            horizontal
                            renderItem={({item, index}) => {
                                return (
                                    <TouchableOpacity
                                        style={index !== 0 && {marginLeft: 16}}
                                        onPress={() => {
                                            if (item.selected === false && item.empty === false) {
                                                notifica("warning", "O lugar já está reservado", 3000);
                                            } else {
                                                handleSelect();
                                                handleEscolhaChange(item.lugar);
                                                onSelectrow3col3(index);
                                            }
                                        }}
                                    >
                                        {
                                            item.empty === false && item.selected === true ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraSelecionada}/>
                                            ) : item.empty === true && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraVazia}/>
                                            ) : item.empty === false && item.selected === false ? (
                                                <Image style={{width: 24, height: 24}} source={cadeiraOcupada}/>
                                            ) : null
                                        }
                                    </TouchableOpacity>
                                );
                            }}
                        />
                    </View>

                    <FlatList style={{left: 15, marginTop: 23}} data={row4col0} horizontal
                              renderItem={({item, index}) => {
                                  return (
                                      <TouchableOpacity style={[
                                          {marginTop: 20, marginBottom: 21},
                                          index !== 0 && {marginLeft: 80},
                                      ]} onPress={() => {
                                          if (item.selected === false && item.empty === false) {
                                              notifica("warning", "O lugar já está reservado", 3000);
                                          } else {
                                              handleSelect();
                                              handleEscolhaChange(item.lugar);
                                              onSelectrow4col0(index);
                                          }
                                      }}>
                                          {
                                              item.empty === false && item.selected === true ? (
                                                  <Image style={{width: 24, height: 24}} source={cadeiraSelecionada}/>
                                              ) : item.empty === true && item.selected === false ? (
                                                  <Image style={{width: 24, height: 24}} source={cadeiraVazia}/>
                                              ) : item.empty === false && item.selected === false ? (
                                                  <Image style={{width: 24, height: 24}} source={cadeiraOcupada}/>
                                              ) : null
                                          }
                                      </TouchableOpacity>
                                  )
                              }}/>
                </View>
            </View>
        </View>
    );
};

export default PlantaCard;
