import {
    marco,
    tobias,
    facebook,
    instagram,
    linkedin,
    twitter,
    COA,
    impressora,
    wifi,
    domicilio,
    andre,
    doraArquitetura,
    gamanhoMultimedia,
    inCentea,
    postoTrabalho,
    gestaoCorrespondencia,
    salaReuniao,
    cacifo,
    televisao,
    telefone, videoProjetor, arCondicionado, limpeza, liberdade, leitorNFC, orlando, rafael, horse
} from "../images";
import {FiEdit, FiShoppingBag} from "react-icons/fi";
import {
    AiOutlineCalendar,
} from "react-icons/ai";
import {IoMdContacts} from "react-icons/io";
import {RiContactsLine} from "react-icons/ri";
import {BsKanban} from "react-icons/bs";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export const imagens = [
    {
        id: 1,
        img: "https://coworker.imgix.net/photos/portugal/fundao/cowork-fundao/main.jpg?w=800&h=0&q=90&auto=format,compress&fit=crop&mark=/template/img/wm_icon.png&markscale=5&markalign=center,middle",
    },
    {
        id: 2,
        img: "https://coworker.imgix.net/photos/portugal/fundao/cowork-fundao/1-1676630409.jpg?w=800&h=0&q=90&auto=format,compress&fit=crop&mark=/template/img/wm_icon.png&markscale=5&markalign=center,middle",
    },
    {
        id: 3,
        img: "https://coworker.imgix.net/photos/portugal/fundao/cowork-fundao/2-1676630409.jpeg?w=800&h=0&q=90&auto=format,compress&fit=crop&mark=/template/img/wm_icon.png&markscale=5&markalign=center,middle",
    },
    {
        id: 4,
        img: "https://coworker.imgix.net/photos/portugal/fundao/cowork-fundao/3-1676630409.jpg?w=800&h=0&q=90&auto=format,compress&fit=crop&mark=/template/img/wm_icon.png&markscale=5&markalign=center,middle",
    },
    {
        id: 5,
        img: "https://coworker.imgix.net/photos/portugal/fundao/cowork-fundao/4-1676630409.jpg?w=800&h=0&q=90&auto=format,compress&fit=crop&mark=/template/img/wm_icon.png&markscale=5&markalign=center,middle",
    },
    {
        id: 6,
        img: "https://coworker.imgix.net/photos/portugal/fundao/cowork-fundao/5-1676630410.jpg?w=800&h=0&q=90&auto=format,compress&fit=crop&mark=/template/img/wm_icon.png&markscale=5&markalign=center,middle",
    },
    {
        id: 7,
        img: "https://coworker.imgix.net/photos/portugal/fundao/cowork-fundao/6-1676630410.jpg?w=800&h=0&q=90&auto=format,compress&fit=crop&mark=/template/img/wm_icon.png&markscale=5&markalign=center,middle",
    },
    {
        id: 8,
        img: "https://coworker.imgix.net/photos/portugal/fundao/cowork-fundao/7-1676630410.jpg?w=800&h=0&q=90&auto=format,compress&fit=crop&mark=/template/img/wm_icon.png&markscale=5&markalign=center,middle",
    },
    {
        id: 9,
        img: "https://coworker.imgix.net/photos/portugal/fundao/cowork-fundao/8-1676630410.jpg?w=800&h=0&q=90&auto=format,compress&fit=crop&mark=/template/img/wm_icon.png&markscale=5&markalign=center,middle",
    },
    {
        id: 10,
        img: "https://coworker.imgix.net/photos/portugal/fundao/cowork-fundao/9-1676630410.jpg?w=800&h=0&q=90&auto=format,compress&fit=crop&mark=/template/img/wm_icon.png&markscale=5&markalign=center,middle",
    },
    {
        id: 11,
        img: "https://coworker.imgix.net/photos/portugal/fundao/cowork-fundao/10-1676630410.jpg?w=800&h=0&q=90&auto=format,compress&fit=crop&mark=/template/img/wm_icon.png&markscale=5&markalign=center,middle",
    },
    {
        id: 12,
        img: "https://coworker.imgix.net/photos/portugal/fundao/cowork-fundao/11-1676630410.jpg?w=800&h=0&q=90&auto=format,compress&fit=crop&mark=/template/img/wm_icon.png&markscale=5&markalign=center,middle",
    },
];

export const coworks = [
    {
        id: "fundao",
        title: "Cowork Fundão",
        coordenadas: [40.1370878, -7.5019554],
    },
    {
        id: "lisboa",
        title: "Lisboa",
        coordenadas: [38.7222524, -9.1393366],
    },
];
export const navLinks = [
    {
        id: "inicio",
        title: "Inicio",
    },
    {
        id: "intro",
        title: "Serviços",
    },
    {
        id: "app",
        title: "App",
    },
    {
        id: "contratos",
        title: "Contratos",
    },
    {
        id: "clients",
        title: "Clientes",
    },
];

export const servicos = [
    /*
    {
        id: "servico-1",
        icon: domicilio,
        title: "Domiciliação da Sede da sua Empresa",
        content:
            "Oferecemos um ambiente profissional e acolhedor, onde tu e sua equipa podem trabalhar num espaço privativo e confortável, sem preocupações a nível da infraestrutura do local.",
    },
    */
    {
        id: "servico-2",
        icon: wifi,
        title: "Wi-Fi com Tráfego Ilimitado",
        content:
            "Conexão de alta velocidade, segura e confiável. Garante a eficiência do teu trabalho em qualquer lugar do nosso espaço de coworking",
    },
    {
        id: "servico-3",
        icon: impressora,
        title: "Impressora Multifunções",
        content:
            "Oferta de 180 impressões mensais gratuitas.",
    },
    {
        id: "servico-4",
        icon: postoTrabalho,
        title: "Posto de Trabalho",
        content:
            "",
    },
    {
        id: "servico-5",
        icon: gestaoCorrespondencia,
        title: "Gestão de Correspondência",
        content:
            "",
    },
    {
        id: "servico-6",
        icon: salaReuniao,
        title: "Sala de Reuniões",
        content:
            "",
    },
    /*
    {
        id: "servico-7",
        icon: cacifo,
        title: "Cacifo",
        content:
            "",
    },
     */
    {
        id: "servico-8",
        icon: televisao,
        title: "Televisão",
        content:
            "",
    },
    {
        id: "servico-9",
        icon: telefone,
        title: "Utilização ilimitada do telefone para a rede fixa nacional",
        content:
            "",
    },
    {
        id: "servico-10",
        icon: videoProjetor,
        title: "Video Projetor",
        content:
            "",
    },
    {
        id: "servico-11",
        icon: arCondicionado,
        title: "Sistema de ar condicionado",
        content:
            "",
    },
    {
        id: "servico-12",
        icon: liberdade,
        title: "Liberdade no Espaço",
        content:
            "",
    },
    {
        id: "servico-13",
        icon: limpeza,
        title: "Serviço de limpeza e manutenção",
        content:
            "",
    },
    /*
    {
        id: "servico-14",
        icon: leitorNFC,
        title: "Leitor NFC",
        content:
            "",
    },

     */
];

export const feedback = [
    {
        id: "feedback-1",
        comentario:
            "O parque desportivo inclui 2 campos de ténis, um campo de futebol e um campo de basquetebol de rua. Faz parte da zona desportiva de Alter do Chão que inclui também o Pavilhão Municipal, o complexo de piscinas, o grande estádio de futebol e os circuitos de fitness ao ar livre a sul.",
        nome: "André Lencastre",
        cargo: "Visitante",
        img: andre,
    },
    {
        id: "feedback-2",
        comentario:
            "Chama a atenção, bem como o chafariz na Praça da República. À semelhança de muitas localidades do interior da país, falta gente para dar 'vida' à terra. Aqui, as casas têm as molduras das janelas e portas pintadas de amarelo. A Igreja é enorme.",
        nome: "Orlando Melo",
        cargo: "Visitante",
        img: orlando,
    },
    {
        id: "feedback-3",
        comentario:
            "Local de visita obrigatória. Extremamente bonito e cheio de história. Infelizmente visitei ainda com a parte tecnológica descritiva e informativa ainda não disponível. Muito bem cuidado e com particularidades muito interessantes.",
        nome: "Rafael Pena Monteiro",
        cargo: "Visitante",
        img: rafael,
    },
];

export const stats = [
    {
        id: "stats-1",
        title: "Espaços",
        value: "+29",
    },
    {
        id: "stats-2",
        title: "Serviços",
        value: "+13",
    },
    {
        id: "stats-3",
        title: "Oportunidades",
        value: "+230M",
    },
];

export const footerLinks = [
    {
        title: "Links Úteis",
        links: [
            {
                name: "Sobre Nós",
                link: "",
            },
            {
                name: "Serviços",
                link: "",
            },
            {
                name: "Contratos",
                link: "",
            },
            {
                name: "Politica e Privacidade",
                link: "",
            },
            {
                name: "Termos & Condições",
                link: "",
            },
        ],
    },
    {
        title: "Comunidade",
        links: [
            {
                name: "Centro de Ajuda",
                link: "",
            },
            {
                name: "Parceiros",
                link: "",
            },
            {
                name: "Sugestões",
                link: "",
            },
            {
                name: "Blog",
                link: "",
            },
            {
                name: "Newsletters",
                link: "",
            },
        ],
    },
];

export const socialMedia = [
    {
        id: "social-media-1",
        icon: instagram,
        link: "https://www.instagram.com/municipio_alterdochao/",
    },
    {
        id: "social-media-2",
        icon: facebook,
        link: "https://www.facebook.com/MunicipioDeAlterDoChao/",
    },
    {
        id: "social-media-3",
        icon: horse,
        link: "https://cm-alter-chao.pt//",
    },
];

export const clients = [
    {
        id: "client-1",
        logo: COA,
        link: "https://www.facebook.com/COAarq",
    },
    {
        id: "client-2",
        logo: doraArquitetura,
        link: "https://www.facebook.com/Arquitectura.Dora",
    },
    {
        id: "client-3",
        logo: gamanhoMultimedia,
        link: "https://www.gamanhomultimedia.com/"
    },
    {
        id: "client-4",
        logo: inCentea,
        link: "https://www.incentea.com/pt/home",
    },
];

export const links = [
    {
        title: 'Pagina Inicial',
        links: [
            {
                name: 'Inicio',
                icon: <FiShoppingBag style={{color: 'black'}}/>,
            },
        ],
    },

    {
        title: 'Utilizadores',
        links: [
            {
                name: 'Clientes',
                icon: <RiContactsLine style={{color: 'black'}}/>,
            },
            {
                name: 'Criar',
                icon: <FiEdit style={{color: 'black'}}/>,
            },
        ],
    },
    {
        title: 'Reservas',
        links: [
            {
                name: 'Reservas',
                icon: <AiOutlineCalendar style={{color: 'black'}}/>,
            },
        ],
    },
    {
        title: 'Visitas',
        links: [
            {
                name: 'Agendamentos',
                icon: <IoMdContacts style={{color: 'black'}}/>,
            },
        ],
    },
    {
        title: 'Gestão',
        links: [
            {
                name: 'Planta',
                icon: <BsKanban style={{color: 'black'}}/>,
            },
        ],
    },
];