import React, {useEffect, useRef, useState} from 'react';
import Modal from 'react-modal';
import styles from "../style";
import Button2 from "./normalUser/Button2";


import {ref as refStorage, uploadBytes, getDownloadURL} from "firebase/storage";
import {v4} from "uuid";
import {storage, database, auth} from "../firebase";

import {Button} from "react-bootstrap"
import {MdModeEdit} from "react-icons/md";
import {Image, View} from "react-native";
import {reservation} from "../images";
import {ref, update} from "firebase/database";
import PlantaCard from "./normalUser/PlantaCard";
import {AuthErrorCodes, createUserWithEmailAndPassword} from "firebase/auth";

const FormCriarUser = () => {
    const [sucessRegistoModalOpen, setSucessRegistoModalOpen] = useState(false);
    const [plantaModalOpen, setPlantaModalOpen] = useState(false);
    const [errorEmailModalOpen, setErrorEmailModalOpen] = useState(false);
    const [errorPasswordModalOpen, setErrorPasswordModalOpen] = useState(false);
    const [errorPassword2ModalOpen, setErrorPassword2ModalOpen] = useState(false);

    const [previewUrl, setPreviewUrl] = useState();
    const filePickerRef = useRef();

    const openErrorEmailModal = () => {
        setErrorEmailModalOpen(true);
    };
    const openErrorPasswordModal = () => {
        setErrorPasswordModalOpen(true);
    };
    const openErrorPassword2Modal = () => {
        setErrorPassword2ModalOpen(true);
    };

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const openSucessRegistoModal = () => {
        setSucessRegistoModalOpen(true);
    };

    const openPlantaModal = () => {
        setPlantaModalOpen(true);
    };

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [tipo, setTipo] = useState("");
    const [nome, setNome] = useState("");
    const [nif, setNif] = useState(0);
    const [dataNascimento, setDataNascimento] = useState("");
    const [morada, setMorada] = useState("");
    const [cp, setCp] = useState("");
    const [localidade, setLocalidade] = useState("");
    const [contacto, setContacto] = useState(0);
    const [linkedin, setLinkedin] = useState("");
    const [motivacao, setMotivacao] = useState("");
    const [file, setFile] = useState();
    const [profileImage, setProfileImage] = useState("");
    const [curriculo, setCurriculo] = useState("");
    const [escolha, setEscolha] = useState(0);
    const aceite = false;
    const cancelamento = "";

    const handleEscolhaChange = (option) => {
        setEscolha(option);
    };

    function criarUser(userId) {
        // Converter nif e contacto em números inteiros
        const nifNumber = parseInt(nif);
        const contactoNumber = parseInt(contacto);

        update(ref(database, `UserData/${userId}`), {
            tipo: tipo,
            nome: nome,
            nif: nifNumber,
            dataNascimento: dataNascimento,
            morada: morada,
            cp: cp,
            localidade: localidade,
            email: email,
            contacto: contactoNumber,
            linkedin: linkedin,
            motivacao: motivacao,
            profileImage: profileImage,
            curriculo: curriculo,
            escolha: escolha,
            aceite: aceite,
            cancelamento: cancelamento
        })
            .then(() => {
                console.log("Document successfully updated!");
                openSucessRegistoModal();
            })
            .catch((error) => {
                console.error("Error updating document: ", error);
            });
    }

    const setData = (e) => {
        e.preventDefault();

        createUserWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                // Signed in
                const user = userCredential.user;
                const userId = user.uid;
                criarUser(userId);
                // ...
            })
            .catch((error) => {
                if (password !== confirmPassword) {
                    openErrorPassword2Modal();
                } else if (error.code === AuthErrorCodes.EMAIL_EXISTS) {
                    openErrorEmailModal();
                } else if (error.code === AuthErrorCodes.WEAK_PASSWORD) {
                    openErrorPasswordModal();
                } else {
                    console.log(error);
                }
            })
    };

    useEffect(() => {
        if (!file) {
            return;
        }
        const fileReader = new FileReader();
        fileReader.onload = () => {
            setPreviewUrl(fileReader.result);
        };
        fileReader.readAsDataURL(file);
    }, [file]);

    function imageSelectedHandler(event) {
        let pickedFile;
        if (event.target.files && event.target.files.length === 1) {
            pickedFile = event.target.files[0];
            setFile(pickedFile);

            const storageRef = refStorage(storage, `profileImages/${pickedFile.name + v4()}`);

            uploadBytes(storageRef, pickedFile)
                .then((snapshot) => {
                    console.log("Imagem carregada com sucesso");
                    // Obter a URL do arquivo carregado
                    getDownloadURL(snapshot.ref)
                        .then((downloadURL) => {
                            console.log("File available at", downloadURL);
                            setProfileImage(downloadURL);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    function pickImageHandler() {
        filePickerRef.current.click();
    }

    function handleCurriculoUpload(event) {
        const file = event.target.files[0];
        const storageRef = refStorage(storage, `curriculos/${file.name}`);

        uploadBytes(storageRef, file)
            .then((snapshot) => {
                console.log("Currículo carregado com sucesso");
                getDownloadURL(snapshot.ref)
                    .then((downloadURL) => {
                        console.log("File available at", downloadURL);
                        setCurriculo(downloadURL);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log("Erro ao carregar currículo:", error);
            });
    }

    const handleRefresh = () => {
        window.location.reload();
    };

    return (
        <section className="min-h-screen flex items-center justify-center">
            <Modal
                isOpen={errorEmailModalOpen}
                onRequestClose={() => setErrorEmailModalOpen(false)}
                contentLabel="Error Modal"
                style={{
                    overlay: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    },
                    content: {
                        position: 'relative',
                        top: 'auto',
                        left: 'auto',
                        right: 'auto',
                        bottom: 'auto',
                        maxWidth: '90%',
                        maxHeight: '90%',
                        padding: '20px',
                        border: 'none',
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        outline: 'none'
                    }
                }}
            >

                <div className="flex items-center justify-center">
                    <div className="px-8 md:px-16 mt-6 mb-6">
                        <h2 className={`${styles.erro}`}>Error de Registo</h2>
                        <p className={`${styles.texto} mb-3`}>O Email que introduziste já está em uso.<br/> Por
                            favor, usa um novo ou pede ao Cliente para Iniciar Sessão.</p>
                        <button
                            className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300 ml-2 mt-3"
                            onClick={() => setErrorEmailModalOpen(false)}
                        >
                            Tentar Novamente
                        </button>

                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={errorPasswordModalOpen}
                onRequestClose={() => setErrorPasswordModalOpen(false)}
                contentLabel="Error Modal"
                style={{
                    overlay: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    },
                    content: {
                        position: 'relative',
                        top: 'auto',
                        left: 'auto',
                        right: 'auto',
                        bottom: 'auto',
                        maxWidth: '90%',
                        maxHeight: '90%',
                        padding: '20px',
                        border: 'none',
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        outline: 'none'
                    }
                }}
            >

                <div className="flex items-center justify-center">
                    <div className="px-8 md:px-16 mt-6 mb-6">
                        <h2 className={`${styles.erro}`}>Error de Registo</h2>
                        <p className={`${styles.texto} mb-3`}>A Password que introduziste é demasiado
                            fraca.<br/> Por
                            favor,
                            usa pelo menos 6 caracteres.</p>
                        <button
                            className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300 ml-2 mt-3"
                            onClick={() => setErrorPasswordModalOpen(false)}
                        >
                            Tentar Novamente
                        </button>

                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={errorPassword2ModalOpen}
                onRequestClose={() => setErrorPassword2ModalOpen(false)}
                contentLabel="Error Modal"
                style={{
                    overlay: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    },
                    content: {
                        position: 'relative',
                        top: 'auto',
                        left: 'auto',
                        right: 'auto',
                        bottom: 'auto',
                        maxWidth: '90%',
                        maxHeight: '90%',
                        padding: '20px',
                        border: 'none',
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        outline: 'none'
                    }
                }}
            >

                <div className="flex items-center justify-center">
                    <div className="px-8 md:px-16 mt-6 mb-6">
                        <h2 className={`${styles.erro}`}>Error de Registo</h2>
                        <p className={`${styles.texto} mb-3`}>As Password's que introduziste não são
                            idênticas.<br/> Por
                            favor, corrige esse pormenor.</p>
                        <button
                            className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300 ml-2 mt-3"
                            onClick={() => setErrorPassword2ModalOpen(false)}
                        >
                            Tentar Novamente
                        </button>

                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={sucessRegistoModalOpen}
                onRequestClose={() => setSucessRegistoModalOpen(false)}
                contentLabel="Sucess Modal"
                style={{
                    overlay: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    },
                    content: {
                        position: 'relative',
                        top: 'auto',
                        left: 'auto',
                        right: 'auto',
                        bottom: 'auto',
                        maxWidth: '90%',
                        maxHeight: '90%',
                        padding: '20px',
                        border: 'none',
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        outline: 'none'
                    }
                }}
            >

                <div className="flex items-center justify-center">
                    <div className="px-8 md:px-16 mt-6 mb-6">
                        <h2 className={`${styles.sucess}`}>Utilizador Registado com Sucesso</h2>
                        <p className={`${styles.texto} mb-3`}>Podes aceder aos seus dados em 'Clientes'.</p>
                        <button
                            className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300 ml-2 mt-3"
                            onClick={handleRefresh}
                        >
                            Fechar
                        </button>

                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={plantaModalOpen}
                onRequestClose={() => setPlantaModalOpen(false)}
                contentLabel="Sucess Modal"
                style={{
                    overlay: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    },
                    content: {
                        position: 'relative',
                        top: 'auto',
                        left: 'auto',
                        right: 'auto',
                        bottom: 'auto',
                        maxWidth: '90%',
                        maxHeight: '90%',
                        padding: '20px',
                        border: 'none',
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        outline: 'none'
                    }
                }}
            >
                <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
                    <Image style={{width: 24, height: 24, alignSelf: "flex-end", margin: 10}} source={reservation}/>
                    <div className="bg-planta ml-32">
                        <PlantaCard handleEscolhaChange={handleEscolhaChange}/>
                    </div>
                    <button
                        className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300 ml-2 mt-3"
                        onClick={() => setPlantaModalOpen(false)}
                    >
                        Fechar
                    </button>
                </View>
            </Modal>

            <div className="form-container items-center">
                <div className="form-container bg-gray-100 rounded-2xl shadow-lg px-8 md:px-16 mt-6 mb-6">
                    <form onSubmit={setData} className="flex flex-col gap-1">
                        <p className={`${styles.texto}`}>Fotografia de Perfil</p>
                        <div className={`${styles.paddingX} ${styles.flexCenter}`}>
                            <div className={`${styles.boxWidth} flex flex-col items-center`}>
                                <input
                                    id="profileImage"
                                    ref={filePickerRef}
                                    style={{display: 'none'}}
                                    type="file"
                                    accept=".jpg,.png,.jpeg"
                                    onChange={imageSelectedHandler}
                                />

                                <div className={`image-upload`}>
                                    <div className="image-upload__preview">
                                        {previewUrl && <img src={previewUrl} alt="preview"/>}
                                        {!previewUrl && (
                                            <div className="image-upload-button">
                                                <Button className="image-upload-button" type="button"
                                                        onClick={pickImageHandler}>+</Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {previewUrl && (
                                    <div className="image-upload-button">
                                        <Button className="image-upload-button flex-col items-center" type="button"
                                                onClick={pickImageHandler}>
                                            <MdModeEdit className="icon mt-4"></MdModeEdit>
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <p className={`${styles.texto}`}>Tipo de Utilizador? *</p>
                        <div className="flex flex-row gap-4">
                            <div className="flex flex-row items-center">
                                <input
                                    type="radio"
                                    id="admin"
                                    name="tipo"
                                    onChange={(e) => setTipo(e.target.value)}
                                    value="Admin"
                                    checked={tipo === "Admin"}
                                    required
                                />
                                <label htmlFor="admin" className="ml-2">Administrador</label>
                            </div>
                            <div className="flex flex-row items-center">
                                <input
                                    type="radio"
                                    id="temporario"
                                    name="tipo"
                                    onChange={(e) => setTipo(e.target.value)}
                                    value="Temporario"
                                    checked={tipo === "Temporario"}
                                    required
                                />
                                <label htmlFor="temporario" className="ml-2">Temporário</label>
                            </div>
                            <div className="flex flex-row items-center">
                                <input
                                    type="radio"
                                    id="fixo"
                                    name="tipo"
                                    onChange={(e) => setTipo(e.target.value)}
                                    value="Fixo"
                                    checked={tipo === "Fixo"}
                                    required
                                />
                                <label htmlFor="fixo" className="ml-2">Fixo</label>
                                {tipo === "Fixo" && (
                                    <>
                                        <p
                                            className={`mb-1 ml-3 text-black cursor-pointer hover:text-amber-200 underline`}
                                            onClick={openPlantaModal}
                                        >
                                            Ver Disp.
                                        </p>
                                    </>
                                )}
                            </div>
                        </div>

                        <li><em><strong>Temporário -</strong> Sem lugar reservado, espaço entregue mediante a
                            disponibilidade;</em></li>
                        <li><em><strong>Fixo -</strong> Um espaço só teu. Irá ficar como temporário até o termo
                            de aceitação.</em></li>
                        <p className={`${styles.texto}`}>Nome Completo *</p>
                        <input className="p-2 rounded-xl border" type="text" name="nome"
                               onChange={(e) => setNome(e.target.value)}
                               value={nome}
                               placeholder="Nome Completo" required/>
                        <p className={`${styles.texto}`}>Número de Contribuínte (NIF) *</p>
                        <input className="p-2 rounded-xl border" type="text" pattern="[0-9]{9}" maxLength="9"
                               id="nif"
                               name="nif" placeholder="000000000" value={nif}
                               onChange={(e) => setNif(e.target.value)} required
                               onInput={(e) => {
                                   e.target.value = e.target.value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
                               }}/>
                        <p className={`${styles.texto}`}>Data de Nascimento *</p>
                        <input className="p-2 rounded-xl border" type="date" name="dataNascimento"
                               value={dataNascimento}
                               onChange={(e) => setDataNascimento(e.target.value)} required/>
                        <li><em>Menores de 18 anos requerem de autorização dos seus representantes legais.</em>
                        </li>
                        <p className={`${styles.texto}`}>Morada *</p>
                        <input className="p-2 rounded-xl border" type="text" name="morada"
                               placeholder="Morada" onChange={(e) => setMorada(e.target.value)}
                               value={morada} required/>
                        <p className={`${styles.texto}`}>Código Postal *</p>
                        <input className="p-2 rounded-xl border" type="text" pattern="[0-9]{4}-[0-9]{3}"
                               maxLength="8" id="cp"
                               name="cp" placeholder="0000-000" value={cp}
                               onChange={(e) => setCp(e.target.value)} required
                               onInput={(e) => { // 0000-000
                                   e.target.value = e.target.value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
                                   e.target.value = e.target.value.replace(/(\d{4})(\d)/, "$1-$2"); // Coloca um hífen entre o quarto e o quinto dígito
                               }}/>
                        <p className={`${styles.texto}`}>Localidade *</p>
                        <input className="p-2 rounded-xl border" type="text" name="localidade"
                               placeholder="Localidade" required
                               onChange={(e) => setLocalidade(e.target.value)}
                               value={localidade}/>
                        <p className={`${styles.texto}`}>Email *</p>
                        <input className="p-2 rounded-xl border" type="email" name="email"
                               value={email}
                               onChange={(e) => setEmail(e.target.value)}
                               placeholder="Email" required/>
                        <p className={`${styles.texto}`}>Password *</p>
                        <div className="relative">
                            <div className="input-box">
                                <input
                                    className="p-2 w-full rounded-xl border"
                                    type={showPassword ? 'text' : 'password'}
                                    name="password" value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Password"
                                    required
                                />
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    fill="gray"
                                    className="bi bi-eye absolute right-3 top-6 -translate-y-1/2 cursor-pointer"
                                    viewBox="0 0 16 16"
                                    onClick={togglePasswordVisibility}
                                >
                                    {showPassword ? (
                                        <>
                                            <path
                                                fillRule="evenodd"
                                                d="M7.998 1C4.68 1 1.825 2.78.513 5.288a.534.534 0 0 0 0 .424C1.825 9.22 4.68 11 7.998 11s6.173-1.78 7.485-4.288a.534.534 0 0 0 0-.424C14.172 2.78 11.318 1 7.998 1zm0 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                            />
                                            <path
                                                fillRule="evenodd"
                                                d="M15.947 7.98C14.885 10.613 12.133 13 7.998 13c-4.135 0-6.888-2.387-7.95-5.02a.76.76 0 0 1 0-.962C1.11 3.387 3.862 1 7.998 1c4.135 0 6.887 2.387 7.949 5.02a.76.76 0 0 1 0 .962zM2.934 7.74c.655 1.78 2.35 3.06 4.064 3.06 1.713 0 3.409-1.28 4.064-3.06l-1.76-.65c-.44 1.08-1.67 1.89-3.304 1.89-1.634 0-2.864-.81-3.303-1.89l-1.76.65z"
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <path
                                                fillRule="evenodd"
                                                d="M7.998 1C4.68 1 1.825 2.78.513 5.288a.534.534 0 0 0 0 .424C1.825 9.22 4.68 11 7.998 11s6.173-1.78 7.485-4.288a.534.534 0 0 0 0-.424C14.172 2.78 11.318 1 7.998 1zm0 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                            />

                                        </>
                                    )}
                                </svg>
                            </div>
                        </div>
                        <p className={`${styles.texto}`}>Confirmar Password *</p>
                        <div className="relative">
                            <input
                                className="p-2 w-full rounded-xl border"
                                type={showConfirmPassword ? 'text' : 'password'}
                                name="confirmPassword" value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="Confirmar Password"
                                required
                            />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                fill="gray"
                                className="bi bi-eye absolute right-3 top-6 -translate-y-1/2 cursor-pointer"
                                viewBox="0 0 16 16"
                                onClick={toggleConfirmPasswordVisibility}
                            >
                                {showConfirmPassword ? (
                                    <>
                                        <path
                                            fillRule="evenodd"
                                            d="M7.998 1C4.68 1 1.825 2.78.513 5.288a.534.534 0 0 0 0 .424C1.825 9.22 4.68 11 7.998 11s6.173-1.78 7.485-4.288a.534.534 0 0 0 0-.424C14.172 2.78 11.318 1 7.998 1zm0 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            d="M15.947 7.98C14.885 10.613 12.133 13 7.998 13c-4.135 0-6.888-2.387-7.95-5.02a.76.76 0 0 1 0-.962C1.11 3.387 3.862 1 7.998 1c4.135 0 6.887 2.387 7.949 5.02a.76.76 0 0 1 0 .962zM2.934 7.74c.655 1.78 2.35 3.06 4.064 3.06 1.713 0 3.409-1.28 4.064-3.06l-1.76-.65c-.44 1.08-1.67 1.89-3.304 1.89-1.634 0-2.864-.81-3.303-1.89l-1.76.65z"
                                        />
                                    </>
                                ) : (
                                    <>
                                        <path
                                            fillRule="evenodd"
                                            d="M7.998 1C4.68 1 1.825 2.78.513 5.288a.534.534 0 0 0 0 .424C1.825 9.22 4.68 11 7.998 11s6.173-1.78 7.485-4.288a.534.534 0 0 0 0-.424C14.172 2.78 11.318 1 7.998 1zm0 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                        />

                                    </>
                                )}
                            </svg>
                        </div>
                        <p className={`${styles.texto}`}>Contacto Telefónico *</p>
                        <input className="p-2 rounded-xl border" type="text" pattern="[0-9]{9}" maxLength="9"
                               id="contactoTelefonico"
                               name="phone" placeholder="000000000"
                               onChange={(e) => setContacto(e.target.value)}
                               value={contacto} required onInput={(e) => { // 000000000   // 9 dígitos
                            e.target.value = e.target.value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
                        }}/>
                        <p className={`${styles.texto}`}>Linkedin</p>
                        <input className="p-2 rounded-xl border" type="text" name="linkedin"
                               placeholder="Linkedin"
                               onChange={(e) => setLinkedin(e.target.value)} value={linkedin}/>
                        <p className={`${styles.texto}`}>O Cliente expressou a sua motivação para ingressar no
                            Cowork?</p>
                        <textarea className="p-2 rounded-xl border" name="motivacao" id="motivacao" cols={30}
                                  rows={5}
                                  placeholder="Motivação"
                                  onChange={(e) => setMotivacao(e.target.value)}
                                  value={motivacao}/>
                        <p className={`${styles.texto}`}>Currículo: (CV, Portefólio, Memória
                            Descritiva)</p>
                        <input className="p-2 rounded-xl border" type="file" name="curriculo"
                               onChange={handleCurriculoUpload} placeholder="Curriculo"/>
                        <div className={`${styles.menu} cursor-pointer mt-4`}>
                            <Button2 type="submit" onClick={setData}
                                     styles={"py-1.5 w-full rounded-xl hover:scale-105 duration-300"}
                                     text={"Submeter Formulário"}/>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default FormCriarUser;
