import React from "react";
import "./ErrorPage.css";
import styles from "../style";

const ErrorPage = () => {

    return (
        <section className="body min-h-screen flex items-center justify-center">
            <div className="flex-col max-w-5xl p-4 items-center mt-6 mb-6">

                <div className="md:block hidden w-1/2">
                    <div className="container">
                        <div className="sand"/>
                        <div className="tower">
                            <div className="pole"/>
                            <div className="door"/>
                            <div className="light-base"/>
                            <div className="dome"/>
                            <div className="light"/>
                        </div>
                        <div className="sand-extra"/>
                    </div>
                </div>
                <div className={`${styles.paddingX} ${styles.flexCenter}`}>
                    <div className={`${styles.boxWidth} flex flex-col items-center`}>
                <h2 className={`${styles.h1} text-white`}>error 404.</h2>
                <h3 className={`${styles.h1} text-white`}>Sorry! Page Not Found.</h3>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default ErrorPage;
