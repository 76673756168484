import HeaderImg from "../images/coreto.png";

import {onAuthStateChanged} from "firebase/auth";
import React, {useEffect, useState} from "react";
import {auth, database} from "../firebase";
import {query, update, onValue, ref, get, set, push, remove} from 'firebase/database';
import styles from "../style";
import Destination from "../components/Destination";
import {
    Clients, Comentarios, Footer, Hero, Intro, Intro2, Mobile, Pub, Reservar, Stats
} from "../components/normalUser/landingPage";
import Servicos from "../components/normalUser/landingPage/Servicos";
import Contratos from "../components/normalUser/landingPage/Contratos";
import Navbar from "../components/normalUser/Navbar";
import AreaPessoal from "../components/normalUser/AreaPessoal";
import Login from "../components/normalUser/Login";
import Formulario from "../components/normalUser/Formulario";
import AgendarVisita from "../components/normalUser/AgendarVisita";
import ContratosPage from "../components/normalUser/ContratosPage";
import SobreNosPage from "../components/normalUser/SobreNosPage";
import ServicosPage from "../components/normalUser/ServicosPage";
import PoliticaPrivacidadePage from "../components/normalUser/PoliticaPrivacidadePage";
import TermosCondicoesPage from "../components/normalUser/TermosCondicoesPage";
import SemConteudoPage from "../components/normalUser/SemConteudoPage";
import ScrollTopButton from "../components/normalUser/ScrollTopButton";
import Sidebar from "../components/Sidebar";
import {
    InicioAdmin, CriarUser, Clientes, Reservas, Agendamentos, Planta
} from "../components";
import NavbarAdmin from "../components/NavbarAdmin";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Espacos from "../components/Espacos";
import Sobre from "../components/Sobre";
import Contacto from "../components/Contacto";
import EspacosDesportivos from "../components/EspacosDesportivos";

const LandingPage = () => {
    const [authUser, setAuthUser] = useState(null);
    const [isSticky, setIsSticky] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Inicio');
    const [nome, setNome] = useState('');
    const [tipo, setTipo] = useState('');
    const [escolha, setEscolha] = useState(0);
    const [aceite, setAceite] = useState(false);
    const [lugar, setLugar] = useState("");
    const [cancelamento, setCancelamento] = useState("");
    const [viewScrollButton, setViewScrollButton] = useState(false);

    const [admin, setAdmin] = useState(true);
    const [adminOption, setAdminOption] = useState('Inicio');

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                setIsSticky(true);
                setViewScrollButton(true);
            } else {
                setIsSticky(false);
                setViewScrollButton(false);
            }
        });
    }, [])

    useEffect(() => {
        const listen = onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthUser(user);
                const userId = user.uid;
                const userDataRef = ref(database, `UserData/${userId}`);

                const listener = onValue(userDataRef, (snapshot) => {
                    const userData = snapshot.val();

                    if (userData) {
                        setNome(userData.nome)
                        setTipo(userData.tipo);
                        setEscolha(userData.escolha);
                        setAceite(userData.aceite);
                        setCancelamento(userData.cancelamento);
                    }
                });

                return () => {
                    listener();
                };
            }
        });

        return () => {
            listen();
        };

    }, []);

    /*
    function deleteReservasData() {
        const reservasRef = ref(database, 'Reservas');
        remove(reservasRef)
            .then(() => {
                console.log('Dados de reservas removidos com sucesso.');
            })
            .catch((error) => {
                console.error('Erro ao remover os dados de reservas:', error);
            });
    }

        // Chamada da função para remover os dados
        deleteReservasData();

     */

    useEffect(() => {
        if (escolha >= 1 && escolha <= 29) {
            const lugarName = escolha <= 9 ? `0${escolha}` : `${escolha}`;
            setLugar(`lugar${lugarName}`);
        }
    }, [escolha]);

    useEffect(() => {
        if (lugar !== "") {
            const lugarName = lugar.replace("lugar", "");
            const lugarFieldName = `lugar${lugarName}`;

            if (cancelamento === "Aceite") {
                const plantaRef = ref(database, "Planta/-CoworkFundao");
                update(plantaRef, {
                    [lugarFieldName]: true
                })
                    .then(() => {
                        console.log(`Campo ${lugarFieldName} atualizado com sucesso!`);
                    })
                    .catch((error) => {
                        console.error(`Erro ao atualizar o campo ${lugarFieldName}:`, error);
                    });

                const userId = authUser.uid;
                const userDataRef = ref(database, `UserData/${userId}`);
                update(userDataRef, {
                    tipo: "Temporario", escolha: 0, aceite: false, cancelamento: ""
                })
                    .then(() => {
                        console.log("Document written with ID: ", userId);
                    })
                    .catch((error) => {
                        console.error("Error adding document: ", error);
                    });

                // Criar uma referência para o caminho das reservas
                const reservasRef = ref(database, 'Reservas/');

                // Criar uma consulta para buscar todas as reservas
                const todasReservasQuery = query(reservasRef);

                const currentDate = new Date();

                // Obter todas as reservas
                get(todasReservasQuery)
                    .then((snapshot) => {
                        // Iterar sobre as reservas e remover apenas as que correspondam ao userId
                        snapshot.forEach((reservaSnapshot) => {
                            const reservaKey = reservaSnapshot.key;
                            const reservaData = reservaSnapshot.val();

                            const reservaDate = new Date(reservaData.data);

                            // Verificar se o userId corresponde ao usuário selecionado
                            if (reservaData.userId === userId) {
                                const reservaPath = `Reservas/${reservaKey}`;

                                // Verificar se a data da reserva é maior ou igual à data atual
                                if (reservaDate >= currentDate) {
                                    // Remover a reserva pelo caminho
                                    remove(ref(database, reservaPath))
                                        .then(() => {
                                            console.log(`Reserva removida: ${reservaKey}`);
                                        })
                                        .catch((error) => {
                                            console.error(`Erro ao remover reserva ${reservaKey}:`, error);
                                        });
                                }
                            }
                        });
                    })
                    .catch((error) => {
                        console.error('Erro ao buscar as reservas:', error);
                    });

            } else if (aceite && (tipo === "Fixo")) {
                const plantaRef = ref(database, "Planta/-CoworkFundao");
                update(plantaRef, {
                    [lugarFieldName]: false
                })
                    .then(() => {
                        console.log(`Campo ${lugarFieldName} atualizado com sucesso!`);
                    })
                    .catch((error) => {
                        console.error(`Erro ao atualizar o campo ${lugarFieldName}:`, error);
                    });

                // Obter a data atual
                const currentDate = new Date();

                const userId = authUser.uid;
                const reservasRef = ref(database, `Reservas/`);

                const todasReservasQuery = query(reservasRef);

                // Gerar um novo ID único para a reserva
                const reservaId = push(reservasRef).key;

                // Atualizar o caminho da reserva com o ID único
                const reservaPath = `Reservas/${reservaId}`;

                // Atualizar a referência da reserva
                const reservaRef = ref(database, reservaPath);

                get(todasReservasQuery)
                    .then((snapshot) => {
                        const reservasData = [];

                        snapshot.forEach((reservaSnapshot) => {
                            const reservaKey = reservaSnapshot.key;
                            const reservaData = reservaSnapshot.val();

                            // Verifica se o userId corresponde ao usuário selecionado
                            if (reservaData.userId === userId) {
                                const reservaPath = `Reservas/${reservaKey}`;
                                reservasData.push({
                                    reservaKey, reservaPath, ...reservaData,
                                });
                            }
                        });

                        const datas = [];
                        const datasSet = new Set(); // Usamos um Set para armazenar as datas únicas

                        for (let i = 0; i < reservasData.length; i++) {
                            const data = reservasData[i].data;
                            if (!datasSet.has(data)) {
                                datasSet.add(data);
                                datas.push(data);
                            }
                        }

                        for (let i = 0; i < 8; i++) {
                            const date = new Date(currentDate.getTime() + i * 24 * 60 * 60 * 1000);

                            const formattedDay = date.getDate().toString().padStart(2, '0');
                            const formattedMonth = (date.getMonth() + 1).toString().padStart(2, '0');
                            const formattedYear = date.getFullYear();
                            const formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}`;

                            if (!datas.includes(formattedDate)) {
                                const reservaId = push(reservasRef).key;
                                const reservaPath = `Reservas/${reservaId}`;
                                const reservaRef = ref(database, reservaPath);

                                const reservaData = {
                                    userId: userId,
                                    cowork: 'Cowork Fundão',
                                    data: formattedDate,
                                    lugar: lugar,
                                    CheckIn: '00:00',
                                    CheckOut: '23:59',
                                    entrada: "",
                                    saida: "",
                                    status: ""
                                };

                                set(reservaRef, reservaData)
                                    .then(() => {
                                        console.log('Reserva criada com sucesso!');
                                    })
                                    .catch((error) => {
                                        console.error('Erro ao criar a reserva:', error);
                                    });
                            }
                        }
                    })
                    .catch((error) => {
                        console.error('Erro ao buscar as reservas:', error);
                    });

            }
        }
    }, [lugar, aceite, tipo, cancelamento]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.pageYOffset;
            const navbar = document.querySelector(`.${styles.navbar}`);
            if (navbar) {
                const navbarOffset = navbar.offsetTop;
                setIsSticky(scrollPosition >= navbarOffset);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [selectedOption]); // Adiciona a dependência selectedOption

    useEffect(() => {
        // Define isSticky como false quando a opção selecionada for "Reservar" ou "AreaPessoal"
        setIsSticky(selectedOption === "Incio" && selectedOption !== "Reservar" && selectedOption !== "AreaPessoal");
    }, [selectedOption]); // Adicione a dependência selectedOption

    const handleOptionChange = (option) => {
        setSelectedOption(option);
        // enviar a página para o topo
        window.scrollTo(0, 0);
    };

    const adminOptionChange = (option) => {
        setAdminOption(option);
        // enviar a página para o topo
        window.scrollTo(0, 0);
    };

    const notifica = (notificacao, texto, tempo) => {
        if (notificacao === "success") {
            toast.success(texto, {
                position: "top-center",
                autoClose: tempo,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else if (notificacao === "info") {
            toast.info(texto, {
                position: "top-center",
                autoClose: tempo,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else if (notificacao === "warning") {
            toast.warning(texto, {
                position: "top-center",
                autoClose: tempo,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else if (notificacao === "error") {
            toast.error(texto, {
                position: "top-center",
                autoClose: tempo,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };

    return (<div className="w-full overflow-hidden">
            <ToastContainer
                position="top-center"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            {tipo === "Admin" ? (<>
                <div className="flex relative">
                    <div className="lg2:w-64 w-0 fixed sidebar bg-white ">
                        <Sidebar adminOptionChange={adminOptionChange}/>
                    </div>

                    <div className="fixed bg-white navbar w-full">
                        <NavbarAdmin nome={nome} handleOptionChange={handleOptionChange}
                                     adminOptionChange={adminOptionChange}/>
                    </div>

                    <div
                        className="min-h-screen lg2:ml-64 w-full"
                    >
                        {adminOption === "Inicio" && (<>
                            <InicioAdmin/>
                        </>)}

                        {adminOption === "Clientes" && (<>
                            <Clientes/>
                        </>)}

                        {adminOption === "Criar" && (<>
                            <CriarUser/>
                        </>)}

                        {adminOption === "Reservas" && (<>
                            <Reservas/>
                        </>)}

                        {adminOption === "Agendamentos" && (<>
                            <Agendamentos/>
                        </>)}

                        {adminOption === "Planta" && (<>
                            <Planta/>
                        </>)}
                    </div>
                </div>
            </>) : (<>
                    {selectedOption === "Login" && (<>
                        <Login handleOptionChange={handleOptionChange}/>
                    </>)}

                    {selectedOption === "Inicio" && (<>
                        <div className="corda center">
                            <div className="candeeiro center"></div>
                        </div>
                    </>)}

                    {selectedOption !== "Login" && (<>
                        <div className={`${styles.paddingX} ${styles.flexCenter}`}>
                            <div className={`${styles.boxWidth} ${isSticky ? 'sticky' : ''}`}>
                                <Navbar />
                            </div>
                        </div>
                    </>)}
                    {selectedOption === "Inicio" && (<>
                        <div className={` `}>
                            <div className={``}>
                                <Hero handleOptionChange={handleOptionChange} authUser={authUser} cName="hero"
                                      heroImg={HeaderImg}
                                      title="Tiago Barreiros" text="Engeharia Informática"
                                      buttonText="PÁGINA EM MANUTENÇÃO" url="https://www.linkedin.com/in/tiago-barreiros/"
                                      btnClass="show"/>
                            </div>
                        </div>
                    </>)}

                    {selectedOption === "Sobre" && (<>
                        <div className={` `}>
                            <div className={``}>
                                <Hero handleOptionChange={handleOptionChange} authUser={authUser}
                                      cName="hero-mid"
                                      heroImg={HeaderImg}
                                      title="Sobre"/>
                            </div>
                        </div>
                    </>)}
                    {selectedOption === "Servicos" && (<>
                        <div className={` `}>
                            <div className={``}>
                                <Hero handleOptionChange={handleOptionChange} authUser={authUser}
                                      cName="hero-mid"
                                      heroImg={HeaderImg}
                                      title="Serviços"/>
                            </div>
                        </div>
                    </>)}
                    {selectedOption === "Contactos" && (<>
                        <div className={` `}>
                            <div className={``}>
                                <Hero handleOptionChange={handleOptionChange} authUser={authUser} cName="hero-mid"
                                      heroImg={HeaderImg}
                                      title="Contactos"/>
                            </div>
                        </div>
                    </>)}

                </>
            )}

            {viewScrollButton === true && (<>
                <ScrollTopButton/>
            </>)}
        </div>
    );
};

export default LandingPage;
