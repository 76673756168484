import "./Navbar.css";
import {Link} from "react-router-dom";
import {MenuItems} from "../MenuItems";

import {onAuthStateChanged, signOut} from "firebase/auth";
import React, {useEffect, useState} from "react";
import {auth} from "../../firebase";

import {close, logo, menu} from "../../images";

import styles from "../../style";

const Navbar = ({handleOptionChange, selectedOption}) => {
    const [toggle, setToggle] = useState(false);
    const [authUser, setAuthUser] = useState(null);

    useEffect(() => {
        const listen = onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthUser(user);
            } else {
                setAuthUser(null);
            }
        });

        return () => {
            listen();
        }
    }, []);
    const userSignOut = () => {
        signOut(auth).then(() => {
            setAuthUser(null);
            handleOptionChange("Inicio");
            // atualizar pagina
            window.location.reload();
        }).catch(error => console.log(error))
    }

    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(!clicked);
    }

    return (
        <nav className="NavbarItems">
            <a href="/">
                <img src={logo} alt="logo" className="w-14 h-14"/>
            </a>
            <h1 className="navbar-logo">Alter do Chão</h1>

            <div className="menu-icons" onClick={handleClick}>
                <i className={clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
            </div>

            <ul className={clicked ? "nav-menu active" : "nav-menu"}>
                {authUser ? <>
                    <li onClick={() => handleOptionChange("Inicio")}>
                        <Link to="/" className="nav-links">
                            <i className="fa-solid fa-house-user"></i>Início
                        </Link>
                    </li>
                    <li
                        onClick={() => handleOptionChange("Sobre")}>
                        <Link className="nav-links">
                            <i className="fa-solid fa-circle-info"></i>Sobre
                        </Link>
                    </li>
                    <li
                        onClick={() => handleOptionChange("Servicos")}>
                        <Link className="nav-links">
                            <i className="fa-solid fa-briefcase"></i>Serviços
                        </Link>
                    </li>
                    <li
                        onClick={() => handleOptionChange("Contactos")}>
                        <Link className="nav-links">
                            <i className="fa-solid fa-address-book"></i>Contactos
                        </Link>
                    </li>
                    <button onClick={userSignOut}>Sair</button>
                </> : <>
                    {selectedOption === "Inicio" && (
                        <>
                            <li onClick={() => handleOptionChange("Inicio")}>
                                <Link to="/" className="nav-links">
                                    <i className="fa-solid fa-house-user"></i>
                                    <a href="#inicio">Início</a>
                                </Link>
                            </li>
                            <li
                                onClick={() => handleOptionChange("Sobre")}>
                                <Link className="nav-links">
                                    <i className="fa-solid fa-circle-info"></i>
                                    <a href="#sobre">Sobre</a>
                                </Link>
                            </li>
                            <li
                                onClick={() => handleOptionChange("Servicos")}>
                                <Link className="nav-links">
                                    <i className="fa-solid fa-briefcase"></i>
                                    <a href="#servicos">Serviços</a>
                                </Link>
                            </li>
                            <li
                                onClick={() => handleOptionChange("Contactos")}>
                                <Link className="nav-links">
                                    <i className="fa-solid fa-address-book"></i>
                                    <a href="#contacto">Contacto</a>
                                </Link>
                            </li>
                            <button onClick={() => handleOptionChange("Login")}>Login</button>
                        </>
                    )}
                    {selectedOption === "Visita" && (
                        <>
                            <li className={"text-dimWhite saira-condensed-bold text-[18px] uppercase hover:text-white mr-14"}
                                onClick={() => handleOptionChange("Inicio")}>
                                Inicio
                            </li>
                            <li className={"text-dimWhite saira-condensed-bold text-[18px] uppercase hover:text-white"}>
                                <a href="#contratos">Contratos</a>
                            </li>
                            <div
                                className={`${styles.menu} cursor-pointer list-none nb:flex hidden justify-end items-center`}>
                                <button
                                    className={"py-0.5 px-5 ml-11 verdeTropa bg-softGrey text-white saira-condensed-bold text-[18px] uppercase rounded-[10px] button-burlyWood"}
                                    onClick={() => handleOptionChange("Login")}>
                                    Login
                                </button>
                            </div>
                        </>
                    )}
                    {(selectedOption === "Contratos" || selectedOption === "Sobre Nós" || selectedOption === "Serviços" || selectedOption === "Politica e Privacidade" || selectedOption === "Termos & Condições" || selectedOption === "Centro de Ajuda" || selectedOption === "Parceiros" || selectedOption === "Sugestões" || selectedOption === "Blog" || selectedOption === "Newletters") && (
                        <>
                            <li className={"text-dimWhite saira-condensed-bold text-[18px] uppercase hover:text-white"}
                                onClick={() => handleOptionChange("Inicio")}>
                                Inicio
                            </li>
                            <div
                                className={`${styles.menu} cursor-pointer list-none nb:flex hidden justify-end items-center`}>
                                <button
                                    className={"py-0.5 px-5 ml-11 verdeTropa bg-softGrey text-white saira-condensed-bold text-[18px] uppercase rounded-[10px] button-burlyWood"}
                                    onClick={() => handleOptionChange("Login")}>
                                    Login
                                </button>
                            </div>
                        </>
                    )}
                </>}
            </ul>
        </nav>
        /*
            <nav className="w-full flex py-6 justify-between items-center navbar">
                        <a href="/">
                            <img src={logo} alt="logo" className="w-[124px] h-[32px]"/>
                        </a>
                        <ul className="list-none nb:flex hidden justify-end items-center flex-1">
                            <div className={`${styles.menu} cursor-pointer list-none nb:flex hidden justify-end items-center`}>
                                {authUser ? <>
                                    <li className={"text-dimWhite saira-condensed-bold text-[18px] uppercase hover:text-white"}
                                        onClick={() => handleOptionChange("Inicio")}>
                                        Inicio
                                    </li>
                                    <button
                                        className={"py-0.5 px-5 verdeTropa bg-burlyWood text-white saira-condensed-bold text-[18px] uppercase rounded-[10px] button-softGrey ml-11 mr-11"}
                                        onClick={() => handleOptionChange("Reservar")}>
                                        Reservar
                                    </button>
                                    <button
                                        className={"py-0.5 px-5 verdeTropa bg-burlyWood text-white saira-condensed-bold text-[18px] uppercase rounded-[10px] button-softGrey mr-11"}
                                        onClick={() => handleOptionChange("AreaPessoal")}>
                                        Área Pessoal
                                    </button>
                                    <button onClick={userSignOut}
                                            className={"py-0.5 px-5 verdeTropa bg-softGrey text-white saira-condensed-bold text-[18px] uppercase rounded-[10px] button-burlyWood"}>
                                        Sair
                                    </button>
                                </> : <>
                                    {selectedOption === "Inicio" && (
                                        <>
                                            <li className={"text-dimWhite saira-condensed-bold text-[18px] uppercase hover:text-white mr-14"}
                                                onClick={() => handleOptionChange("Inicio")}>
                                                <a href="#inicio">Inicio</a>
                                            </li>
                                            <li className={"text-dimWhite saira-condensed-bold text-[18px] uppercase hover:text-white mr-14"}>
                                                <a href="#intro">Serviços</a>
                                            </li>
                                            <li className={"text-dimWhite saira-condensed-bold text-[18px] uppercase hover:text-white mr-14"}>
                                                <a href="#app">App</a>
                                            </li>
                                            <li className={"text-dimWhite saira-condensed-bold text-[18px] uppercase hover:text-white mr-14"}>
                                                <a href="#contratos">Contratos</a>
                                            </li>
                                            <li className={"text-dimWhite saira-condensed-bold text-[18px] uppercase hover:text-white"}>
                                                <a href="#clients">Clientes</a>
                                            </li>
                                            <div
                                                className={`${styles.menu} cursor-pointer list-none nb:flex hidden justify-end items-center`}>
                                                <button
                                                    className={"py-0.5 px-5 ml-11 verdeTropa bg-softGrey text-white saira-condensed-bold text-[18px] uppercase rounded-[10px] button-burlyWood"}
                                                    onClick={() => handleOptionChange("Login")}>
                                                    Login
                                                </button>
                                            </div>
                                        </>
                                    )}
                                    {selectedOption === "Visita" && (
                                        <>
                                            <li className={"text-dimWhite saira-condensed-bold text-[18px] uppercase hover:text-white mr-14"}
                                                onClick={() => handleOptionChange("Inicio")}>
                                                Inicio
                                            </li>
                                            <li className={"text-dimWhite saira-condensed-bold text-[18px] uppercase hover:text-white"}>
                                                <a href="#contratos">Contratos</a>
                                            </li>
                                            <div
                                                className={`${styles.menu} cursor-pointer list-none nb:flex hidden justify-end items-center`}>
                                                <button
                                                    className={"py-0.5 px-5 ml-11 verdeTropa bg-softGrey text-white saira-condensed-bold text-[18px] uppercase rounded-[10px] button-burlyWood"}
                                                    onClick={() => handleOptionChange("Login")}>
                                                    Login
                                                </button>
                                            </div>
                                        </>
                                    )}
                                    {(selectedOption === "Contratos" || selectedOption === "Sobre Nós" || selectedOption === "Serviços" || selectedOption === "Politica e Privacidade" || selectedOption === "Termos & Condições" || selectedOption === "Centro de Ajuda" || selectedOption === "Parceiros" || selectedOption === "Sugestões" || selectedOption === "Blog" || selectedOption === "Newletters") && (
                                        <>
                                            <li className={"text-dimWhite saira-condensed-bold text-[18px] uppercase hover:text-white"}
                                                onClick={() => handleOptionChange("Inicio")}>
                                                Inicio
                                            </li>
                                            <div
                                                className={`${styles.menu} cursor-pointer list-none nb:flex hidden justify-end items-center`}>
                                                <button
                                                    className={"py-0.5 px-5 ml-11 verdeTropa bg-softGrey text-white saira-condensed-bold text-[18px] uppercase rounded-[10px] button-burlyWood"}
                                                    onClick={() => handleOptionChange("Login")}>
                                                    Login
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </>}
                            </div>
                        </ul>

                        <div className="nb:hidden flex flex-1 justify-end items-center">
                            <img
                                src={toggle ? close : menu}
                                alt="menu"
                                className="w-[28px] h-[28px] object-contain"
                                onClick={() => setToggle(!toggle)}
                            />

                            <div
                                className={`${
                                    !toggle ? "hidden" : "flex"
                                } p-6 bg-verdeTropa absolute top-16 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}
                            >
                                <ul className="list-none flex justify-end items-start flex-1 flex-col">
                                    <div className={`${styles.menu} cursor-pointer mt-4`}>
                                        {authUser ? <>
                                            <li className={"text-dimWhite mb-1 saira-condensed-bold text-[18px] uppercase hover:text-white"}
                                                onClick={() => handleOptionChange("Inicio")}>
                                                Inicio
                                            </li>
                                            <button
                                                className={"py-0.5 px-5 mb-1 verdeTropa bg-burlyWood text-white saira-condensed-bold text-[18px] uppercase rounded-[10px] button-softGrey mr-11"}
                                                onClick={() => handleOptionChange("Reservar")}>
                                                Reservas
                                            </button>
                                            <button
                                                className={"py-0.5 px-5 mb-1 verdeTropa bg-burlyWood text-white saira-condensed-bold text-[18px] uppercase rounded-[10px] button-softGrey mr-11"}
                                                onClick={() => handleOptionChange("AreaPessoal")}>
                                                Área Pessoal
                                            </button>
                                            <button onClick={userSignOut}
                                                    className={"py-0.5 px-5 mb-1 verdeTropa bg-softGrey text-white saira-condensed-bold text-[18px] uppercase rounded-[10px] button-burlyWood"}>
                                                Sair
                                            </button>
                                        </> : <>
                                            {selectedOption === "Inicio" && (
                                                <>
                                                    <li className={"text-dimWhite saira-condensed-bold text-[18px] uppercase hover:text-white mr-14"}
                                                        onClick={() => handleOptionChange("Inicio")}>
                                                        Inicio
                                                    </li>
                                                    <li className={"text-dimWhite saira-condensed-bold text-[18px] uppercase hover:text-white mr-14"}>
                                                        <a href="#intro">Serviços</a>
                                                    </li>
                                                    <li className={"text-dimWhite saira-condensed-bold text-[18px] uppercase hover:text-white mr-14"}>
                                                        <a href="#app">App</a>
                                                    </li>
                                                    <li className={"text-dimWhite saira-condensed-bold text-[18px] uppercase hover:text-white mr-14"}>
                                                        <a href="#contratos">Contratos</a>
                                                    </li>
                                                    <li className={"text-dimWhite saira-condensed-bold text-[18px] uppercase hover:text-white"}>
                                                        <a href="#clients">Clientes</a>
                                                    </li>
                                                    <div
                                                        className={`${styles.menu} cursor-pointer list-none nb:flex hidden justify-end items-center`}>
                                                        <button
                                                            className={"py-0.5 px-5 ml-11 verdeTropa bg-softGrey text-white saira-condensed-bold text-[18px] uppercase rounded-[10px] button-burlyWood"}
                                                            onClick={() => handleOptionChange("Login")}>
                                                            Login
                                                        </button>
                                                    </div>
                                                </>
                                            )}
                                            {selectedOption === "Visita" && (
                                                <>
                                                    <li className={"text-dimWhite saira-condensed-bold text-[18px] uppercase hover:text-white mr-14"}
                                                        onClick={() => handleOptionChange("Inicio")}>
                                                        Inicio
                                                    </li>
                                                    <li className={"text-dimWhite saira-condensed-bold text-[18px] uppercase hover:text-white"}>
                                                        <a href="#contratos">Contratos</a>
                                                    </li>
                                                    <div
                                                        className={`${styles.menu} cursor-pointer list-none nb:flex hidden justify-end items-center`}>
                                                        <button
                                                            className={"py-0.5 px-5 ml-11 verdeTropa bg-softGrey text-white saira-condensed-bold text-[18px] uppercase rounded-[10px] button-burlyWood"}
                                                            onClick={() => handleOptionChange("Login")}>
                                                            Login
                                                        </button>
                                                    </div>
                                                </>
                                            )}
                                            {selectedOption === "Contratos" && (
                                                <>
                                                    <li className={"text-dimWhite saira-condensed-bold text-[18px] uppercase hover:text-white"}
                                                        onClick={() => handleOptionChange("Inicio")}>
                                                        Inicio
                                                    </li>
                                                    <div
                                                        className={`${styles.menu} cursor-pointer list-none nb:flex hidden justify-end items-center`}>
                                                        <button
                                                            className={"py-0.5 px-5 ml-11 verdeTropa bg-softGrey text-white saira-condensed-bold text-[18px] uppercase rounded-[10px] button-burlyWood"}
                                                            onClick={() => handleOptionChange("Login")}>
                                                            Login
                                                        </button>
                                                    </div>
                                                </>
                                            )}
                                        </>}
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </nav>
                */
    );
};

export default Navbar;
