import React, {useEffect, useRef, useState} from 'react';
import Modal from 'react-modal';
import imagemLogin from "../../images/cowork/teste01.jpg";
import styles from "../../style";
import Button2 from "./Button2";

import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    AuthErrorCodes,
    GoogleAuthProvider,
    signInWithPopup,
    sendPasswordResetEmail
} from "firebase/auth";
import {ref as refStorage, uploadBytes, getDownloadURL} from "firebase/storage";
import {v4} from "uuid";
import {auth, storage, database} from "../../firebase";
import {update, ref, get} from 'firebase/database';
import {logo, reservation} from "../../images";

import {Button} from "react-bootstrap"
import {MdModeEdit} from "react-icons/md";
import {Image, View} from "react-native";
import PlantaCard from "./PlantaCard";

const Login = ({handleOptionChange}) => {
        const [errorLoginModalOpen, setErrorLoginModalOpen] = useState(false);
        const [errorEmailModalOpen, setErrorEmailModalOpen] = useState(false);
        const [errorPasswordModalOpen, setErrorPasswordModalOpen] = useState(false);
        const [errorPassword2ModalOpen, setErrorPassword2ModalOpen] = useState(false);
        const [sucessRegistoModalOpen, setSucessRegistoModalOpen] = useState(false);
        const [sucessEmailModalOpen, setSucessEmailModalOpen] = useState(false);
        const [errorEmail2ModalOpen, setErrorEmail2ModalOpen] = useState(false);
        const [plantaModalOpen, setPlantaModalOpen] = useState(false);

        const [previewUrl, setPreviewUrl] = useState();
        const filePickerRef = useRef();

        const openErrorLoginModal = () => {
            setErrorLoginModalOpen(true);
        };
        const openErrorEmailModal = () => {
            setErrorEmailModalOpen(true);
        };
        const openErrorEmail2Modal = () => {
            setErrorEmail2ModalOpen(true);
        };
        const openErrorPasswordModal = () => {
            setErrorPasswordModalOpen(true);
        };
        const openErrorPassword2Modal = () => {
            setErrorPassword2ModalOpen(true);
        };
        const openSucessRegistoModal = () => {
            setSucessRegistoModalOpen(true);
        };
        const openSucessEmailModal = () => {
            setSucessEmailModalOpen(true);
        };

        const [email, setEmail] = useState("");
        const [password, setPassword] = useState("");
        const [confirmPassword, setConfirmPassword] = useState("");
        const [tipo, setTipo] = useState("");
        const [nome, setNome] = useState("");
        const [nif, setNif] = useState(0);
        const [dataNascimento, setDataNascimento] = useState("");
        const [morada, setMorada] = useState("");
        const [cp, setCp] = useState("");
        const [localidade, setLocalidade] = useState("");
        const [contacto, setContacto] = useState(0);
        const [linkedin, setLinkedin] = useState("");
        const [motivacao, setMotivacao] = useState("");
        const [file, setFile] = useState();
        const [profileImage, setProfileImage] = useState("");
        const [curriculo, setCurriculo] = useState("");
        const [escolha, setEscolha] = useState(0);
        const aceite = false;
        const cancelamento = "";

        const handleEscolhaChange = (option) => {
            setEscolha(option);
        };

        const signIn = (e) => {
            e.preventDefault();
            signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    handleOptionChange("Inicio");
                }).catch((error) => {
                console.log(error);
                openErrorLoginModal();
            })
        }

        function generateUserData(userCredential) {
            const userData = {};

            // Converter nif e contacto em números inteiros
            const nifNumber = parseInt(nif);
            const contactoNumber = parseInt(contacto);

            const email = userCredential.user.email;
            const userId = userCredential.user.uid;

            userData[userId] = {};
            userData[userId].tipo = tipo;
            userData[userId].nome = nome;
            userData[userId].nif = nifNumber;
            userData[userId].dataNascimento = dataNascimento;
            userData[userId].morada = morada;
            userData[userId].cp = cp;
            userData[userId].localidade = localidade;
            userData[userId].email = email;
            userData[userId].contacto = contactoNumber;
            userData[userId].linkedin = linkedin;
            userData[userId].motivacao = motivacao;
            userData[userId].profileImage = profileImage;
            userData[userId].curriculo = curriculo;
            userData[userId].escolha = escolha;
            userData[userId].aceite = aceite;
            userData[userId].cancelamento = cancelamento;

            return userData;

        }

        function writeUserData(userData, userId) {
            const userDataRef = ref(database, 'UserData');

            // Verificar se os dados já existem
            get(userDataRef)
                .then((snapshot) => {
                    const existingData = snapshot.val();

                    if (existingData && existingData[userId]) {
                        console.log('Os dados do user já existem, não serão atualizados.');
                        handleOptionChange("Inicio");
                    } else {
                        // Atualizar os dados específicos do usuário
                        const userRef = ref(database, `UserData/${userId}`);
                        update(userRef, userData[userId])
                            .then(() => {
                                console.log('Dados do user atualizados com sucesso!');
                                handleOptionChange("Inicio");
                            })
                            .catch((error) => {
                                console.log(error);
                                openErrorLoginModal();
                            });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    openErrorLoginModal();
                });
        }

        const signInWithGoogle = async () => {
            try {
                const provider = new GoogleAuthProvider();
                const userCredential = await signInWithPopup(auth, provider);

                const userData = generateUserData(userCredential);

                const userId = userCredential.user.uid;

                writeUserData(userData, userId);
            } catch (error) {
                console.log(error);
                openErrorLoginModal();
            }
        };

        const forgotPassword = (e) => {
            e.preventDefault();
            sendPasswordResetEmail(auth, email)
                .then(() => {
                    console.log("Email enviado com sucesso");
                    openSucessEmailModal();
                })
                .catch((error) => {
                    console.log(error);
                    openErrorEmail2Modal();
                });
        };

        const signUp = (e) => {
            e.preventDefault();

            createUserWithEmailAndPassword(auth, email, password)
                .then(async (userCredential) => {
                    const userData = generateUserData(userCredential);

                    const userId = userCredential.user.uid;

                    writeUserData(userData, userId);
                })
                .catch((error) => {
                    if (password !== confirmPassword) {
                        openErrorPassword2Modal();
                    } else if (error.code === AuthErrorCodes.EMAIL_EXISTS) {
                        openErrorEmailModal();
                    } else if (error.code === AuthErrorCodes.WEAK_PASSWORD) {
                        openErrorPasswordModal();
                    } else {
                        console.log(error);
                    }
                })
        };

        useEffect(() => {
            if (!file) {
                return;
            }
            const fileReader = new FileReader();
            fileReader.onload = () => {
                setPreviewUrl(fileReader.result);
            };
            fileReader.readAsDataURL(file);
        }, [file]);

        function imageSelectedHandler(event) {
            let pickedFile;
            if (event.target.files && event.target.files.length === 1) {
                pickedFile = event.target.files[0];
                setFile(pickedFile);

                const storageRef = refStorage(storage, `profileImages/${pickedFile.name + v4()}`);

                uploadBytes(storageRef, pickedFile)
                    .then((snapshot) => {
                        console.log("Imagem carregada com sucesso");
                        // Obter a URL do arquivo carregado
                        getDownloadURL(snapshot.ref)
                            .then((downloadURL) => {
                                console.log("File available at", downloadURL);
                                setProfileImage(downloadURL);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }

        function pickImageHandler() {
            filePickerRef.current.click();
        }

        function handleCurriculoUpload(event) {
            const file = event.target.files[0];
            const storageRef = refStorage(storage, `curriculos/${file.name}`);

            uploadBytes(storageRef, file)
                .then((snapshot) => {
                    console.log("Currículo carregado com sucesso");
                    getDownloadURL(snapshot.ref)
                        .then((downloadURL) => {
                            console.log("File available at", downloadURL);
                            setCurriculo(downloadURL);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                })
                .catch((error) => {
                    console.log("Erro ao carregar currículo:", error);
                });
        }


        const [showRegister, setShowRegister] = useState(false);

//se tiver igual a um numero é aquilo, senao é null
        const handleRegisterClick = () => {
            setShowRegister(true);
        };

        const handleBackToLoginClick = () => {
            setShowRegister(false);
        };

        const [showPassword, setShowPassword] = useState(false);
        const [showConfirmPassword, setShowConfirmPassword] = useState(false);

        const togglePasswordVisibility = () => {
            setShowPassword(!showPassword);
        };

        const toggleConfirmPasswordVisibility = () => {
            setShowConfirmPassword(!showConfirmPassword);
        };

        const openPlantaModal = () => {
            setPlantaModalOpen(true);
        };
        return (
            <div className="mx-auto">
                <section className="bg-marca min-h-screen flex items-center justify-center">

                    <Modal
                        isOpen={errorLoginModalOpen}
                        onRequestClose={() => setErrorLoginModalOpen(false)}
                        contentLabel="Error Modal"
                        style={{
                            overlay: {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)'
                            },
                            content: {
                                position: 'relative',
                                top: 'auto',
                                left: 'auto',
                                right: 'auto',
                                bottom: 'auto',
                                maxWidth: '90%',
                                maxHeight: '90%',
                                padding: '20px',
                                border: 'none',
                                borderRadius: '8px',
                                backgroundColor: '#ffffff',
                                overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                outline: 'none'
                            }
                        }}
                    >

                        <div className="flex items-center justify-center">
                            <div className="px-8 md:px-16 mt-6 mb-6">
                                <h2 className={`${styles.erro}`}>Error de Autenticação</h2>
                                <p className={`${styles.texto} mb-3`}>Ocorreu um erro durante a autenticação.<br/> Por
                                    favor,
                                    verifica se introduziste os dados corretos e tenta
                                    novamente. <br/> Caso Contrário, podes seguir um dos passos seguintes:</p>
                                <li><em>Se já fizeste login com o Google, terás de efetuar a sessão por lá.</em></li>
                                <li><em>Se é a tua primeira vez a aceder à nossa página, ou a utilizar os nossos serviços,
                                    deves
                                    efetuar o teu registo.</em></li>
                                <li><em>Recuperar a tua password, em caso de esquecimento ou perda da mesma.</em></li>
                                <button
                                    className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300 ml-2 mt-3"
                                    onClick={() => setErrorLoginModalOpen(false)}
                                >
                                    Tentar Novamente
                                </button>

                            </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={errorEmailModalOpen}
                        onRequestClose={() => setErrorEmailModalOpen(false)}
                        contentLabel="Error Modal"
                        style={{
                            overlay: {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)'
                            },
                            content: {
                                position: 'relative',
                                top: 'auto',
                                left: 'auto',
                                right: 'auto',
                                bottom: 'auto',
                                maxWidth: '90%',
                                maxHeight: '90%',
                                padding: '20px',
                                border: 'none',
                                borderRadius: '8px',
                                backgroundColor: '#ffffff',
                                overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                outline: 'none'
                            }
                        }}
                    >

                        <div className="flex items-center justify-center">
                            <div className="px-8 md:px-16 mt-6 mb-6">
                                <h2 className={`${styles.erro}`}>Error de Registo</h2>
                                <p className={`${styles.texto} mb-3`}>O Email que introduziste já está em uso.<br/> Por
                                    favor,
                                    usa um novo ou inicia sessão.</p>
                                <button
                                    className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300 ml-2 mt-3"
                                    onClick={() => setErrorEmailModalOpen(false)}
                                >
                                    Tentar Novamente
                                </button>

                            </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={errorEmail2ModalOpen}
                        onRequestClose={() => setErrorEmail2ModalOpen(false)}
                        contentLabel="Error Modal"
                        style={{
                            overlay: {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)'
                            },
                            content: {
                                position: 'relative',
                                top: 'auto',
                                left: 'auto',
                                right: 'auto',
                                bottom: 'auto',
                                maxWidth: '90%',
                                maxHeight: '90%',
                                padding: '20px',
                                border: 'none',
                                borderRadius: '8px',
                                backgroundColor: '#ffffff',
                                overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                outline: 'none'
                            }
                        }}
                    >

                        <div className="flex items-center justify-center">
                            <div className="px-8 md:px-16 mt-6 mb-6">
                                <h2 className={`${styles.erro}`}>Email Inválido</h2>
                                <p className={`${styles.texto} mb-3`}>O Email que introduziste não existe.<br/> Por favor,
                                    usa um email diferente ou cria uma conta.</p>
                                <button
                                    className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300 ml-2 mt-3"
                                    onClick={() => setErrorEmail2ModalOpen(false)}
                                >
                                    Tentar Novamente
                                </button>

                            </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={errorPasswordModalOpen}
                        onRequestClose={() => setErrorPasswordModalOpen(false)}
                        contentLabel="Error Modal"
                        style={{
                            overlay: {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)'
                            },
                            content: {
                                position: 'relative',
                                top: 'auto',
                                left: 'auto',
                                right: 'auto',
                                bottom: 'auto',
                                maxWidth: '90%',
                                maxHeight: '90%',
                                padding: '20px',
                                border: 'none',
                                borderRadius: '8px',
                                backgroundColor: '#ffffff',
                                overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                outline: 'none'
                            }
                        }}
                    >

                        <div className="flex items-center justify-center">
                            <div className="px-8 md:px-16 mt-6 mb-6">
                                <h2 className={`${styles.erro}`}>Error de Registo</h2>
                                <p className={`${styles.texto} mb-3`}>A Password que introduziste é demasiado
                                    fraca.<br/> Por
                                    favor,
                                    usa pelo menos 6 caracteres.</p>
                                <button
                                    className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300 ml-2 mt-3"
                                    onClick={() => setErrorPasswordModalOpen(false)}
                                >
                                    Tentar Novamente
                                </button>

                            </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={errorPassword2ModalOpen}
                        onRequestClose={() => setErrorPassword2ModalOpen(false)}
                        contentLabel="Error Modal"
                        style={{
                            overlay: {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)'
                            },
                            content: {
                                position: 'relative',
                                top: 'auto',
                                left: 'auto',
                                right: 'auto',
                                bottom: 'auto',
                                maxWidth: '90%',
                                maxHeight: '90%',
                                padding: '20px',
                                border: 'none',
                                borderRadius: '8px',
                                backgroundColor: '#ffffff',
                                overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                outline: 'none'
                            }
                        }}
                    >

                        <div className="flex items-center justify-center">
                            <div className="px-8 md:px-16 mt-6 mb-6">
                                <h2 className={`${styles.erro}`}>Error de Registo</h2>
                                <p className={`${styles.texto} mb-3`}>As Password's que introduziste não são
                                    idênticas.<br/> Por
                                    favor, corrige esse pormenor.</p>
                                <button
                                    className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300 ml-2 mt-3"
                                    onClick={() => setErrorPassword2ModalOpen(false)}
                                >
                                    Tentar Novamente
                                </button>

                            </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={sucessRegistoModalOpen}
                        onRequestClose={() => window.location.href = '/'}
                        contentLabel="Sucess Modal"
                        style={{
                            overlay: {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)'
                            },
                            content: {
                                position: 'relative',
                                top: 'auto',
                                left: 'auto',
                                right: 'auto',
                                bottom: 'auto',
                                maxWidth: '90%',
                                maxHeight: '90%',
                                padding: '20px',
                                border: 'none',
                                borderRadius: '8px',
                                backgroundColor: '#ffffff',
                                overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                outline: 'none'
                            }
                        }}
                    >

                        <div className="flex items-center justify-center">
                            <div className="px-8 md:px-16 mt-6 mb-6">
                                <h2 className={`${styles.sucess}`}>Utilizador Registado com Sucesso</h2>
                                <p className={`${styles.texto} mb-3`}>Bem-vindo à tua nova casa. Estamos felizes por te te
                                    conosco. Sente-te à vontade para explorar todas as funcionalidades e recursos
                                    disponíveis.
                                    Em caso de dúvidas, a nossa equipa está pronta para te ajudar. Aproveita a tua
                                    experiência!
                                </p>
                                <button
                                    className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300 ml-2 mt-3"
                                    onClick={() => window.location.href = '/'}
                                >
                                    Efetuar Login
                                </button>

                            </div>
                        </div>
                    </Modal>
                    <Modal
                        isOpen={sucessEmailModalOpen}
                        onRequestClose={() => setSucessEmailModalOpen(false)}
                        contentLabel="Sucess Modal"
                        style={{
                            overlay: {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)'
                            },
                            content: {
                                position: 'relative',
                                top: 'auto',
                                left: 'auto',
                                right: 'auto',
                                bottom: 'auto',
                                maxWidth: '90%',
                                maxHeight: '90%',
                                padding: '20px',
                                border: 'none',
                                borderRadius: '8px',
                                backgroundColor: '#ffffff',
                                overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                outline: 'none'
                            }
                        }}
                    >

                        <div className="flex items-center justify-center">
                            <div className="px-8 md:px-16 mt-6 mb-6">
                                <h2 className={`${styles.sucess}`}>Verifica a Caixa de Correio do teu Email</h2>
                                <p className={`${styles.texto} mb-3`}>Foi-te enviado um link, acede e altera a tua
                                    palavra-passe.</p>
                                <button
                                    className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300 ml-2 mt-3"
                                    onClick={() => setSucessEmailModalOpen(false)}
                                >
                                    Fechar
                                </button>

                            </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={plantaModalOpen}
                        onRequestClose={() => setPlantaModalOpen(false)}
                        contentLabel="Sucess Modal"
                        style={{
                            overlay: {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)'
                            },
                            content: {
                                position: 'relative',
                                top: 'auto',
                                left: 'auto',
                                right: 'auto',
                                bottom: 'auto',
                                maxWidth: '100%',
                                maxHeight: '90%',
                                padding: '20px',
                                border: 'none',
                                borderRadius: '8px',
                                backgroundColor: '#ffffff',
                                overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                outline: 'none'
                            }
                        }}
                    >
                        <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
                            <Image style={{width: 24, height: 24, alignSelf: "flex-end", margin: 10}} source={reservation}/>
                            <div className="bg-planta ml-16">
                                <PlantaCard handleEscolhaChange={handleEscolhaChange}/>
                            </div>
                            <button
                                className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300 ml-2 mt-3"
                                onClick={() => setPlantaModalOpen(false)}
                            >
                                Fechar
                            </button>
                        </View>
                    </Modal>

                    <a href="/" className="bg-verdeTropa logo rounded-2xl">
                        <img src={logo} alt="logo" className="mt-2 mb-2 ml-2 mr-2"/>
                    </a>

                    {showRegister ? (
                        <>
                            <div className="form-container items-center">
                                <div className="form-container bg-gray-100 rounded-2xl shadow-lg px-8 md:px-16 mt-6 mb-6">
                                    <div className="form-header">
                                        <h3 className={`${styles.h3}`}>Bem Vindo à tua nova casa</h3>
                                        <p className={`${styles.texto}`}>Preenche o Formulário de Pré-Candidatura:</p>
                                    </div>
                                    <form onSubmit={signUp} className="flex flex-col gap-1">
                                        <p className={`${styles.texto}`}>Fotografia de Perfil</p>
                                        <div className={`${styles.paddingX} ${styles.flexCenter}`}>
                                            <div className={`${styles.boxWidth} flex flex-col items-center`}>
                                                <input
                                                    id="profileImage"
                                                    ref={filePickerRef}
                                                    style={{display: 'none'}}
                                                    type="file"
                                                    accept=".jpg,.png,.jpeg"
                                                    onChange={imageSelectedHandler}
                                                />

                                                <div className={`image-upload`}>
                                                    <div className="image-upload__preview">
                                                        {previewUrl && <img src={previewUrl} alt="preview"/>}
                                                        {!previewUrl && (
                                                            <div className="image-upload-button">
                                                                <Button className="image-upload-button" type="button"
                                                                        onClick={pickImageHandler}>+</Button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {previewUrl && (
                                                    <div className="image-upload-button">
                                                        <Button className="image-upload-button flex-col items-center"
                                                                type="button"
                                                                onClick={pickImageHandler}>
                                                            <MdModeEdit className="icon mt-4"></MdModeEdit>
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <p className={`${styles.texto}`}>Pretendes ser um utilizador Temporário ou Fixo? *
                                            (<a href="/#contratos" target="_blank" rel="noopener noreferrer"
                                                className="hover:text-amber-300 underline">Ver Contratos</a>) </p>
                                        <div className="flex flex-row gap-4">
                                            <div className="flex flex-row items-center">
                                                <input
                                                    type="radio"
                                                    id="temporario"
                                                    name="tipo"
                                                    onChange={(e) => setTipo(e.target.value)}
                                                    value="Temporario"
                                                    checked={tipo === "Temporario"}
                                                    required
                                                />
                                                <label htmlFor="temporario" className="ml-2">Temporário</label>
                                            </div>
                                            <div className="flex flex-row items-center">
                                                <input
                                                    type="radio"
                                                    id="fixo"
                                                    name="tipo"
                                                    onChange={(e) => setTipo(e.target.value)}
                                                    value="Fixo"
                                                    checked={tipo === "Fixo"}
                                                    required
                                                />
                                                <label htmlFor="fixo" className="ml-2">Fixo</label>
                                                {tipo === "Fixo" && (
                                                    <>
                                                        <p
                                                            className={`mb-1 ml-3 text-black cursor-pointer hover:text-amber-200 underline`}
                                                            onClick={openPlantaModal}
                                                        >
                                                            Ver Disp.
                                                        </p>
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        <li><em><strong>Temporário -</strong> Sem lugar reservado, espaço entregue mediante
                                            a
                                            disponibilidade.</em></li>
                                        <li><em><strong>Fixo -</strong> Um espaço só teu. Irá ficar como temporário até o
                                            termo
                                            de aceitação.</em></li>
                                        <p className={`${styles.texto}`}>Nome Completo *</p>
                                        <input className="p-2 rounded-xl border" type="text" name="nome"
                                               onChange={(e) => setNome(e.target.value)}
                                               value={nome}
                                               placeholder="Nome Completo" required/>
                                        <p className={`${styles.texto}`}>Número de Contribuínte (NIF) *</p>
                                        <input className="p-2 rounded-xl border" type="text" pattern="[0-9]{9}"
                                               maxLength="9"
                                               id="nif"
                                               name="nif" placeholder="000000000" value={nif}
                                               onChange={(e) => setNif(e.target.value)} required
                                               onInput={(e) => {
                                                   e.target.value = e.target.value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
                                               }}/>
                                        <p className={`${styles.texto}`}>Data de Nascimento *</p>
                                        <input className="p-2 rounded-xl border" type="date" name="dataNascimento"
                                               value={dataNascimento}
                                               onChange={(e) => setDataNascimento(e.target.value)} required/>
                                        <li><em>Menores de 18 anos requerem de autorização dos seus representantes
                                            legais.</em>
                                        </li>
                                        <p className={`${styles.texto}`}>Morada *</p>
                                        <input className="p-2 rounded-xl border" type="text" name="morada"
                                               placeholder="Morada" onChange={(e) => setMorada(e.target.value)}
                                               value={morada} required/>
                                        <p className={`${styles.texto}`}>Código Postal *</p>
                                        <input className="p-2 rounded-xl border" type="text" pattern="[0-9]{4}-[0-9]{3}"
                                               maxLength="8" id="cp"
                                               name="cp" placeholder="0000-000" value={cp}
                                               onChange={(e) => setCp(e.target.value)} required
                                               onInput={(e) => { // 0000-000
                                                   e.target.value = e.target.value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
                                                   e.target.value = e.target.value.replace(/(\d{4})(\d)/, "$1-$2"); // Coloca um hífen entre o quarto e o quinto dígito
                                               }}/>
                                        <p className={`${styles.texto}`}>Localidade *</p>
                                        <input className="p-2 rounded-xl border" type="text" name="localidade"
                                               placeholder="Localidade" required
                                               onChange={(e) => setLocalidade(e.target.value)}
                                               value={localidade}/>
                                        <p className={`${styles.texto}`}>Email *</p>
                                        <input className="p-2 rounded-xl border" type="email" name="email"
                                               value={email}
                                               onChange={(e) => setEmail(e.target.value)}
                                               placeholder="Email" required/>
                                        <p className={`${styles.texto}`}>Password *</p>
                                        <div className="relative">
                                            <div className="input-box">
                                                <input
                                                    className="p-2 w-full rounded-xl border"
                                                    type={showPassword ? 'text' : 'password'}
                                                    name="password" value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    placeholder="Password"
                                                    required
                                                />
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={16}
                                                    height={16}
                                                    fill="gray"
                                                    className="bi bi-eye absolute right-3 top-6 -translate-y-1/2 cursor-pointer"
                                                    viewBox="0 0 16 16"
                                                    onClick={togglePasswordVisibility}
                                                >
                                                    {showPassword ? (
                                                        <>
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M7.998 1C4.68 1 1.825 2.78.513 5.288a.534.534 0 0 0 0 .424C1.825 9.22 4.68 11 7.998 11s6.173-1.78 7.485-4.288a.534.534 0 0 0 0-.424C14.172 2.78 11.318 1 7.998 1zm0 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M15.947 7.98C14.885 10.613 12.133 13 7.998 13c-4.135 0-6.888-2.387-7.95-5.02a.76.76 0 0 1 0-.962C1.11 3.387 3.862 1 7.998 1c4.135 0 6.887 2.387 7.949 5.02a.76.76 0 0 1 0 .962zM2.934 7.74c.655 1.78 2.35 3.06 4.064 3.06 1.713 0 3.409-1.28 4.064-3.06l-1.76-.65c-.44 1.08-1.67 1.89-3.304 1.89-1.634 0-2.864-.81-3.303-1.89l-1.76.65z"
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M7.998 1C4.68 1 1.825 2.78.513 5.288a.534.534 0 0 0 0 .424C1.825 9.22 4.68 11 7.998 11s6.173-1.78 7.485-4.288a.534.534 0 0 0 0-.424C14.172 2.78 11.318 1 7.998 1zm0 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                                            />

                                                        </>
                                                    )}
                                                </svg>
                                            </div>
                                        </div>
                                        <p className={`${styles.texto}`}>Confirmar Password *</p>
                                        <div className="relative">
                                            <input
                                                className="p-2 w-full rounded-xl border"
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                name="confirmPassword" value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                placeholder="Confirmar Password"
                                                required
                                            />
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={16}
                                                height={16}
                                                fill="gray"
                                                className="bi bi-eye absolute right-3 top-6 -translate-y-1/2 cursor-pointer"
                                                viewBox="0 0 16 16"
                                                onClick={toggleConfirmPasswordVisibility}
                                            >
                                                {showConfirmPassword ? (
                                                    <>
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M7.998 1C4.68 1 1.825 2.78.513 5.288a.534.534 0 0 0 0 .424C1.825 9.22 4.68 11 7.998 11s6.173-1.78 7.485-4.288a.534.534 0 0 0 0-.424C14.172 2.78 11.318 1 7.998 1zm0 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                                        />
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M15.947 7.98C14.885 10.613 12.133 13 7.998 13c-4.135 0-6.888-2.387-7.95-5.02a.76.76 0 0 1 0-.962C1.11 3.387 3.862 1 7.998 1c4.135 0 6.887 2.387 7.949 5.02a.76.76 0 0 1 0 .962zM2.934 7.74c.655 1.78 2.35 3.06 4.064 3.06 1.713 0 3.409-1.28 4.064-3.06l-1.76-.65c-.44 1.08-1.67 1.89-3.304 1.89-1.634 0-2.864-.81-3.303-1.89l-1.76.65z"
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M7.998 1C4.68 1 1.825 2.78.513 5.288a.534.534 0 0 0 0 .424C1.825 9.22 4.68 11 7.998 11s6.173-1.78 7.485-4.288a.534.534 0 0 0 0-.424C14.172 2.78 11.318 1 7.998 1zm0 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                                        />

                                                    </>
                                                )}
                                            </svg>
                                        </div>
                                        <p className={`${styles.texto}`}>Contacto Telefónico *</p>
                                        <input className="p-2 rounded-xl border" type="text" pattern="[0-9]{9}"
                                               maxLength="9"
                                               id="contactoTelefonico"
                                               name="phone" placeholder="000000000"
                                               onChange={(e) => setContacto(e.target.value)}
                                               value={contacto} required onInput={(e) => { // 000000000   // 9 dígitos
                                            e.target.value = e.target.value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
                                        }}/>
                                        <p className={`${styles.texto}`}>Linkedin</p>
                                        <input className="p-2 rounded-xl border" type="text" name="linkedin"
                                               placeholder="Linkedin"
                                               onChange={(e) => setLinkedin(e.target.value)} value={linkedin}/>
                                        <p className={`${styles.texto}`}>Qual a tua motivação para ingressar no Cowork?</p>
                                        <textarea className="p-2 rounded-xl border" name="motivacao" id="motivacao"
                                                  cols={30}
                                                  rows={5}
                                                  placeholder="Motivação"
                                                  onChange={(e) => setMotivacao(e.target.value)}
                                                  value={motivacao}/>
                                        <p className={`${styles.texto}`}>Anexa o teu Currículo: (CV, Portefólio, Memória
                                            Descritiva)</p>
                                        <input className="p-2 rounded-xl border" type="file" name="curriculo"
                                               onChange={handleCurriculoUpload} placeholder="Curriculo"/>
                                        <p> Aceitas os nossos <a href="#" className="hover:text-amber-300 underline">Termos
                                            de
                                            Utilização</a>? *</p>
                                        <div className="flex flex-row items-center">
                                            <input type="checkbox" id="termos" name="termos" value="termos" required/>
                                            <label htmlFor="termos" className="ml-2">Sim</label>
                                        </div>
                                        <div className={`${styles.menu} cursor-pointer mt-4`}>
                                            <Button2 type="submit" onClick={handleBackToLoginClick && signUp}
                                                     styles={"py-1.5 w-full rounded-xl hover:scale-105 duration-300"}
                                                     text={"Efetuar Registo"}/>
                                        </div>
                                    </form>

                                    <div className="column">
                                        <div
                                            className={`${styles.paragrafo} mt-3 text-xs flex justify-between items-center`}>
                                            <p>Já tens conta?</p>
                                            <button
                                                className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300"
                                                onClick={handleBackToLoginClick}
                                            >
                                                Clica Aqui!
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="bg-gray-100 flex rounded-2xl shadow-lg max-w-5xl p-4 items-center mt-6 mb-6">
                                <div className="md:block hidden w-1/2">
                                    <img src={imagemLogin} className="rounded-2xl w-full h-full"/>
                                </div>
                                {/* form */}
                                <div className="md:w-1/2 px-8 md:px-16 mt-6 mb-6">
                                    <h2 className={`${styles.h2}`}>Bem Vindo</h2>
                                    <p className={`${styles.texto}`}>Insere os teus dados nos espaços:</p>
                                    <form onSubmit={signIn} className="flex flex-col gap-4">
                                        <input className="p-2 mt-8 rounded-xl border" type="email" name="email"
                                               value={email}
                                               onChange={(e) => setEmail(e.target.value)}
                                               placeholder="Coloca o teu Email" required/>
                                        <div className="relative">
                                            <input
                                                className="p-2 rounded-xl border w-full"
                                                type={showPassword ? 'text' : 'password'}
                                                name="password" value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                placeholder="Coloca a tua Password"
                                            />
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={16}
                                                height={16}
                                                fill="gray"
                                                className="bi bi-eye absolute top-1/2 right-3 -translate-y-1/2 cursor-pointer"
                                                viewBox="0 0 16 16"
                                                onClick={togglePasswordVisibility}
                                            >
                                                {showPassword ? (
                                                    <>
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M7.998 1C4.68 1 1.825 2.78.513 5.288a.534.534 0 0 0 0 .424C1.825 9.22 4.68 11 7.998 11s6.173-1.78 7.485-4.288a.534.534 0 0 0 0-.424C14.172 2.78 11.318 1 7.998 1zm0 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                                        />
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M15.947 7.98C14.885 10.613 12.133 13 7.998 13c-4.135 0-6.888-2.387-7.95-5.02a.76.76 0 0 1 0-.962C1.11 3.387 3.862 1 7.998 1c4.135 0 6.887 2.387 7.949 5.02a.76.76 0 0 1 0 .962zM2.934 7.74c.655 1.78 2.35 3.06 4.064 3.06 1.713 0 3.409-1.28 4.064-3.06l-1.76-.65c-.44 1.08-1.67 1.89-3.304 1.89-1.634 0-2.864-.81-3.303-1.89l-1.76.65z"
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M7.998 1C4.68 1 1.825 2.78.513 5.288a.534.534 0 0 0 0 .424C1.825 9.22 4.68 11 7.998 11s6.173-1.78 7.485-4.288a.534.534 0 0 0 0-.424C14.172 2.78 11.318 1 7.998 1zm0 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                                        />

                                                    </>
                                                )}
                                            </svg>
                                        </div>
                                        <div className={`${styles.menu} cursor-pointer`}>
                                            <Button2 type="submit" onClick={signIn}
                                                     styles={"py-1.5 w-full rounded-xl hover:scale-105 duration-300"}
                                                     text={"Login"}/>
                                        </div>
                                    </form>
                                    <div className="mt-6 grid grid-cols-3 items-center text-gray-400">
                                        <hr className="border-gray-400"/>
                                        <p className="text-center text-sm">OU</p>
                                        <hr className="border-gray-400"/>
                                    </div>
                                    <button onClick={signInWithGoogle}
                                            className="bg-white border py-2 w-full rounded-xl mt-5 flex justify-center items-center text-sm hover:scale-105 duration-300 text-[#002D74]">
                                        <svg className="mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"
                                             width="25px">
                                            <path fill="#FFC107"
                                                  d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"/>
                                            <path fill="#FF3D00"
                                                  d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"/>
                                            <path fill="#4CAF50"
                                                  d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"/>
                                            <path fill="#1976D2"
                                                  d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"/>
                                        </svg>
                                        Login with Google
                                    </button>
                                    <div className={`${styles.paragrafo} mt-5 text-xs border-b border-[#404944] py-4`}>
                                        <button
                                            className="hover:text-amber-300 underline"
                                            onClick={forgotPassword}
                                        >Perdeste a Password?
                                        </button>
                                    </div>
                                    <div
                                        className={`${styles.paragrafo} mt-3 text-xs flex justify-between items-center cursor-pointer`}>
                                        <p>Não tens conta?</p>
                                        <button
                                            className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300"
                                            onClick={handleRegisterClick}
                                        >
                                            Regista-te Aqui!
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </section>
            </div>
        );
    }
;

export default Login;
