import "./Espacos.css";
import EspacosData from "./EspacosData";
import Espaco1 from '../images/padel.jpg';
import Espaco2 from '../images/estadio.jpg';
import Espaco3 from '../images/basquete.jpg';

function Espacos() {
    return (
        <div className="espacos">
            <h1>Espaços Desportivos</h1>
            <p>Os melhores espaços para praticar desporto, desde o moderno Campo de Padel,
                passando pelo tradicional Estádio Municipal Ferragial d'El Rei, até ao vibrante Campo de Basquetebol,
                cada espaço oferece instalações de qualidade para diferentes modalidades desportivas.</p>
            <div className="espacoscard">
                <EspacosData
                    image={Espaco1}
                    heading="Campo de Padel"
                    text="Desfrute de momentos de diversão e competição no moderno Campo de Padel, equipado para receber jogadores de todos os níveis."
                />
                <EspacosData
                    image={Espaco2}
                    heading="Estádio Ferragial d'El Rei"
                    text="Viva a paixão pelo futebol no Estádio Ferragial d'El Rei, um espaço tradicional que acolhe eventos e jogos memoráveis."
                />
                <EspacosData
                    image={Espaco3}
                    heading="Campo de Basquetebol"
                    text="Jogue uma partida no vibrante Campo de Basquetebol."
                />
            </div>
        </div>
    );
}

export default Espacos;