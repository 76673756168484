import React, {useState} from 'react';
import Modal from 'react-modal';
import styles from "../style";
import Button2 from "../components/normalUser/Button2";

import {logo} from "../images";

import {AuthErrorCodes, confirmPasswordReset} from "firebase/auth";
import {auth} from "../firebase";


const ResetPassword = () => {
    const [errorPasswordModalOpen, setErrorPasswordModalOpen] = useState(false);
    const [errorPassword2ModalOpen, setErrorPassword2ModalOpen] = useState(false);
    const [sucessRegistoModalOpen, setSucessRegistoModalOpen] = useState(false);
    const openErrorPasswordModal = () => {
        setErrorPasswordModalOpen(true);
    };
    const openSucessRegistoModal = () => {
        setSucessRegistoModalOpen(true);
    };
    const openErrorPassword2Modal = () => {
        setErrorPassword2ModalOpen(true);
    };

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const useQuery = () => {
        return new URLSearchParams(window.location.search);
    };

    const query = useQuery();

    const resetPassword = (e) => {
        e.preventDefault();

        if (password.length < 6) {
            openErrorPasswordModal();
        } else {
            confirmPasswordReset(auth, query.get("oobCode"), password)
                .then(() => {
                    openSucessRegistoModal();
                })
                .catch((error) => {
                    if (password !== confirmPassword) {
                        openErrorPassword2Modal();
                    } else if (error.code === AuthErrorCodes.WEAK_PASSWORD) {
                        openErrorPasswordModal();
                    } else {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        console.log(errorCode);
                        console.log(errorMessage);
                    }
                });
        }
    };
    return (
        <section className="bg-marca min-h-screen flex items-center justify-center">

            <Modal
                isOpen={errorPasswordModalOpen}
                onRequestClose={() => setErrorPasswordModalOpen(false)}
                contentLabel="Error Modal"
                style={{
                    overlay: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    },
                    content: {
                        position: 'relative',
                        top: 'auto',
                        left: 'auto',
                        right: 'auto',
                        bottom: 'auto',
                        maxWidth: '90%',
                        maxHeight: '90%',
                        padding: '20px',
                        border: 'none',
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        outline: 'none'
                    }
                }}
            >

                <div className="flex items-center justify-center">
                    <div className="px-8 md:px-16 mt-6 mb-6">
                        <h2 className={`${styles.erro}`}>Error de Registo</h2>
                        <p className={`${styles.texto} mb-3`}>A Password que introduziste é demasiado fraca.<br/> Por
                            favor, usa pelo menos 6 caracteres.</p>
                        <button
                            className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300 ml-2 mt-3"
                            onClick={() => setErrorPasswordModalOpen(false)}
                        >
                            Tentar Novamente
                        </button>

                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={errorPassword2ModalOpen}
                onRequestClose={() => setErrorPassword2ModalOpen(false)}
                contentLabel="Error Modal"
                style={{
                    overlay: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    },
                    content: {
                        position: 'relative',
                        top: 'auto',
                        left: 'auto',
                        right: 'auto',
                        bottom: 'auto',
                        maxWidth: '90%',
                        maxHeight: '90%',
                        padding: '20px',
                        border: 'none',
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        outline: 'none'
                    }
                }}
            >

                <div className="flex items-center justify-center">
                    <div className="px-8 md:px-16 mt-6 mb-6">
                        <h2 className={`${styles.erro}`}>Error de Registo</h2>
                        <p className={`${styles.texto} mb-3`}>As Password's que introduziste não são idênticas.<br/> Por
                            favor, corrige esse pormenor.</p>
                        <button
                            className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300 ml-2 mt-3"
                            onClick={() => setErrorPassword2ModalOpen(false)}
                        >
                            Tentar Novamente
                        </button>

                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={sucessRegistoModalOpen}
                onRequestClose={() => window.location.href = '/login'}
                contentLabel="Sucess Modal"
                style={{
                    overlay: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    },
                    content: {
                        position: 'relative',
                        top: 'auto',
                        left: 'auto',
                        right: 'auto',
                        bottom: 'auto',
                        maxWidth: '90%',
                        maxHeight: '90%',
                        padding: '20px',
                        border: 'none',
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        outline: 'none'
                    }
                }}
            >

                <div className="flex items-center justify-center">
                    <div className="px-8 md:px-16 mt-6 mb-6">
                        <h2 className={`${styles.sucess}`}>Password Alterada com Sucesso</h2>
                        <p className={`${styles.texto} mb-3`}>Aproveita, já podes fazer login com a tua nova
                            Password!</p>
                        <button
                            className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300 ml-2 mt-3"
                            onClick={() => window.location.href = '/'}
                        >
                            Fechar
                        </button>

                    </div>
                </div>
            </Modal>

            <a href="/" className="bg-verdeTropa logo rounded-2xl">
                <img src={logo} alt="logo" className="mt-2 mb-2 ml-2 mr-2"/>
            </a>

            <div className="bg-gray-100 flex rounded-2xl shadow-lg max-w-5xl p-4 items-center mt-6 mb-6">
                <div className="px-8 md:px-16 mt-6 mb-6">
                    <h2 className={`${styles.h2}`}>Processo de alteração da Password</h2>
                    <p className={`${styles.texto}`}>Preenche o Formulário com a nova Password:</p>

                    <form onSubmit={resetPassword} className="flex flex-col gap-1">
                        <p className={`${styles.texto}`}>Password *</p>
                        <div className="relative">
                            <input
                                className="p-2 rounded-xl border w-full"
                                type={showPassword ? 'text' : 'password'}
                                name="password" value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Password"
                                required
                            />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                fill="gray"
                                className="bi bi-eye absolute top-1/2 right-3 -translate-y-1/2 cursor-pointer"
                                viewBox="0 0 16 16"
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? (
                                    <>
                                        <path
                                            fillRule="evenodd"
                                            d="M7.998 1C4.68 1 1.825 2.78.513 5.288a.534.534 0 0 0 0 .424C1.825 9.22 4.68 11 7.998 11s6.173-1.78 7.485-4.288a.534.534 0 0 0 0-.424C14.172 2.78 11.318 1 7.998 1zm0 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            d="M15.947 7.98C14.885 10.613 12.133 13 7.998 13c-4.135 0-6.888-2.387-7.95-5.02a.76.76 0 0 1 0-.962C1.11 3.387 3.862 1 7.998 1c4.135 0 6.887 2.387 7.949 5.02a.76.76 0 0 1 0 .962zM2.934 7.74c.655 1.78 2.35 3.06 4.064 3.06 1.713 0 3.409-1.28 4.064-3.06l-1.76-.65c-.44 1.08-1.67 1.89-3.304 1.89-1.634 0-2.864-.81-3.303-1.89l-1.76.65z"
                                        />
                                    </>
                                ) : (
                                    <>
                                        <path
                                            fillRule="evenodd"
                                            d="M7.998 1C4.68 1 1.825 2.78.513 5.288a.534.534 0 0 0 0 .424C1.825 9.22 4.68 11 7.998 11s6.173-1.78 7.485-4.288a.534.534 0 0 0 0-.424C14.172 2.78 11.318 1 7.998 1zm0 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                        />

                                    </>
                                )}
                            </svg>
                        </div>
                        <p className={`${styles.texto}`}>Confirmar Password *</p>
                        <div className="relative">
                            <input
                                className="p-2 rounded-xl border w-full"
                                type={showConfirmPassword ? 'text' : 'password'}
                                name="confirmPassword" value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="Confirmar Password"
                                required
                            />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                fill="gray"
                                className="bi bi-eye absolute top-1/2 right-3 -translate-y-1/2 cursor-pointer"
                                viewBox="0 0 16 16"
                                onClick={toggleConfirmPasswordVisibility}
                            >
                                {showConfirmPassword ? (
                                    <>
                                        <path
                                            fillRule="evenodd"
                                            d="M7.998 1C4.68 1 1.825 2.78.513 5.288a.534.534 0 0 0 0 .424C1.825 9.22 4.68 11 7.998 11s6.173-1.78 7.485-4.288a.534.534 0 0 0 0-.424C14.172 2.78 11.318 1 7.998 1zm0 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            d="M15.947 7.98C14.885 10.613 12.133 13 7.998 13c-4.135 0-6.888-2.387-7.95-5.02a.76.76 0 0 1 0-.962C1.11 3.387 3.862 1 7.998 1c4.135 0 6.887 2.387 7.949 5.02a.76.76 0 0 1 0 .962zM2.934 7.74c.655 1.78 2.35 3.06 4.064 3.06 1.713 0 3.409-1.28 4.064-3.06l-1.76-.65c-.44 1.08-1.67 1.89-3.304 1.89-1.634 0-2.864-.81-3.303-1.89l-1.76.65z"
                                        />
                                    </>
                                ) : (
                                    <>
                                        <path
                                            fillRule="evenodd"
                                            d="M7.998 1C4.68 1 1.825 2.78.513 5.288a.534.534 0 0 0 0 .424C1.825 9.22 4.68 11 7.998 11s6.173-1.78 7.485-4.288a.534.534 0 0 0 0-.424C14.172 2.78 11.318 1 7.998 1zm0 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                        />

                                    </>
                                )}
                            </svg>
                        </div>
                        <div className={`${styles.menu} cursor-pointer mt-4`}>
                            <Button2 type="submit" onClick={resetPassword}
                                     styles={"py-1.5 w-full rounded-xl hover:scale-105 duration-300"}
                                     text={"Alterar Password"}/>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default ResetPassword;
