import styles, {layout} from "../../style";
import React, {useEffect, useState} from "react";
import SelectCowork from "./SelectCowork";
import MapContainer from './MapContainer';

import Divisorio from "./Divisorio";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import {coworks} from "../../constants";
import {Image, View} from "react-native";
import {reservation} from "../../images";
import Modal from "react-modal";

import './HorizontalScrollButtons.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDays} from "@fortawesome/free-solid-svg-icons";
import {
    addDays,
    format,
    setMinutes,
    getMinutes,
    getHours,
    isSameDay
} from "date-fns";
import {DateRange} from "react-date-range";
import {database} from "../../firebase";
import {get, onValue, push, query, ref, update} from "firebase/database";
import {LocalizationProvider, MobileTimePicker} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import PlantaReservas from "./PlantaReservas";
import PlantaNovaHora from "./PlantaNovaHora";
import {toast, ToastContainer} from "react-toastify";

const Reservar = ({authUser, handleOptionChange, notifica}) => {
    const [planta, setPlanta] = useState({});
    const [selectedCoworkName, setSelectedCoworkName] = useState(coworks[0].title);
    const [selectedCowork, setSelectedCowork] = useState(coworks[0].coordenadas);
    const [selectedOption, setSelectedOption] = useState('diario');
    const [plantaModalOpen, setPlantaModalOpen] = useState(false);
    const [plantaModalOpen2, setPlantaModalOpen2] = useState(false);
    const [selectedReservaIndex, setSelectedReservaIndex] = useState(null);
    const [selectedReservaIndex2, setSelectedReservaIndex2] = useState(null);
    const [showReservas, setShowReservas] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reservas, setReservas] = useState([]); // Estado para armazenar as reservas
    let [inicio, setInicio] = useState();
    const [fim, setFim] = useState();
    const [lugares, setLugares] = useState({}); // Estado para armazenar os lugares da planta
    const [checkIn, setCheckIn] = useState(null);
    const [checkIn2, setCheckIn2] = useState(null);
    const [checkOut, setCheckOut] = useState(null);
    const [checkOut2, setCheckOut2] = useState(null);
    const [disponibilidade, setDisponibilidade] = useState(null);
    const [disponibilidade2, setDisponibilidade2] = useState(null);
    const [agendamento, setAgendamento] = useState([]);
    const [agendamento2, setAgendamento2] = useState([]);
    const [viewCheckOut, setViewCheckOut] = useState(false);
    const [viewCheckOut2, setViewCheckOut2] = useState(false);
    const [viewButton, setViewButton] = useState(false);
    const [viewButton2, setViewButton2] = useState(false);
    const [viewInfos, setViewInfos] = useState(false);
    const [viewInfos2, setViewInfos2] = useState(false);
    const [viewMes, setViewMes] = useState("");
    const [viewMes2, setViewMes2] = useState("");
    const [viewMinhasReservas, setViewMinhasReservas] = useState(false);
    const [horasMes, setHorasMes] = useState([]);
    const [horasMes2, setHorasMes2] = useState([]);
    const [newHora, setNewHora] = useState(false);
    const [viewHorarioReservado, setViewHorarioReservado] = useState(false);
    const [viewHorarioReservado2, setViewHorarioReservado2] = useState(false);
    const [tipo, setTipo] = useState("");
    const [aceite, setAceite] = useState(false);
    const [reservasUser, setReservasUser] = useState([]);
    const currentDate = new Date();
    const dataHoje = format(currentDate, 'yyyy-MM-dd');
    const [existeReserva, setExisteReserva] = useState("");

    const userId = authUser.uid;
    const userDataRef = ref(database, `UserData/${userId}`);

    useEffect(() => {

        const listener = onValue(userDataRef, (snapshot) => {
            const userData = snapshot.val();

            if (userData) {
                setTipo(userData.tipo);
                setAceite(userData.aceite);
            } else {
                console.log("No such document!");
            }
        });

        return () => {
            listener();
        };
    }, []);

    useEffect(() => {
        getReservas();
    }, [authUser, userId]);

    useEffect(() => {
        if (reservasUser.length !== 0) {
            const aux = reservasUser[0].data;
            setExisteReserva(aux)
        }
    }, [reservasUser]);

    const getReservas = () => {
        const reservasRef = ref(database, 'Reservas/');
        const todasReservasQuery = query(reservasRef);

        get(todasReservasQuery)
            .then((snapshot) => {
                const reservasData = [];

                snapshot.forEach((reservaSnapshot) => {
                    const reservaKey = reservaSnapshot.key;
                    const reservaData = reservaSnapshot.val();

                    const data = new Date(reservaData.data);

                    // Verifica se o userId corresponde ao usuário selecionado
                    if (reservaData.userId === userId && reservaData.status !== "Finalizado" &&
                        (isSameDay(data, currentDate) || data > currentDate)) {
                        const reservaPath = `Reservas/${reservaKey}`;
                        reservasData.push({
                            reservaKey,
                            reservaPath,
                            ...reservaData,
                        });
                    }
                });

                // Ordena as reservas por data e 'CheckIn'
                reservasData.sort((a, b) => {
                    const dateA = new Date(a.data);
                    const dateB = new Date(b.data);

                    // Compara as datas
                    if (dateA < dateB) return -1;
                    if (dateA > dateB) return 1;

                    // Se as datas forem iguais, compara o 'CheckIn'
                    const checkInA = a.CheckIn.split(':');
                    const checkInB = b.CheckIn.split(':');

                    const hourA = parseInt(checkInA[0]);
                    const minuteA = parseInt(checkInA[1]);
                    const hourB = parseInt(checkInB[0]);
                    const minuteB = parseInt(checkInB[1]);

                    if (hourA < hourB) return -1;
                    if (hourA > hourB) return 1;
                    if (minuteA < minuteB) return -1;
                    if (minuteA > minuteB) return 1;

                    return 0; // Se tudo for igual, mantém a ordem original
                });

                console.log('reservationData: ', JSON.stringify(reservasData));

                if (reservasData.length > 0) {
                    const reservaUser = reservasData.shift(); // Remove e obtém a primeira reserva
                    console.log('reservaUser: ', JSON.stringify(reservaUser));
                    setReservasUser([reservaUser]);
                } else {
                    setReservasUser([]);
                }
            })
            .catch((error) => {
                console.error('Erro ao buscar as reservas:', error);
            });
    };

    const handleAddNovaHora = () => {
        setNewHora(true);
        setViewInfos2(true)
    };

    useEffect(() => {

        const plantaRef = ref(database, `Planta/-CoworkFundao`);
        onValue(plantaRef, (snapshot) => {
            const data = snapshot.val();
            setPlanta(data);
        });

    }, []);

    console.log("planta: ", JSON.stringify(planta));

    useEffect(() => {
        const plantaRef = ref(database, 'Planta/-CoworkFundao');
        onValue(plantaRef, (snapshot) => {
            const data = snapshot.val();
            const lugares = Object.keys(data).filter((lugar) => data[lugar] === true);
            console.log(lugares);
            setLugares(lugares);
        });
    }, []);


    console.log("lugares: " + lugares);

    useEffect(() => {

        console.log("inicio: " + inicio);
        console.log("fim: " + fim);
        // Criar uma referência para o caminho das reservas
        const reservasRef = ref(database, 'Reservas/');

        // Criar uma consulta para buscar todas as reservas
        const todasReservasQuery = query(reservasRef);

        // Obter todas as reservas
        get(todasReservasQuery)
            .then((snapshot) => {
                const reservasData = snapshot.val();

                // Filtrar as reservas com base nas condições
                const reservasFiltradas = Object.entries(reservasData)
                    .filter(([reservaKey, reservaData]) => {
                        return (
                            reservaData.data >= inicio &&
                            reservaData.data <= fim &&
                            lugares.includes(reservaData.lugar)
                        );
                    })
                    .reduce((reservas, [reservaKey, reservaData]) => {
                        reservas[reservaKey] = reservaData;
                        return reservas;
                    }, {});

                setReservas(reservasFiltradas);

                console.log('Reservas:', JSON.stringify(reservasFiltradas));
            })
            .catch((error) => {
                console.error('Erro ao buscar as reservas:', error);
            });

    }, [inicio, fim, lugares]);

    console.log('Reservas:', JSON.stringify(reservas));

    const handleOptionChange2 = (event) => {
        setShowReservas(false);
        setViewCheckOut(false);
        setViewButton(false)
        setCheckIn(null);
        setCheckOut(null);
        setAgendamento([]);
        setHorasMes([]);
        setDisponibilidade(null)
        setViewMes("");
        setViewCheckOut2(false);
        setViewButton2(false)
        setCheckIn2(null);
        setCheckOut2(null);
        setAgendamento2([]);
        setHorasMes2([]);
        setDisponibilidade2(null)
        setViewMes2("");
        setViewHorarioReservado(false);
        setViewHorarioReservado2(false);
        setSelectedOption(event.target.value);
    };

    const handleSelectCowork = (event) => {
        const selectedTitle = event.target.value;
        const cowork = coworks.find((item) => item.title === selectedTitle);
        if (cowork) {
            setSelectedCowork(cowork.coordenadas);
            setSelectedCoworkName(cowork.title)
        }
    };

    const openPlantaModal = (index) => {
        setPlantaModalOpen(true);
        setSelectedReservaIndex(index);
    };

    const openPlantaModal2 = (index) => {
        setPlantaModalOpen2(true);
        setSelectedReservaIndex2(index);
    };
    const [openDate, setOpenDate] = useState(false);
    const [date, setDate] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const handleLoading = (option) => {
        setLoading(option);
    }

    const DiarioDateChange = async (item) => {
        setShowReservas(false);
        setViewCheckOut(false);
        setViewButton(false)
        setCheckIn(null);
        setCheckOut(null);
        setAgendamento([]);
        setHorasMes([]);
        setDisponibilidade(null)
        setViewMes("");
        setViewCheckOut2(false);
        setViewButton2(false)
        setCheckIn2(null);
        setCheckOut2(null);
        setAgendamento2([]);
        setHorasMes2([]);
        setDisponibilidade2(null)
        setViewMes2("");
        setViewHorarioReservado(false);
        setViewHorarioReservado2(false);

        if (item.selection) {
            const {startDate} = item.selection;

            const endDate = new Date(startDate);
            endDate.setDate(startDate.getDate()); // Define a data de término igual à data inicial

            setDate([
                {
                    startDate: startDate,
                    endDate: endDate,
                    key: 'selection',
                },
            ]);

            const formattedDate1 = format(startDate, 'yyyy-MM-dd');
            const formattedDate2 = format(endDate, 'yyyy-MM-dd');
            setInicio(formattedDate1);
            setFim(formattedDate2);

            handleLoading(true);

            handleLoading(false);
            setShowReservas(true);
            setViewInfos(true)
        }
    }
    const SemanalDateChange = async (item) => {
        setShowReservas(false);
        setViewCheckOut(false);
        setViewButton(false)
        setCheckIn(null);
        setCheckOut(null);
        setAgendamento([]);
        setHorasMes([]);
        setDisponibilidade(null)
        setViewMes("");
        setViewCheckOut2(false);
        setViewButton2(false)
        setCheckIn2(null);
        setCheckOut2(null);
        setAgendamento2([]);
        setHorasMes2([]);
        setDisponibilidade2(null)
        setViewMes2("");
        setViewHorarioReservado(false);
        setViewHorarioReservado2(false);

        if (item.selection) {
            const {startDate} = item.selection;

            const endDate = new Date(startDate);
            endDate.setDate(startDate.getDate() + 6); // Adiciona 6 dias à data inicial

            setDate([
                {
                    startDate: startDate,
                    endDate: endDate,
                    key: 'selection'
                }
            ]);

            const formattedDate1 = format(startDate, 'yyyy-MM-dd');
            const formattedDate2 = format(endDate, 'yyyy-MM-dd');
            setInicio(formattedDate1);
            setFim(formattedDate2);

            handleLoading(true);

            handleLoading(false);
            setShowReservas(true);
            setViewInfos(true)
        }
    };
    const MensalDateChange = async (item) => {
        setShowReservas(false);
        setViewCheckOut(false);
        setViewButton(false)
        setCheckIn(null);
        setCheckOut(null);
        setAgendamento([]);
        setHorasMes([]);
        setDisponibilidade(null)
        setViewMes("");
        setViewCheckOut2(false);
        setViewButton2(false)
        setCheckIn2(null);
        setCheckOut2(null);
        setAgendamento2([]);
        setHorasMes2([]);
        setDisponibilidade2(null)
        setViewMes2("");
        setViewHorarioReservado(false);
        setViewHorarioReservado2(false);

        if (item.selection) {
            const {startDate} = item.selection;

            const endDate = new Date(startDate);
            endDate.setDate(startDate.getDate() + 29); // Adiciona 29 dias à data inicial

            setDate([
                {
                    startDate: startDate,
                    endDate: endDate,
                    key: 'selection'
                }
            ]);

            setViewMes("Mes")

            const formattedDate1 = format(startDate, 'yyyy-MM-dd');
            const formattedDate2 = format(endDate, 'yyyy-MM-dd');
            setInicio(formattedDate1);
            setFim(formattedDate2);

            handleLoading(true);

            handleLoading(false);
            setShowReservas(true);
            setViewInfos(true)
        }
    };

    const isTimeAvailable = () => {
        console.log("Reservas no isTimeAvailable: ", JSON.stringify(reservas));

        let timeIn = new Date(checkIn);
        let timeOut = new Date(checkOut);

        const formattedHourIn = timeIn.getHours().toString().padStart(2, '0') + ':' + timeIn.getMinutes().toString().padStart(2, '0');
        const formattedHourOut = timeOut.getHours().toString().padStart(2, '0') + ':' + timeOut.getMinutes().toString().padStart(2, '0');

        const lugaresIndisponiveis = [];

        const inicioAux = new Date(inicio);

        let dataInicio = new Date(inicio);
        let dataFim = new Date(fim);

        for (let i = dataInicio; i <= dataFim; i = addDays(i, 1)) {
            console.log("i: ", i);

            const formattedDate = format(inicioAux, 'yyyy-MM-dd');
            console.log("formattedDate: ", formattedDate);

            let hasAvailability = false;

            for (const lugar of lugares) {
                console.log("lugar: ", lugar);

                let isAvailable = true;

                for (const reservaKey in reservas) {
                    const reserva = reservas[reservaKey];
                    const reservaCheckIn = reserva.CheckIn;
                    const reservaCheckOut = reserva.CheckOut;
                    const reservaData = reserva.data;
                    const reservaLugar = reserva.lugar;

                    if (reservaData === formattedDate && reservaLugar === lugar) {
                        if (
                            (formattedHourIn >= reservaCheckIn && formattedHourIn <= reservaCheckOut) ||
                            (formattedHourOut >= reservaCheckIn && formattedHourOut <= reservaCheckOut) || (formattedHourIn <= reservaCheckIn && formattedHourOut >= reservaCheckOut)
                        ) {
                            isAvailable = false;
                            lugaresIndisponiveis.push(lugar);
                            break;
                        }
                    }
                }

                if (isAvailable) {
                    hasAvailability = true;
                    let reserva = {
                        userId: userId,
                        data: formattedDate,
                        lugar: lugar,
                        checkIn: formattedHourIn,
                        checkOut: formattedHourOut,
                    };
                    agendamento.push(reserva);
                    break;
                    //const numeroLugar = parseInt(lugar.match(/\d+/)[0]);
                    //setEscolha(numeroLugar);
                }
            }

            if (!hasAvailability) {
                notifica("warning", "Não há lugares disponíveis para o horário selecionado.", 2500);
                return setDisponibilidade(false); // Horário não está disponível
            }

            const novaData = new Date(inicioAux);
            novaData.setDate(novaData.getDate() + 1);

            if (novaData.getMonth() !== inicioAux.getMonth()) {
                // Avança para o próximo mês
                inicioAux.setMonth(inicioAux.getMonth() + 1);
            }

            inicioAux.setDate(novaData.getDate());

        }

        if (viewMes == "Mes") {
            setViewMes("Sim")
            let horas1 = {
                checkIn: formattedHourIn,
                checkOut: formattedHourOut,
            };
            horasMes.push(horas1);
        }

        return setDisponibilidade(true); // Horário está disponível
    };

    // Função para lidar com a seleção do horário de check-in
    const handleCheckInTimeChange = (newTime) => {
        setViewHorarioReservado(false);
        setCheckOut(null);
        console.log('Check-in: ', newTime);
        setDisponibilidade(null);
        setAgendamento([]);

        const horasOcupadas = [];

        const currentDate = new Date();
        const currentHour = getHours(currentDate);
        const currentMinute = getMinutes(currentDate);

        const selectedHour = getHours(newTime);
        const selectedMinute = getMinutes(newTime);

        if (inicio === format(currentDate, 'yyyy-MM-dd')) {
            if (selectedHour < currentHour) {
                const horaOcupada = {
                    checkIn: '00:00',
                    checkOut: `${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`,
                };

                horasOcupadas.push(horaOcupada);
            } else if (selectedHour === currentHour && selectedMinute < currentMinute) {
                const horaOcupada = {
                    checkIn: '00:00',
                    checkOut: `${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`,
                };

                horasOcupadas.push(horaOcupada);
            }
        }
        /*

        const inicioAux = new Date(inicio);

        let dataInicio = new Date(inicio);
        let dataFim = new Date(fim);

        for (let i = dataInicio; i <= dataFim; i = addDays(i, 1)) {
            const formattedDate = format(inicioAux, 'yyyy-MM-dd');

            for (const reservaKey in reservas) {
                const reserva = reservas[reservaKey];
                const reservaCheckIn = reserva.CheckIn;
                const reservaCheckOut = reserva.CheckOut;
                const reservaData = reserva.data;

                if (reservaData === formattedDate){
                    const horaOcupada = {
                        checkIn: reservaCheckIn,
                        checkOut: reservaCheckOut,
                    };

                    horasOcupadas.push(horaOcupada);
                }
            }
            const novaData = addDays(new Date(inicioAux), 1);
            inicioAux.setDate(novaData.getDate());
        }

         */

        // Verifica se a hora selecionada está em alguma reserva
        if (horasOcupadas.length === 0) {
            setCheckIn(newTime);
            setViewCheckOut(true);
            return;
        }
        const selectedTime = format(newTime, 'HH:mm');
        for (const horaOcupada of horasOcupadas) {
            const {checkIn, checkOut} = horaOcupada;
            if (selectedTime >= checkIn && selectedTime <= checkOut) {
                notifica("warning", "A hora selecionada já está reservada.", 2500);
                setViewHorarioReservado(true);
                setViewCheckOut(false)
                return;
            } else {
                setViewHorarioReservado(false);
                setCheckIn(newTime);

                setViewCheckOut(true);
            }
        }
    };

    // Função para lidar com a seleção do horário de check-out
    const handleCheckOutTimeChange = (newTime) => {
        setViewHorarioReservado(false);
        setAgendamento([]);
        if (newTime < checkIn) {
            setViewButton(false)
            notifica("warning", "O horário de check-out deve ser maior que o horário de check-in.", 3000);
        } else {
            setDisponibilidade(null);
            setViewInfos(false)
            setCheckOut(newTime);
            setViewButton(true);
        }

        /*
        else {
            const horasOcupadas = [];

            const inicioAux = new Date(inicio);

            let dataInicio = new Date(inicio);
            let dataFim = new Date(fim);

            for (let i = dataInicio; i <= dataFim; i = addDays(i, 1)) {
                const formattedDate = format(inicioAux, 'yyyy-MM-dd');

                for (const reservaKey in reservas) {
                    const reserva = reservas[reservaKey];
                    const reservaCheckIn = reserva.CheckIn;
                    const reservaCheckOut = reserva.CheckOut;
                    const reservaData = reserva.data;

                    if (reservaData === formattedDate){
                        const horaOcupada = {
                            checkIn: reservaCheckIn,
                            checkOut: reservaCheckOut,
                        };

                        horasOcupadas.push(horaOcupada);
                    }
                }
                const novaData = addDays(new Date(inicioAux), 1);
                inicioAux.setDate(novaData.getDate());
            }

            // Verifica se a hora selecionada está em alguma reserva
            const selectedTime = format(newTime, 'HH:mm');
            for (const horaOcupada of horasOcupadas) {
                const { checkIn, checkOut } = horaOcupada;
                if ((selectedTime >= checkIn && selectedTime <= checkOut) || (selectedTime <= checkIn && selectedTime >= checkOut)) {
                    alert('A hora slecionada já está reservada.');
                    setViewHorarioReservado(true);
                    return;
                } else{
                    setViewHorarioReservado(false);
                    setDisponibilidade(null);
                    setViewInfos(false)
                    setCheckOut(newTime);
                    setViewButton(true);
                }
            }
        }
        */
    };

    const handleDisponibilidade = () => {
        setViewButton(false)
        // Verificar se o horário selecionado está disponível
        const disponivel = isTimeAvailable();
        console.log('Check-out disponível:', disponivel);
        console.log('Agendamento: ', agendamento);
    };

    const isTimeAvailable2 = () => {
        console.log("Reservas no isTimeAvailable: ", JSON.stringify(reservas));

        let timeIn = new Date(checkIn2);
        let timeOut = new Date(checkOut2);

        const formattedHourIn = timeIn.getHours().toString().padStart(2, '0') + ':' + timeIn.getMinutes().toString().padStart(2, '0');
        const formattedHourOut = timeOut.getHours().toString().padStart(2, '0') + ':' + timeOut.getMinutes().toString().padStart(2, '0');

        const lugaresIndisponiveis = [];

        const inicioAux = new Date(inicio);

        let dataInicio = new Date(inicio);
        let dataFim = new Date(fim);

        for (let i = dataInicio; i <= dataFim; i = addDays(i, 1)) {
            console.log("i: ", i);

            const formattedDate = format(inicioAux, 'yyyy-MM-dd');
            console.log("formattedDate: ", formattedDate);

            let hasAvailability = false;

            let colisaoHoras = false;

            for (let i = 0; i < agendamento.length; i++) {

                let isAvailable = true;

                const reserva = agendamento[i];
                const reservaCheckIn = reserva.checkIn;
                const reservaCheckOut = reserva.checkOut;
                const reservaLugar = reserva.lugar;
                if (
                    (formattedHourIn >= reservaCheckIn && formattedHourIn <= reservaCheckOut) ||
                    (formattedHourOut >= reservaCheckIn && formattedHourOut <= reservaCheckOut) || (formattedHourIn <= reservaCheckIn && formattedHourOut >= reservaCheckOut)
                ) {
                    isAvailable = false;
                    colisaoHoras = true;
                    break;
                }

                for (const lugar of lugares) {

                    let isAvailable = true;

                    for (const reservaKey in reservas) {
                        const reserva = reservas[reservaKey];
                        const reservaCheckIn = reserva.CheckIn;
                        const reservaCheckOut = reserva.CheckOut;
                        const reservaData = reserva.data;
                        const reservaLugar = reserva.lugar;

                        if (reservaData === formattedDate && reservaLugar === lugar) {
                            if (
                                (formattedHourIn >= reservaCheckIn && formattedHourIn <= reservaCheckOut) ||
                                (formattedHourOut >= reservaCheckIn && formattedHourOut <= reservaCheckOut) || (formattedHourIn <= reservaCheckIn && formattedHourOut >= reservaCheckOut)
                            ) {
                                isAvailable = false;
                                lugaresIndisponiveis.push(lugar);
                                break;
                            }
                        }
                    }

                    if (isAvailable) {
                        hasAvailability = true;
                        let reserva = {
                            userId: userId,
                            data: formattedDate,
                            lugar: lugar,
                            checkIn: formattedHourIn,
                            checkOut: formattedHourOut,
                        };
                        agendamento2.push(reserva);
                        break;
                        //const numeroLugar = parseInt(lugar.match(/\d+/)[0]);
                        //setEscolha(numeroLugar);
                    }
                }
            }

            if (colisaoHoras) {
                notifica("warning", "O horário selecionado colide com as horas selecionadas anteriormente.", 3000);
                setViewHorarioReservado2(true);
                return setDisponibilidade2(false); // Horário não está disponível
            }

            if (!hasAvailability && !colisaoHoras) {
                notifica("warning", "Não há lugares disponíveis para o horário selecionado.", 3000);
                return setDisponibilidade2(false); // Horário não está disponível
            }

            const novaData = new Date(inicioAux);
            novaData.setDate(novaData.getDate() + 1);

            if (novaData.getMonth() !== inicioAux.getMonth()) {
                // Avança para o próximo mês
                inicioAux.setMonth(inicioAux.getMonth() + 1);
            }

            inicioAux.setDate(novaData.getDate());

        }

        if (viewMes2 == "Mes") {
            setViewMes2("Sim")
            let horas1 = {
                checkIn: formattedHourIn,
                checkOut: formattedHourOut,
            };
            horasMes2.push(horas1);
        }

        return setDisponibilidade2(true); // Horário está disponível
    };

    // Função para lidar com a seleção do horário de check-in
    const handleCheckInTimeChange2 = (newTime) => {
        setViewHorarioReservado2(false);
        setCheckOut2(null);
        setDisponibilidade2(null);
        setAgendamento2([]);

        const horasOcupadas = [];

        const currentDate = new Date();
        const currentHour = getHours(currentDate);
        const currentMinute = getMinutes(currentDate);

        const selectedHour = getHours(newTime);
        const selectedMinute = getMinutes(newTime);

        if (inicio === format(currentDate, 'yyyy-MM-dd')) {
            if (selectedHour < currentHour) {
                const horaOcupada = {
                    checkIn: '00:00',
                    checkOut: `${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`,
                };

                horasOcupadas.push(horaOcupada);
            } else if (selectedHour === currentHour && selectedMinute < currentMinute) {
                const horaOcupada = {
                    checkIn: '00:00',
                    checkOut: `${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`,
                };

                horasOcupadas.push(horaOcupada);
            }
        }

        for (let i = 0; i < agendamento.length; i++) {

            const reserva = agendamento[i];
            const reservaCheckIn = reserva.checkIn;
            const reservaCheckOut = reserva.checkOut;

            const horaOcupada = {
                checkIn: reservaCheckIn,
                checkOut: reservaCheckOut,
            };

            horasOcupadas.push(horaOcupada);
        }

        // Verifica se a hora selecionada está em alguma reserva
        if (horasOcupadas.length === 0) {
            setCheckIn2(newTime);
            setViewCheckOut2(true);
            return;
        }
        const selectedTime = format(newTime, 'HH:mm');
        for (const horaOcupada of horasOcupadas) {
            const {checkIn, checkOut} = horaOcupada;
            if (selectedTime >= checkIn && selectedTime <= checkOut) {
                notifica("warning", "A hora selecionada já está reservada.", 2500);
                setViewHorarioReservado2(true);
                setViewCheckOut2(false)
                return;
            } else {
                setViewHorarioReservado2(false);
                setCheckIn2(newTime);

                setViewCheckOut2(true);
            }
        }
    };

    // Função para lidar com a seleção do horário de check-out
    const handleCheckOutTimeChange2 = (newTime) => {
        setViewHorarioReservado2(false);
        setAgendamento2([]);
        if (newTime < checkIn2) {
            setViewButton2(false)
            notifica("warning", "O horário de check-out deve ser maior que o horário de check-in.", 3000);
        } else {
            setViewHorarioReservado2(false);
            setDisponibilidade2(null);
            setViewInfos2(false)
            setCheckOut2(newTime);
            setViewButton2(true);
        }
    };

    const handleDisponibilidade2 = () => {
        setViewButton2(false)
        // Verificar se o horário selecionado está disponível
        const disponivel = isTimeAvailable2();
    };

    const handleEscolhaChange = (option, index) => {
        const lugarName = option <= 9 ? `0${option}` : `${option}`;
        setAgendamento((prevState) => {
            const newState = [...prevState];
            newState[index].lugar = `lugar${lugarName}`;
            return newState;
        });
    };

    const handleEscolhaChange2 = (option, index) => {
        const lugarName = option <= 9 ? `0${option}` : `${option}`;
        setAgendamento2((prevState) => {
            const newState = [...prevState];
            newState[index].lugar = `lugar${lugarName}`;
            return newState;
        });
    };

    const handleReserva = () => {

        // adicionar conteudo do array `agendamento2` para o array `agendamento`
        for (let i = 0; i < agendamento2.length; i++) {
            agendamento.push(agendamento2[i]);
        }

        for (let i = 0; i < agendamento.length; i++) {

            const reservasRef = ref(database, `Reservas/`);
            // Gerar um novo ID único para a reserva
            const reservaId = push(reservasRef).key;

            // Atualizar o caminho da reserva com o ID único
            const reservaPath = `Reservas/${reservaId}`;

            // Atualizar a referência da reserva
            const reservaRef = ref(database, reservaPath);

            // Atualizar os dados da reserva com o ID único
            update(reservaRef, {
                userId: agendamento[i].userId,
                cowork: selectedCoworkName,
                data: agendamento[i].data,
                lugar: agendamento[i].lugar,
                CheckIn: agendamento[i].checkIn,
                CheckOut: agendamento[i].checkOut,
                entrada: "",
                saida: "",
                status: ""
            })
                .then(() => {
                    console.log("Reserva written with ID: ", reservaId);
                })
                .catch((error) => {
                    console.error("Error adding reserva: ", error);
                });
        }

        notifica("success", "Reserva realizada com sucesso!", 2500);

        setShowReservas(false);
        setViewCheckOut(false);
        setViewButton(false)
        setCheckIn(null);
        setCheckOut(null);
        setAgendamento([]);
        setHorasMes([]);
        setDisponibilidade(null)
        setViewMes("");
        setViewCheckOut2(false);
        setViewButton2(false)
        setCheckIn2(null);
        setCheckOut2(null);
        setAgendamento2([]);
        setHorasMes2([]);
        setDisponibilidade2(null)
        setViewMes2("");
        setViewMinhasReservas(true);

        setTimeout(() => {
            voltar();
        }, 3000);
    };

    const deleteNovaHora = () => {
        setNewHora(false);
        setViewCheckOut2(false);
        setViewButton2(false)
        setCheckIn2(null);
        setCheckOut2(null);
        setAgendamento2([]);
        setHorasMes2([]);
        setDisponibilidade2(null)
        setViewMes2("");
    };

    const shouldDisableTimeCheckIn = (value, view) => {

        /*
        const horasOcupadas = [];

        const inicioAux = new Date(inicio);

        let dataInicio = new Date(inicio);
        let dataFim = new Date(fim);

        for (let i = dataInicio; i <= dataFim; i = addDays(i, 1)) {
            const formattedDate = format(inicioAux, 'yyyy-MM-dd');

            for (const reservaKey in reservas) {
                const reserva = reservas[reservaKey];
                const reservaCheckIn = reserva.CheckIn;
                const reservaCheckOut = reserva.CheckOut;
                const reservaData = reserva.data;

                if (reservaData === formattedDate){
                    const horaOcupada = {
                        checkIn: reservaCheckIn,
                        checkOut: reservaCheckOut,
                    };

                    horasOcupadas.push(horaOcupada);
                }
            }
            const novaData = addDays(new Date(inicioAux), 1);
            inicioAux.setDate(novaData.getDate());
        }

         */

        const currentDate = new Date();
        const currentHour = getHours(currentDate);
        const currentMinute = getMinutes(currentDate);

        if (view === 'minutes') {
            const selectedHour = getHours(value);
            const selectedMinute = getMinutes(value);

            if (inicio === format(currentDate, 'yyyy-MM-dd')) {
                if (selectedHour < currentHour) {
                    return true; // Desabilita todas as horas anteriores à hora atual
                } else if (selectedHour === currentHour && selectedMinute < currentMinute) {
                    return true; // Desabilita os minutos anteriores à hora atual
                }
            }

            /*
            // Verifica se a hora selecionada está em alguma reserva
            const selectedTime = format(value, 'HH:mm');
            for (const horaOcupada of horasOcupadas) {
                const { checkIn, checkOut } = horaOcupada;
                if (selectedTime >= checkIn && selectedTime <= checkOut) {
                    return true; // Desabilita a hora selecionada se estiver reservada
                }
            }

             */
        }

        return false;
    };

    const shouldDisableTimeCheckOut = (value, view) => {

        /*
        const horasOcupadas = [];

        const inicioAux = new Date(inicio);

        let dataInicio = new Date(inicio);
        let dataFim = new Date(fim);

        for (let i = dataInicio; i <= dataFim; i = addDays(i, 1)) {
            const formattedDate = format(inicioAux, 'yyyy-MM-dd');

            for (const reservaKey in reservas) {
                const reserva = reservas[reservaKey];
                const reservaCheckIn = reserva.CheckIn;
                const reservaCheckOut = reserva.CheckOut;
                const reservaData = reserva.data;

                if (reservaData === formattedDate){
                    const horaOcupada = {
                        checkIn: reservaCheckIn,
                        checkOut: reservaCheckOut,
                    };

                    horasOcupadas.push(horaOcupada);
                }
            }
            const novaData = addDays(new Date(inicioAux), 1);
            inicioAux.setDate(novaData.getDate());
        }

         */

        if (view === 'minutes') {
            const currentHour = getHours(checkIn);
            const currentMinute = getMinutes(checkIn);

            const selectedHour = getHours(value);
            const selectedMinute = getMinutes(value);

            if (selectedHour < currentHour) {
                return true; // Desabilita todas as horas anteriores à hora atual
            } else if (selectedHour === currentHour && selectedMinute < currentMinute) {
                return true; // Desabilita os minutos anteriores à hora atual
            }
        }

        return false;
    };

    const shouldDisableTimeCheckIn2 = (value, view) => {

        const horasOcupadas = [];

        for (let i = 0; i < agendamento.length; i++) {

            const reserva = agendamento[i];
            const reservaCheckIn = reserva.checkIn;
            const reservaCheckOut = reserva.checkOut;

            const horaOcupada = {
                checkIn: reservaCheckIn,
                checkOut: reservaCheckOut,
            };

            horasOcupadas.push(horaOcupada);
        }

        const currentDate = new Date();
        const currentHour = getHours(currentDate);
        const currentMinute = getMinutes(currentDate);

        if (view === 'minutes') {
            const selectedHour = getHours(value);
            const selectedMinute = getMinutes(value);

            if (inicio === format(currentDate, 'yyyy-MM-dd')) {
                if (selectedHour < currentHour) {
                    return true; // Desabilita todas as horas anteriores à hora atual
                } else if (selectedHour === currentHour && selectedMinute < currentMinute) {
                    return true; // Desabilita os minutos anteriores à hora atual
                }
            }

            // Verifica se a hora selecionada está em alguma reserva
            const selectedTime = format(value, 'HH:mm');
            for (const horaOcupada of horasOcupadas) {
                const {checkIn, checkOut} = horaOcupada;
                if (selectedTime >= checkIn && selectedTime <= checkOut) {
                    return true; // Desabilita a hora selecionada se estiver reservada
                }
            }
        }

        return false;
    };

    const shouldDisableTimeCheckOut2 = (value, view) => {

        const currentHour = getHours(checkIn2);
        const currentMinute = getMinutes(checkIn2);

        if (view === 'minutes') {
            const selectedHour = getHours(value);
            const selectedMinute = getMinutes(value);

            if (selectedHour < currentHour) {
                return true; // Desabilita todas as horas anteriores à hora atual
            } else if (selectedHour === currentHour && selectedMinute < currentMinute) {
                return true; // Desabilita os minutos anteriores à hora atual
            }
        }

        return false;
    };

    function voltar() {
        window.location.href = "/";
    }

    const handleEntrarCowork = async () => {
        const valido = await verificarReservas();

        const entrada = new Date();
        const saida = new Date();

        const checkIn = reservasUser[0].CheckIn.split(':');
        const checkOut = reservasUser[0].CheckOut.split(':');

        entrada.setHours(checkIn[0], checkIn[1], 0, 0);
        saida.setHours(checkOut[0], checkOut[1], 0, 0);

        if (valido) {
            if (reservasUser[0].status === 'Em Andamento') {
                const reservasRef = ref(database, `Reservas/`);
                // Gerar um novo ID único para a reserva
                const reservaId = reservasUser[0].reservaKey;

                // Atualizar o caminho da reserva com o ID único
                const reservaPath = `Reservas/${reservaId}`;

                // Atualizar a referência da reserva
                const reservaRef = ref(database, reservaPath);

                const novosDadosReserva = {
                    // Atualize os campos da reserva conforme necessário
                    saida: format(currentDate, 'HH:mm'),
                    status: "Saiu",
                };

                update(reservaRef, novosDadosReserva)
                    .then(() => {
                        console.log('Reserva atualizada com sucesso!');
                    })
                    .catch((error) => {
                        console.error('Erro ao atualizar a reserva:', error);
                    });

                notifica("info", "Você saiu do coworking!", 3000);
                setTimeout(() => {
                    voltar();
                }, 4000);
            } else if (reservasUser[0].status === 'Saiu') {
                const reservasRef = ref(database, `Reservas/`);
                // Gerar um novo ID único para a reserva
                const reservaId = reservasUser[0].reservaKey;

                // Atualizar o caminho da reserva com o ID único
                const reservaPath = `Reservas/${reservaId}`;

                // Atualizar a referência da reserva
                const reservaRef = ref(database, reservaPath);

                const novosDadosReserva = {
                    // Atualize os campos da reserva conforme necessário
                    saida: format(currentDate, 'HH:mm'),
                    status: "Em Andamento",
                };

                update(reservaRef, novosDadosReserva)
                    .then(() => {
                        console.log('Reserva atualizada com sucesso!');
                    })
                    .catch((error) => {
                        console.error('Erro ao atualizar a reserva:', error);
                    });

                notifica("info", "Bem-Vindo de Volta!", 3000);
                setTimeout(() => {
                    voltar();
                }, 4000);
            } else {
                const reservasRef = ref(database, `Reservas/`);
                // Gerar um novo ID único para a reserva
                const reservaId = reservasUser[0].reservaKey;

                // Atualizar o caminho da reserva com o ID único
                const reservaPath = `Reservas/${reservaId}`;

                // Atualizar a referência da reserva
                const reservaRef = ref(database, reservaPath);

                const novosDadosReserva = {
                    // Atualize os campos da reserva conforme necessário
                    entrada: format(currentDate, 'HH:mm'),
                    saida: "",
                    status: "Em Andamento",
                };

                update(reservaRef, novosDadosReserva)
                    .then(() => {
                        console.log('Reserva atualizada com sucesso!');
                    })
                    .catch((error) => {
                        console.error('Erro ao atualizar a reserva:', error);
                    });

                notifica("success", "Você entrou no coworking!", 3000);
                setTimeout(() => {
                    voltar();
                }, 4000);
            }

        } else {
            if (reservasUser[0].status === 'Em Andamento') {
                const reservasRef = ref(database, `Reservas/`);
                // Gerar um novo ID único para a reserva
                const reservaId = reservasUser[0].reservaKey;

                // Atualizar o caminho da reserva com o ID único
                const reservaPath = `Reservas/${reservaId}`;

                // Atualizar a referência da reserva
                const reservaRef = ref(database, reservaPath);

                const novosDadosReserva = {
                    // Atualize os campos da reserva conforme necessário
                    saida: format(currentDate, 'HH:mm'),
                    status: "Finalizado",
                    aviso: "Saiu fora do Horário Agendado",
                };

                update(reservaRef, novosDadosReserva)
                    .then(() => {
                        console.log('Reserva atualizada com sucesso!');
                    })
                    .catch((error) => {
                        console.error('Erro ao atualizar a reserva:', error);
                    });

                notifica("info", "Você saiu do coworking!", 3000);
                setTimeout(() => {
                    voltar();
                }, 4000);
            } else if (reservasUser[0].status === 'Saiu') {
                const reservasRef = ref(database, `Reservas/`);
                // Gerar um novo ID único para a reserva
                const reservaId = reservasUser[0].reservaKey;

                // Atualizar o caminho da reserva com o ID único
                const reservaPath = `Reservas/${reservaId}`;

                // Atualizar a referência da reserva
                const reservaRef = ref(database, reservaPath);

                const novosDadosReserva = {
                    // Atualize os campos da reserva conforme necessário
                    status: "Finalizado",
                };

                update(reservaRef, novosDadosReserva)
                    .then(() => {
                        console.log('Reserva atualizada com sucesso!');
                    })
                    .catch((error) => {
                        console.error('Erro ao atualizar a reserva:', error);
                    });

                notifica("info", "Precisa de Atualizar a página!", 3000);
                setTimeout(() => {
                    voltar();
                }, 4000);
            } else if (reservasUser[0].entrada === '') {
                const reservasRef = ref(database, `Reservas/`);
                // Gerar um novo ID único para a reserva
                const reservaId = reservasUser[0].reservaKey;

                // Atualizar o caminho da reserva com o ID único
                const reservaPath = `Reservas/${reservaId}`;

                // Atualizar a referência da reserva
                const reservaRef = ref(database, reservaPath);

                const novosDadosReserva = {
                    // Atualize os campos da reserva conforme necessário
                    saida: format(currentDate, 'HH:mm'),
                    status: "Finalizado",
                    aviso: "Não compareceu",
                };

                update(reservaRef, novosDadosReserva)
                    .then(() => {
                        console.log('Reserva atualizada com sucesso!');
                    })
                    .catch((error) => {
                        console.error('Erro ao atualizar a reserva:', error);
                    });

                notifica("info", "Precisa de Atualizar a página!", 3000);
                setTimeout(() => {
                    voltar();
                }, 4000);
            } else {
                notifica("error", "Você não possui uma reserva para este Horário!", 3000);
            }
        }
    }

    const verificarReservas = () => {
        const reservasRef = ref(database, 'Reservas/');
        const todasReservasQuery = query(reservasRef);
        const currentDate = new Date();

        return get(todasReservasQuery)
            .then((snapshot) => {
                const reservasData = [];

                snapshot.forEach((reservaSnapshot) => {
                    const reservaKey = reservaSnapshot.key;
                    const reservaData = reservaSnapshot.val();
                    const reservaDate = new Date(reservaData.data);

                    if (
                        reservaData.userId === userId &&
                        (isSameDay(reservaDate, currentDate) || reservaDate > currentDate)
                    ) {
                        const reservaPath = `Reservas/${reservaKey}`;
                        reservasData.push({
                            reservaKey,
                            reservaPath,
                            ...reservaData,
                        });
                    }
                });

                for (let i = 0; i < reservasData.length; i++) {
                    const reserva = reservasData[i];

                    const entrada = new Date();
                    const saida = new Date();

                    const checkIn = reservasUser[0].CheckIn.split(':');
                    const checkOut = reservasUser[0].CheckOut.split(':');

                    entrada.setHours(checkIn[0], checkIn[1], 0, 0);
                    saida.setHours(checkOut[0], checkOut[1], 0, 0);

                    console.log("saida->>>>>>>", saida);
                    console.log("Reserva Utilizador->>>>>>> ", reservasUser[0]);

                    if (
                        reserva.reservaKey === reservasUser[0].reservaKey &&
                        saida >= currentDate &&
                        entrada <= currentDate
                    ) {

                        return true;
                    }
                }

                return false; // Retorna false se nenhuma reserva corresponder às condições
            })
            .catch((error) => {
                console.error('Erro ao buscar as reservas:', error);
                return false; // Retorna false em caso de erro
            });
    };

    const defaultValue = setMinutes(new Date(), 50);

    const notifica2 = (notificacao, texto, tempo) => {
        if (notificacao === "success") {
            toast.success(texto, {
                position: "top-center",
                autoClose: tempo,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else if (notificacao === "info") {
            toast.info(texto, {
                position: "top-center",
                autoClose: tempo,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else if (notificacao === "warning") {
            toast.warning(texto, {
                position: "top-center",
                autoClose: tempo,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else if (notificacao === "error") {
            toast.error(texto, {
                position: "top-center",
                autoClose: tempo,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };

    return (
        <div id="reservar">

            <ToastContainer
                position="top-center"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />

            <Modal
                isOpen={plantaModalOpen}
                onRequestClose={() => setPlantaModalOpen(false)}
                contentLabel="Sucess Modal"
                style={{
                    overlay: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 10, // Defina um valor maior para a propriedade zIndex
                    },
                    content: {
                        position: 'relative',
                        top: 'auto',
                        left: 'auto',
                        right: 'auto',
                        bottom: 'auto',
                        maxWidth: '90%',
                        maxHeight: '90%',
                        padding: '20px',
                        border: 'none',
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        outline: 'none',
                        zIndex: 11, // Defina um valor maior para a propriedade zIndex
                    }
                }}
            >
                <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
                    <Image style={{width: 24, height: 24, alignSelf: "flex-end", margin: 10}} source={reservation}/>
                    {selectedReservaIndex !== null && (
                        <div className="bg-planta">
                            <PlantaReservas
                                handleEscolhaChange={handleEscolhaChange}
                                notifica2={notifica2}
                                lugar={agendamento[selectedReservaIndex]?.lugar} // Verifica se lugar existe antes de acessá-lo
                                checkIn={checkIn}
                                checkOut={checkOut}
                                inicio={agendamento[selectedReservaIndex]?.data} // Verifica se data existe antes de acessá-la
                                reservas={reservas}
                                lugares={lugares}
                                indexAgendamento={selectedReservaIndex}
                            />
                        </div>
                    )}

                    <button
                        className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300 ml-2 mt-3"
                        onClick={() => setPlantaModalOpen(false)}
                    >
                        Fechar
                    </button>
                </View>
            </Modal>

            <Modal
                isOpen={plantaModalOpen2}
                onRequestClose={() => setPlantaModalOpen2(false)}
                contentLabel="Sucess Modal"
                style={{
                    overlay: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 10, // Defina um valor maior para a propriedade zIndex
                    },
                    content: {
                        position: 'relative',
                        top: 'auto',
                        left: 'auto',
                        right: 'auto',
                        bottom: 'auto',
                        maxWidth: '90%',
                        maxHeight: '90%',
                        padding: '20px',
                        border: 'none',
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        outline: 'none',
                        zIndex: 11, // Defina um valor maior para a propriedade zIndex
                    }
                }}
            >
                <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
                    <Image style={{width: 24, height: 24, alignSelf: "flex-end", margin: 10}} source={reservation}/>
                    {selectedReservaIndex2 !== null && (
                        <div className="bg-planta">
                            <PlantaNovaHora
                                handleEscolhaChange2={handleEscolhaChange2}
                                notifica2={notifica2}
                                lugar={agendamento2[selectedReservaIndex2]?.lugar} // Verifica se lugar existe antes de acessá-lo
                                checkIn={checkIn}
                                checkOut={checkOut}
                                inicio={agendamento2[selectedReservaIndex2]?.data} // Verifica se data existe antes de acessá-la
                                reservas={reservas}
                                lugares={lugares}
                                indexAgendamento={selectedReservaIndex2}
                            />
                        </div>
                    )}

                    <button
                        className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300 ml-2 mt-3"
                        onClick={() => setPlantaModalOpen2(false)}
                    >
                        Fechar
                    </button>
                </View>
            </Modal>

            {aceite === true && tipo === "Fixo" && (
                <>
                    <div className="text-center mt-12">
                        <h1 className="text-7xl font-bold text-black mb-4 saira-condensed-bold">
                            Olá! Preparado para mais um dia de crescimento?
                        </h1>
                        <p className={`${styles.texto} text-black mb-5`}>
                            Tu és do tamanho dos teus sonhos e do teu empenho. Vamos lá!
                        </p>
                        <button className="btnCard fillCard2 mb-8" onClick={handleEntrarCowork}>ENTRADA DO COWORK
                        </button>
                        <div className={`flex-1 flex ${styles.flexCenter} md:my-0 my-0 relative ml-4 mr-4`}>
                            <MapContainer selectedCowork={selectedCowork}/>
                        </div>
                    </div>
                </>
            )}

            {((aceite === false && tipo === "Fixo") || (tipo === "Temporario")) && (
                <>
                    {reservasUser.length !== 0 && existeReserva === dataHoje && (
                        <>
                            <div className="text-center mt-12">
                                <h1 className="text-7xl font-bold text-black mb-4 saira-condensed-bold">
                                    Olá! Preparado para mais um dia de crescimento?
                                </h1>
                                <p className={`${styles.texto} text-black mb-5`}>
                                    Tu és do tamanho dos teus sonhos e do teu empenho. Vamos lá!
                                </p>
                                <button className="btnCard fillCard2" onClick={handleEntrarCowork}>ENTRADA DO COWORK
                                </button>
                            </div>
                        </>
                    )}
                    <div className="text-center mt-8">
                        <h1 className="text-7xl font-bold text-black mb-4 saira-condensed-bold">
                            Reserva na Rede de Coworking do Fundão
                        </h1>
                        <p className={`${styles.texto} text-black mb-8`}>
                            Desperta o teu potencial e eleva a tua produtividade a um novo patamar.
                        </p>
                    </div>
                    <section id="app" className={`${layout.sectionReverse}`}>
                        <div className={`flex-1 flex ${styles.flexCenter} md:my-0 my-0 relative ml-4 mr-4`}>
                            <MapContainer selectedCowork={selectedCowork}/>
                        </div>

                        <div className={`${layout.sectionInfo} items-center bg-verdeTropa rounded-3xl`}>
                            <Divisorio styles={"px-20 mt-2"}/>

                            <select
                                className={`${styles.menu} w-auto p-1.5 rounded-xl border select`}
                                name="coworks"
                                id="coworks"
                                onChange={handleSelectCowork}
                            >
                                <SelectCowork onSelectCowork={handleSelectCowork}/>
                            </select>

                            <div className="text-center mt-3">
                                <input
                                    className="input-3"
                                    type="radio"
                                    id="diario"
                                    name="options"
                                    value="diario"
                                    checked={selectedOption === 'diario'}
                                    onChange={handleOptionChange2}
                                />
                                <label htmlFor="diario" className={`label-3 ${styles.menu} mr-1`}>
                                    Diário
                                </label>
                                <input
                                    className="input-3"
                                    type="radio"
                                    id="semanal"
                                    name="options"
                                    value="semanal"
                                    checked={selectedOption === 'semanal'}
                                    onChange={handleOptionChange2}
                                />
                                <label htmlFor="semanal" className={`label-3 ${styles.menu}`}>
                                    Semanal
                                </label>
                                <input
                                    className="input-3"
                                    type="radio"
                                    id="mensal"
                                    name="options"
                                    value="mensal"
                                    checked={selectedOption === 'mensal'}
                                    onChange={handleOptionChange2}
                                />
                                <label htmlFor="mensal" className={`label-3 ${styles.menu} ml-1`}>
                                    Mensal
                                </label>
                            </div>

                            <div>
                                <div className="text-center mb-1">

                                    {selectedOption === "diario" && (
                                        <>
                                            <div
                                                className={`${layout.sectionInfo} ${styles.menu} items-center rounded-3xl`}>
                                                <button onClick={() => setOpenDate(!openDate)}
                                                        className="mt-2 flex rounded-xl">
                                                    <div
                                                        className={`${styles.flexCenter} w-[160px] h-[50px] bg-verdeTropa p-[4px]`}>
                                                        <div
                                                            className={`${styles.flexCenter} flex-col bg-white button-burlyWood w-[100%] h-[100%] rounded-xl`}>
                                                            <div className={`${styles.flexCenter} flex-row `}>
                                                                <FontAwesomeIcon icon={faCalendarDays}
                                                                                 className="headerIcon mr-4 verdeTropa w-[23px] h-[23px] object-contain ml-2 "/>
                                                                <p className={`${styles.menu} leading-[23.4px]`}>
                <span
                    className={`${styles.menu} headerSearchText`}
                >{`${format(date[0].startDate, 'dd/MM/yyyy')}`}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </div>

                                            <div
                                                className={`${layout.sectionInfo} ${styles.menu} items-center rounded-3xl`}>
                                                {openDate && (
                                                    <DateRange
                                                        editableDateInputs={true}
                                                        onChange={DiarioDateChange}
                                                        moveRangeOnFirstSelection={false}
                                                        ranges={date}
                                                        className="date mt-1"
                                                        minDate={new Date()}
                                                    />
                                                )}
                                            </div>
                                        </>
                                    )}

                                    {selectedOption === "semanal" && (
                                        <>
                                            <div
                                                className={`${layout.sectionInfo} ${styles.menu} items-center rounded-3xl`}>
                                                <button onClick={() => setOpenDate(!openDate)}
                                                        className="mt-2 flex rounded-xl">
                                                    <div
                                                        className={`${styles.flexCenter} w-[250px] h-[50px] bg-verdeTropa p-[4px]`}>
                                                        <div
                                                            className={`${styles.flexCenter} flex-col bg-white button-burlyWood w-[100%] h-[100%] rounded-xl`}>
                                                            <div className={`${styles.flexCenter} flex-row `}>
                                                                <FontAwesomeIcon icon={faCalendarDays}
                                                                                 className="headerIcon mr-4 verdeTropa w-[23px] h-[23px] object-contain ml-2 "/>
                                                                <p className={`${styles.menu} leading-[23.4px]`}>
                <span
                    className={`${styles.menu} headerSearchText`}
                >{`${format(date[0].startDate, 'dd/MM/yyyy')} a ${format(date[0].endDate, 'dd/MM/yyyy')}`}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </div>

                                            <div
                                                className={`${layout.sectionInfo} ${styles.menu} items-center rounded-3xl`}>
                                                {openDate && (
                                                    <DateRange
                                                        editableDateInputs={true}
                                                        onChange={SemanalDateChange}
                                                        moveRangeOnFirstSelection={false}
                                                        ranges={date}
                                                        className="date mt-1"
                                                        minDate={new Date()}
                                                    />
                                                )}
                                            </div>
                                        </>
                                    )}

                                    {selectedOption === "mensal" && (
                                        <>
                                            <div
                                                className={`${layout.sectionInfo} ${styles.menu} items-center rounded-3xl`}>
                                                <button onClick={() => setOpenDate(!openDate)}
                                                        className="mt-2 flex rounded-xl">
                                                    <div
                                                        className={`${styles.flexCenter} w-[250px] h-[50px] bg-verdeTropa p-[4px]`}>
                                                        <div
                                                            className={`${styles.flexCenter} flex-col bg-white button-burlyWood w-[100%] h-[100%] rounded-xl`}>
                                                            <div className={`${styles.flexCenter} flex-row`}>
                                                                <FontAwesomeIcon icon={faCalendarDays}
                                                                                 className="headerIcon mr-4 verdeTropa w-[23px] h-[23px] object-contain ml-2 "/>
                                                                <p className={`${styles.menu} leading-[23.4px]`}>
                <span
                    className={`${styles.menu} headerSearchText`}
                >{`${format(date[0].startDate, 'dd/MM/yyyy')} a ${format(date[0].endDate, 'dd/MM/yyyy')}`}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </div>

                                            <div
                                                className={`${layout.sectionInfo} ${styles.menu} items-center rounded-3xl`}>
                                                {openDate && (
                                                    <DateRange
                                                        editableDateInputs={true}
                                                        onChange={MensalDateChange}
                                                        moveRangeOnFirstSelection={false}
                                                        ranges={date}
                                                        className="date mt-1 custom-calendar"
                                                        minDate={new Date()}
                                                    />
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>

                            {loading === true && (
                                <>
                                    <div className="lds-spinner mt-1">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <p
                                        className={`${styles.menu} mt-2 text-white text-[13px] leading-[20.4px] mb-4`}
                                    >
                                        Por favor, aguarda enquanto analisamos a disponibilidade.
                                    </p>
                                </>
                            )}


                            {(showReservas !== false) && (loading === false) && (
                                <>
                                    <div className="">
                                        <p
                                            className={`${styles.menu} mt-1 text-white text-[13px] leading-[20.4px] ml-12 mr-12`}
                                        >
                                            Preenche com as horas previstadas que prentedes entrar e sair, poderás
                                            alterar
                                            mais
                                            tarde com base na disponibilidade.
                                        </p>
                                    </div>
                                    <section className="flex md:flex-row flex-col mt-3">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <div className={`${styles.menu} mr-2 ml-2`}>
                                                <label htmlFor="checkInTime"
                                                       className="block text-white font-medium mb-1">
                                                    Check-In
                                                </label>
                                                <div>
                                                    <MobileTimePicker
                                                        MaxTime={checkOut}
                                                        className="bg-white"
                                                        value={checkIn}
                                                        onChange={handleCheckInTimeChange}
                                                        shouldDisableTime={shouldDisableTimeCheckIn}
                                                    />
                                                </div>
                                            </div>
                                            {(viewCheckOut === true) && (
                                                <>
                                                    <div className={`${styles.menu} mr-2 ml-2`}>
                                                        <label htmlFor="checkOutTime"
                                                               className="block text-white font-medium mb-1">
                                                            Check-Out
                                                        </label>
                                                        <div>
                                                            <MobileTimePicker
                                                                className="bg-white"
                                                                value={checkOut}
                                                                onChange={handleCheckOutTimeChange}
                                                                shouldDisableTime={shouldDisableTimeCheckOut}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </LocalizationProvider>
                                    </section>
                                    {(viewHorarioReservado === true) && (
                                        <>
                                            <p
                                                className={`${styles.menu} text-red-500 text-[18px] leading-[20.4px] mt-2 mb-3 ml-12 mr-12`}
                                            >
                                                A hora selecionada já está reservada.
                                            </p>
                                        </>
                                    )}

                                    {(viewButton === true) && (viewCheckOut === true) && (
                                        <>
                                            <div className={`${styles.menu} cursor-pointer mt-4 mb-2`}>
                                                <a className="verdeTropa bg-softGrey text-white saira-condensed-bold text-[18px] uppercase rounded-[10px] button-burlyWood mb-6 py-1.5 px-2 w-full rounded-xl hover:scale-105 duration-300"
                                                   onClick={handleDisponibilidade}>Verificar Disponibilidade</a>
                                            </div>
                                        </>
                                    )}
                                    {(viewInfos === true) && (
                                        <>
                                            <li className="text-white mt-1"><em><strong>AM* (ante meridiem)
                                                -</strong> Antes
                                                do
                                                Meio-Dia;</em></li>
                                            <li className="text-white"><em><strong>PM* (post meridiem) -</strong> Após o
                                                Meio-Dia.</em></li>
                                            <p
                                                className={`${styles.menu} text-white text-[18px] leading-[20.4px] mt-4`}
                                            >
                                                O horário de Check-Out deve ser maior que o horário de Check-In.
                                            </p>
                                        </>
                                    )}

                                    {(disponibilidade === false) && (
                                        <>
                                            <p
                                                className={`${styles.menu} text-red-500 text-[18px] leading-[20.4px] mt-7 ml-12 mr-12`}
                                            >
                                                Não existe disponibilidade para as horas selecionadas.
                                            </p>
                                        </>
                                    )}

                                    {(viewMes === "Sim") && (
                                        <>
                                            <p
                                                className={`${styles.menu} text-green-500 text-[18px] leading-[20.4px] mt-7 ml-12 mr-12`}
                                            >
                                                É possível efetuar a reserva! Confirma a Reserva e poderás alterar as
                                                suas
                                                informações na tua Área Pessoal.
                                            </p>

                                            {horasMes.map((hora, index2) => (
                                                <div key={index2} className="text-center">
                                                    <p className={`${styles.menu} text-green-500 text-[29px] leading-[20.4px] mt-3`}>
                                                        Check-In: {hora.checkIn}
                                                    </p>
                                                    <p className={`${styles.menu} text-green-500 text-[29px] leading-[20.4px] mt-2`}>
                                                        Check-Out: {hora.checkOut}
                                                    </p>
                                                </div>
                                            ))}
                                        </>
                                    )}

                                    {(showReservas !== false) && (loading === false) && (disponibilidade === true) && (
                                        <>
                                            {(showReservas !== false) && (loading === false) && (disponibilidade === true) && (viewMes === "") && (
                                                <>
                                                    {agendamento.map((reserva, index) => (
                                                        <div key={index} className="text-center">
                                                            <p className={`${styles.menu} text-green-500 text-[29px] leading-[20.4px] mt-3`}>
                                                                {format(new Date(reserva.data), 'dd/MM/yyyy')}, {reserva.lugar}
                                                            </p>
                                                            <p
                                                                className={`${styles.menu} mt-2 text-white text-[13px] leading-[20.4px] cursor-pointer hover:text-amber-200 underline`}
                                                                onClick={() => openPlantaModal(index)} // Passar o índice da reserva como argumento
                                                            >
                                                                Alterar Lugar
                                                            </p>
                                                        </div>
                                                    ))}
                                                </>
                                            )}

                                            {newHora ? (
                                                <>
                                                    <section className="flex md:flex-row flex-col mt-3">
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <div className={`${styles.menu} mr-2 ml-2`}>
                                                                <label htmlFor="checkInTime"
                                                                       className="block text-white font-medium mb-1">
                                                                    Check-In
                                                                </label>
                                                                <div>
                                                                    <MobileTimePicker
                                                                        MaxTime={checkOut2}
                                                                        className="bg-white"
                                                                        value={checkIn2}
                                                                        onChange={handleCheckInTimeChange2}
                                                                        shouldDisableTime={shouldDisableTimeCheckIn2}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {viewCheckOut2 === true && (
                                                                <>
                                                                    <div className={`${styles.menu} mr-2 ml-2`}>
                                                                        <label htmlFor="checkOutTime"
                                                                               className="block text-white font-medium mb-1">
                                                                            Check-Out
                                                                        </label>
                                                                        <div>
                                                                            <MobileTimePicker
                                                                                className="bg-white"
                                                                                value={checkOut2}
                                                                                onChange={handleCheckOutTimeChange2}
                                                                                shouldDisableTime={shouldDisableTimeCheckOut2}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </LocalizationProvider>
                                                    </section>
                                                    {(viewHorarioReservado2 === true) && (
                                                        <>
                                                            <p
                                                                className={`${styles.menu} text-red-500 text-[18px] leading-[20.4px] mt-2 mb-3 ml-12 mr-12`}
                                                            >
                                                                O horário selecionado colide com as horas selecionadas
                                                                anteriormente.
                                                            </p>
                                                        </>
                                                    )}
                                                    {(viewButton2 === true) && (viewCheckOut2 === true) && (
                                                        <>
                                                            <div className={`${styles.menu} cursor-pointer mt-4 mb-2`}>
                                                                <a className="verdeTropa bg-softGrey text-white saira-condensed-bold text-[18px] uppercase rounded-[10px] button-burlyWood mb-6 py-1.5 px-2 w-full rounded-xl hover:scale-105 duration-300"
                                                                   onClick={handleDisponibilidade2}>Verificar
                                                                    Disponibilidade</a>
                                                            </div>
                                                        </>
                                                    )}
                                                    {(viewInfos2 === true) && (
                                                        <>
                                                            <li className="text-white mt-1"><em><strong>AM* (ante
                                                                meridiem)
                                                                -</strong> Antes do
                                                                Meio-Dia;</em></li>
                                                            <li className="text-white"><em><strong>PM* (post meridiem)
                                                                -</strong> Após o
                                                                Meio-Dia.</em></li>
                                                            <p
                                                                className={`${styles.menu} text-white text-[18px] leading-[20.4px] mt-4 mb-2`}
                                                            >
                                                                O horário de Check-Out deve ser maior que o horário de
                                                                Check-In.
                                                            </p>
                                                        </>
                                                    )}

                                                    {(disponibilidade2 === false) && (
                                                        <>
                                                            <p
                                                                className={`${styles.menu} text-red-500 text-[18px] leading-[20.4px] mt-7 ml-12 mr-12`}
                                                            >
                                                                Não existe disponibilidade para as horas selecionadas.
                                                            </p>
                                                        </>
                                                    )}

                                                    {(viewMes2 === "Sim") && (
                                                        <>
                                                            <p
                                                                className={`${styles.menu} text-green-500 text-[18px] leading-[20.4px] mt-7 ml-12 mr-12`}
                                                            >
                                                                É possível efetuar a reserva! Confirma a Reserva e
                                                                poderás
                                                                alterar as suas
                                                                informações na tua Área Pessoal.
                                                            </p>

                                                            {horasMes2.map((hora2, index3) => (
                                                                <div key={index3} className="text-center">
                                                                    <p className={`${styles.menu} text-green-500 text-[29px] leading-[20.4px] mt-3`}>
                                                                        Check-In: {hora2.checkIn}
                                                                    </p>
                                                                    <p className={`${styles.menu} text-green-500 text-[29px] leading-[20.4px] mt-2`}>
                                                                        Check-Out: {hora2.checkOut}
                                                                    </p>
                                                                </div>
                                                            ))}
                                                        </>
                                                    )}

                                                    {(showReservas !== false) && (loading === false) && (disponibilidade2 === true) && (viewMes2 === "") && (
                                                        <>
                                                            {agendamento2.map((reserva2, index4) => (
                                                                <div key={index4} className="text-center">
                                                                    <p className={`${styles.menu} text-green-500 text-[29px] leading-[20.4px] mt-3`}>
                                                                        {format(new Date(reserva2.data), 'dd/MM/yyyy')}, {reserva2.lugar}
                                                                    </p>
                                                                    <p
                                                                        className={`${styles.menu} mt-2 text-white text-[13px] leading-[20.4px] cursor-pointer hover:text-amber-200 underline`}
                                                                        onClick={() => openPlantaModal2(index4)} // Passar o índice da reserva como argumento
                                                                    >
                                                                        Alterar Lugar
                                                                    </p>
                                                                </div>
                                                            ))}
                                                        </>
                                                    )}
                                                    <div className={`${styles.menu} cursor-pointer mt-4 mb-4`}>
                                                        <a className="text-red-600 bg-white saira-condensed-bold text-[18px] uppercase button-white py-1.5 w-full rounded-xl hover:scale-105 duration-300"
                                                           onClick={deleteNovaHora}>Eliminar Nova Hora</a>
                                                    </div>
                                                    {(disponibilidade2 === true) && (
                                                        <>
                                                            <div className={`${styles.menu} cursor-pointer mt-5 mb-4`}>
                                                                <a className="verdeTropa bg-softGrey text-white saira-condensed-bold text-[18px] uppercase rounded-[10px] button-burlyWood mb-6 py-1.5 px-2 w-full rounded-xl hover:scale-105 duration-300"
                                                                   onClick={handleReserva}>Confirmar Reserva</a>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <div className="mt-3">
                                                        <p
                                                            className={`${styles.menu} mt-1 text-white text-[13px] leading-[20.4px] mb-1 ml-12 mr-12`}
                                                            onClick=""
                                                        >
                                                            Se pretenderes fazer uma pausa e voltar ao nosso espaço mais
                                                            tarde,
                                                            clica em "Adicionar Nova Hora".
                                                        </p>
                                                    </div>
                                                    <div className="text-center">
                                                        <a
                                                            className={`${styles.menu} text-white text-[18px] leading-[43.4px] underline cursor-pointer hover:text-amber-200`}
                                                            onClick={handleAddNovaHora}
                                                        >
                                                            Adicionar Nova Hora
                                                        </a>
                                                    </div>
                                                    <div className={`${styles.menu} cursor-pointer mt-2 mb-4`}>
                                                        <a className="verdeTropa bg-softGrey text-white saira-condensed-bold text-[18px] uppercase rounded-[10px] button-burlyWood mb-6 py-1.5 px-2 w-full rounded-xl hover:scale-105 duration-300"
                                                           onClick={handleReserva}>Confirmar Reserva</a>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                            {(viewMinhasReservas === true) && (
                                <>
                                    <div className={`${styles.menu} cursor-pointer mt-9 mb-4`}>
                                        <a className="btnCard fillCard2"
                                           onClick={() => handleOptionChange("AreaPessoal")}>Ver Minhas Reservas</a>
                                    </div>
                                </>
                            )}
                        </div>
                    </section>
                </>
            )}

        </div>
    );
};

export default Reservar;

