import React from "react";


const ScrollTopButton = () => {

    function backToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    return (
        <button style={{
            position: "fixed",
            bottom: "20px",
            right: "30px",
            zIndex: "99",
            border: "none",
            outline: "none",
            backgroundColor: "#ECCB7B",
            color: "white",
            cursor: "pointer",
            padding: "15px",
            borderRadius: "10px",
            fontSize: "18px"
        }} onClick={backToTop} title="Go to top" className="scroll-top">
            <i className="fa fa-chevron-up" aria-hidden="true"></i></button>
    );
};

export default ScrollTopButton;
