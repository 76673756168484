import {Box, CircularProgress, Fab} from '@mui/material';
import React, {useState} from 'react';
import {Check} from '@mui/icons-material';
import {green} from '@mui/material/colors';
import {database} from "../firebase";
import {ref, remove} from "firebase/database";
import {deletes} from "../images";

const DeleteVisita = ({params, rowId, setRowId}) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const apagarVisita = async (agendamentoPath) => {
        remove(ref(database, `${agendamentoPath}`)).then(() => {
            setTimeout(() => {
                setLoading(false);
                setSuccess(true);
                setRowId(null);
            }, 1000);
            setTimeout(() => {
                setSuccess(false);
            }, 2200);
            console.log("Data removing successfully!");
            return true
        }).catch((error) => {
            console.error("Error removing data: ", error);
            return false;
        });
    };

    const handleSubmit = async () => {
        setLoading(true);

        const {
            agendamentoPath,
        } = params.row;
        await apagarVisita(agendamentoPath);
    };

    return (
        <Box
            sx={{
                m: 1,
                position: 'relative',
            }}
        >
            {success ? (
                <Fab
                    color="primary"
                    sx={{
                        width: 40,
                        height: 40,
                        bgcolor: green[500],
                        '&:hover': {bgcolor: green[700]},
                    }}
                >
                    <Check/>
                </Fab>
            ) : (
                <Fab
                    color="#E8E7E8"
                    sx={{
                        width: 40,
                        height: 40,
                    }}
                    disabled={loading}
                    onClick={handleSubmit}
                >
                    <img
                        src={deletes}
                    />
                </Fab>
            )}
            {loading && (
                <CircularProgress
                    size={52}
                    sx={{
                        color: green[500],
                        position: 'absolute',
                        top: -6,
                        left: -6,
                        zIndex: 1,
                    }}
                />
            )}
        </Box>
    );
};

export default DeleteVisita;