const styles = {
    boxWidth: "xl:max-w-[1280px] w-full",

    sucess: "flex-1 saira-condensed-bold sucess-gradient ss:text-[56.8px] text-[52px] text-white",
    erro: "flex-1 saira-condensed-bold error-gradient ss:text-[56.8px] text-[52px] text-white",
    h1: "saira-condensed-bold text-[60px] text-[50px] uppercase text-black xs:leading-[56.8px] leading-[66.8px] w-full",
    h2: "saira-condensed-bold text-[50px] text-[40px] uppercase text-black xs:leading-[56.8px] leading-[66.8px] w-full",
    h3: "saira-condensed-bold text-[28px] text-[28px] uppercase text-black xs:leading-[26.8px] leading-[66.8px] w-full",
    menu: "saira-condensed-bold text-[18px] uppercase",
    texto:"inter-regular text-[18px] leading-[30.8px] w-full mt-3",
    paragrafo: "font-poppins font-normal text-black text-[18px] leading-[23.8px]",

    flexCenter: "flex justify-center items-center",
    flexStart: "flex justify-center items-start",
    flexEnd: "flex justify-center items-end",

    paddingX: "sm:px-28 px-6",
    paddingY: "sm:py-8 py-6",
    padding: "sm:px-16 px-6 sm:py-12 py-4",

    marginX: "sm:mx-16 mx-6",
    marginY: "sm:my-16 my-6",
};

export const layout = {
    section: `flex md:flex-row flex-col ${styles.paddingY}`,
    sectionReverse: `flex md:flex-row flex-col-reverse ${styles.paddingY}`,

    sectionImgReverse: `flex-1 flex ${styles.flexCenter} md:mr-10 mr-0 md:mt-0 mt-10 relative`,
    sectionImg: `flex-1 flex ${styles.flexCenter} md:ml-10 ml-0 md:mt-0 mt-10 relative`,

    sectionInfo: `flex-1 ${styles.flexStart} flex-col`,
    sectionInfo2: `${styles.flexEnd} flex-col`,
};


export default styles;
