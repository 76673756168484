import React, {useEffect, useMemo, useState} from 'react';

import Header from './Header';
import {get, query, ref} from "firebase/database";
import {database} from "../firebase";
import {format, isSameDay} from "date-fns";
import {Box, Typography} from "@mui/material";
import styles from "../style";
import {DataGrid, gridClasses} from "@mui/x-data-grid";
import {grey} from "@mui/material/colors";
import AlterarDataVisita from "./AlterarDataVisita";
import DeleteVisita from "./DeleteVisita";

const Agendamentos = () => {
    const [agendamento, setAgendamento] = useState("")
    const [agendamentos, setAgendamentos] = useState([])
    const [agendamentosPassados, setAgendamentosPassados] = useState([])
    const [rowId, setRowId] = useState(null);
    const [pageSize, setPageSize] = useState(5);
    const currentDate = new Date();

    useEffect(() => {
        const agendamentosRef = ref(database, 'Visitas');
        const todosAgendamentosQuery = query(agendamentosRef);

        get(todosAgendamentosQuery)
            .then((snapshot) => {
                const agendamentosData = [];

                snapshot.forEach((agendamentoSnapshot) => {
                    const agendamentoKey = agendamentoSnapshot.key;
                    const agendamentoData = agendamentoSnapshot.val();
                    const agendamentoDate = new Date(agendamentoData.checkIn);

                    const data = format(agendamentoDate, 'yyyy/MM/dd HH:mm');

                    // Verifica se o userId corresponde ao usuário selecionado
                    if ((isSameDay(agendamentoDate, currentDate) || agendamentoDate > currentDate)
                    ) {
                        const agendamentoPath = `Visitas/${agendamentoKey}`;
                        agendamentosData.push({
                            data,
                            agendamentoKey,
                            agendamentoPath,
                            ...agendamentoData,
                        });
                    }
                });

                // Ordena as reservas por data e 'CheckIn'
                agendamentosData.sort((a, b) => {
                    const dateA = new Date(a.checkIn);
                    const dateB = new Date(b.checkIn);

                    // Compara as datas
                    if (dateA < dateB) return -1;
                    if (dateA > dateB) return 1;

                    return 0; // Se tudo for igual, mantém a ordem original
                });

                setAgendamentos(agendamentosData); // Atualiza o estado com as reservas

            })
            .catch((error) => {
                console.error('Erro ao buscar as reservas:', error);
            });
    }, []);

    useEffect(() => {
        const agendamentosRef = ref(database, 'Visitas');
        const todosAgendamentosQuery = query(agendamentosRef);

        get(todosAgendamentosQuery)
            .then((snapshot) => {
                const agendamentosData = [];

                snapshot.forEach((agendamentoSnapshot) => {
                    const agendamentoKey = agendamentoSnapshot.key;
                    const agendamentoData = agendamentoSnapshot.val();
                    const agendamentoDate = new Date(agendamentoData.checkIn);

                    const data = format(agendamentoDate, 'yyyy/MM/dd HH:mm');

                    // Verifica se o userId corresponde ao usuário selecionado
                    if (agendamentoDate < currentDate) {
                        const agendamentoPath = `Visitas/${agendamentoKey}`;
                        agendamentosData.push({
                            data,
                            agendamentoKey,
                            agendamentoPath,
                            ...agendamentoData,
                        });
                    }
                });

                // Ordena as reservas por data e 'CheckIn'
                agendamentosData.sort((a, b) => {
                    const dateA = new Date(a.checkIn);
                    const dateB = new Date(b.checkIn);

                    // Compara as datas
                    if (dateA < dateB) return -1;
                    if (dateA > dateB) return 1;

                    return 0; // Se tudo for igual, mantém a ordem original
                });

                setAgendamentosPassados(agendamentosData); // Atualiza o estado com as reservas

            })
            .catch((error) => {
                console.error('Erro ao buscar as reservas:', error);
            });
    }, []);

    const columns = useMemo(
        () => [
            {
                field: 'data', headerName: 'Data', width: 150, editable: true, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {
                field: 'cowork', headerName: 'Cowork', width: 130, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {field: 'nome', headerName: 'Nome', width: 170, editable: false,},
            {
                field: 'email', headerName: 'Email', width: 210, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {
                field: 'contacto', headerName: 'Contacto', width: 100, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {
                field: 'motivacao', headerName: 'Motivação', width: 90, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {
                field: 'status',
                headerName: 'Estado',
                width: 130,
                type: 'singleSelect',
                valueOptions: ['Aceite', 'Pendente', 'Recusado', 'Faltou'],
                editable: true,
                headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center', // Alinha as células de dados à esquerda
            },
            {
                field: 'actions',
                headerName: 'Alterar',
                type: 'actions',
                headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center', // Alinha as células de dados à esquerda
                renderCell: (params) => (
                    <AlterarDataVisita {...{params, rowId, setRowId}} />
                ),
            },
            {
                field: 'actions2',
                headerName: 'Eliminar',
                type: 'actions2',
                headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center', // Alinha as células de dados à esquerda
                renderCell: (params) => (
                    <DeleteVisita {...{params, rowId, setRowId}} />
                ),
            },
        ],
        [rowId]
    );

    const columns2 = useMemo(
        () => [
            {
                field: 'data', headerName: 'Data', width: 150, editable: true, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {
                field: 'cowork', headerName: 'Cowork', width: 130, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {field: 'nome', headerName: 'Nome', width: 170, editable: false,},
            {
                field: 'email', headerName: 'Email', width: 210, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {
                field: 'contacto', headerName: 'Contacto', width: 100, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {
                field: 'motivacao', headerName: 'Motivação', width: 90, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {
                field: 'status',
                headerName: 'Estado',
                width: 130,
                type: 'singleSelect',
                valueOptions: ['Aceite', 'Pendente', 'Recusado', 'Faltou'],
                editable: true,
                headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center', // Alinha as células de dados à esquerda
            },
            {
                field: 'actions',
                headerName: 'Alterar',
                type: 'actions',
                headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center', // Alinha as células de dados à esquerda
                renderCell: (params) => (
                    <AlterarDataVisita {...{params, rowId, setRowId}} />
                ),
            },
            {
                field: 'actions2',
                headerName: 'Eliminar',
                type: 'actions2',
                headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center', // Alinha as células de dados à esquerda
                renderCell: (params) => (
                    <DeleteVisita {...{params, rowId, setRowId}} />
                ),
            },
        ],
        [rowId]
    );

  return (
      <div className="m-2 lg2:m-10 lg2:mt-24 mt-24 p-2 lg2:p-10 bg-white rounded-3xl">
      <Header category="Página" title="Agendamentos" />

          <Box
              sx={{
                  width: '100%',
                  display: 'lg2:flex',
                  flexDirection: 'column',
                  alignItems: 'center',
              }}
          >
              <Typography
                  variant="h3"
                  component="h3"
                  sx={{textAlign: 'center', mt: 3, mb: 3}}
              >
                  <h3 className={styles.h3}>Visitas Agendadas</h3>
              </Typography>
              <DataGrid
                  columns={columns}
                  rows={agendamentos}
                  getRowId={(row) => row.agendamentoKey}

                  rowsPerPageOptions={[5, 10, 20]}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  getRowSpacing={(params) => ({
                      top: params.isFirstVisible ? 0 : 5,
                      bottom: params.isLastVisible ? 0 : 5,
                  })}
                  sx={{
                      [`& .${gridClasses.row}`]: {
                          bgcolor: (theme) =>
                              theme.palette.mode === 'light' ? grey[200] : grey[900],
                      },
                  }}
                  onCellEditCommit={(params) => setRowId(params.id)}
              />
          </Box>

          <Box
              sx={{
                  width: '100%',
                  display: 'lg2:flex',
                  flexDirection: 'column',
                  alignItems: 'center',
              }}
          >
              <Typography
                  variant="h3"
                  component="h3"
                  sx={{textAlign: 'center', mt: 3, mb: 3}}
              >
                  <h3 className={styles.h3}>Agendamentos Passados</h3>
              </Typography>
              <DataGrid
                  columns={columns2}
                  rows={agendamentosPassados}
                  getRowId={(row) => row.agendamentoKey}

                  rowsPerPageOptions={[5, 10, 20]}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  getRowSpacing={(params) => ({
                      top: params.isFirstVisible ? 0 : 5,
                      bottom: params.isLastVisible ? 0 : 5,
                  })}
                  sx={{
                      [`& .${gridClasses.row}`]: {
                          bgcolor: (theme) =>
                              theme.palette.mode === 'light' ? grey[200] : grey[900],
                      },
                  }}
                  onCellEditCommit={(params) => setRowId(params.id)}
              />
          </Box>
    </div>
  );
};
export default Agendamentos;
