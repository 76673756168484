import React, {useEffect, useState} from 'react';
import {BsBoxSeam} from 'react-icons/bs';
import {IoIosMore} from 'react-icons/io';

import Button from './Button';
import {useStateContext} from '../contexts/ContextProvider';
import {MdOutlineSupervisorAccount} from "react-icons/md";
import {FiBarChart} from "react-icons/fi";
import styles from "../style";
import {get, query, ref} from "firebase/database";
import {database} from "../firebase";
import {cowork} from "../images";

const InicioAdmin = () => {
    const {currentColor} = useStateContext();

    const [users, setUsers] = useState([]);
    const [reservas, setReservas] = useState([])
    const [agendamentos, setAgendamentos] = useState([])

    useEffect(() => {
        const usersRef = ref(database, 'UserData/');
        const todosUsersQuery = query(usersRef);

        get(todosUsersQuery)
            .then((snapshot) => {
                const usersData = [];

                snapshot.forEach((userSnapshot) => {
                    const userKey = userSnapshot.key;
                    const userData = userSnapshot.val();

                    const userPath = `UserData/${userKey}`;
                    usersData.push({
                        userKey,
                        userPath,
                        ...userData,
                    });
                });

                setUsers(usersData);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        const reservasRef = ref(database, 'Reservas/');
        const todasReservasQuery = query(reservasRef);

        get(todasReservasQuery)
            .then((snapshot) => {
                const reservasData = [];

                snapshot.forEach((reservaSnapshot) => {
                    const reservaKey = reservaSnapshot.key;
                    const reservaData = reservaSnapshot.val();

                    const reservaPath = `Reservas/${reservaKey}`;
                        reservasData.push({
                            reservaKey,
                            reservaPath,
                            ...reservaData,
                        });
                    });

                setReservas(reservasData); // Atualiza o estado com as reservas
            })
            .catch((error) => {
                console.error('Erro ao buscar as reservas:', error);
            });
    }, []);

    useEffect(() => {
        const agendamentosRef = ref(database, 'Visitas');
        const todosAgendamentosQuery = query(agendamentosRef);

        get(todosAgendamentosQuery)
            .then((snapshot) => {
                const agendamentosData = [];

                snapshot.forEach((agendamentoSnapshot) => {
                    const agendamentoKey = agendamentoSnapshot.key;
                    const agendamentoData = agendamentoSnapshot.val();

                    const agendamentoPath = `Visitas/${agendamentoKey}`;
                        agendamentosData.push({
                            agendamentoKey,
                            agendamentoPath,
                            ...agendamentoData,
                        });
                    });

                setAgendamentos(agendamentosData); // Atualiza o estado com as reservas

            })
            .catch((error) => {
                console.error('Erro ao buscar as reservas:', error);
            });
    }, []);

    // contar o numero de utilizadores e guardar numa variável
    const numUsers = users.length;

    // contar o numero de reservas e guardar numa variável
    const numReservas = reservas.length;

    // contar o numero de agendamentos e guardar numa variável
    const numAgendamentos = agendamentos.length;

    return (
        <div className="mt-24">
            <div className="flex flex-wrap lg:flex-nowrap justify-center ">
                <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
                    <div
                        className="bg-white h-44 md:w-56  p-4 pt-9 rounded-2xl ">
                        <button
                            type="button"
                            style={{color: '#ECCB7B', backgroundColor: '#f6ecda'}}
                            className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                        >
                            <MdOutlineSupervisorAccount/>
                        </button>
                        <p className="mt-3">
                            <span className="text-lg text-black">{numUsers}</span>
                        </p>
                        <p className={`${styles.menu} text-black mt-1`}>Utilizadores</p>
                    </div>
                    <div
                        className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl ">
                        <button
                            type="button"
                            style={{color: '#ECCB7B', backgroundColor: '#f6ecda'}}
                            className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                        >
                            <FiBarChart/>
                        </button>
                        <p className="mt-3">
                            <span className="text-lg text-black">{numReservas}</span>
                        </p>
                        <p className={`${styles.menu} text-black mt-1`}>Reservas</p>
                    </div>
                    <div
                        className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl ">
                        <button
                            type="button"
                            style={{color: '#ECCB7B', backgroundColor: '#f6ecda'}}
                            className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                        >
                            <BsBoxSeam/>
                        </button>
                        <p className="mt-3">
                            <span className="text-lg text-black">{numAgendamentos}</span>
                        </p>
                        <p className={`${styles.menu} text-black mt-1`}>Visitas Agendadas</p>
                    </div>
                </div>
            </div>

            <div className="flex flex-wrap justify-center">
                <div className="w-400 bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3">
                    <div className="flex justify-between">
                        <p className={`${styles.h3} text-black mt-1`}>Atividades Diárias</p>
                        <button type="button" className="text-xl font-semibold text-gray-500">
                            <IoIosMore/>
                        </button>
                    </div>
                    <div className="mt-10">
                        <img
                            className="md:w-96 h-50 "
                            src={cowork}
                            alt=""
                        />
                        <div className="mt-8">
                            <p className={`${styles.h3} text-black mt-1`}>Preparar Registo de Entradas e Saídas</p>
                            <p className="text-gray-400 ">Por Gilberto Matias</p>
                            <p className="mt-8 text-sm text-black">
                                Pretende-se conseguir guardar o registo de entrada e saída de cada utilizador do
                                espaço de Cowork.
                            </p>
                            <div className="mt-3">
                                <Button
                                    color="white"
                                    bgColor={currentColor}
                                    text="Saber Mais"
                                    borderRadius="10px"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InicioAdmin;
