import { Component } from "react";
import "./Destination.css"
import Destino1 from "../images/coudelaria.jpg";
import Destino2 from "../images/padel.jpg";

class DestinationData extends Component {
    render() {
        return (
            <div className={this.props.cn}>
                <div className="des-text">
                    <h2>{this.props.heading}</h2>
                    <p>{this.props.text}</p>
                </div>

                <div className="image">
                    <img alt="img" src={this.props.img1}/>
                    <img alt="img" src={this.props.img2}/>
                </div>
            </div>
        );
    }
}

export default DestinationData;