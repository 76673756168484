import "./Hero.css";

import styles from "../../../style";
import {header} from "../../../images";
import VisitaButton from "../VisitaButton";

import Divisorio from "../Divisorio";
import ReservarButton from "../ReservarButton";

const Hero = ({handleOptionChange, authUser, cName, text, heroImg, buttonText, url, btnClass, title}) => {
    return (
        <div className={cName}>
            <img alt="HerpImg"
                 src={heroImg}/>
            <div className="hero-text">
                <h1>{title}</h1>
                <p>{text}</p>
                <a href={url} className={btnClass}>{buttonText}</a>
            </div>
        </div>

        /*
        <section id="inicio" className={`flex md:flex-row flex-col ${styles.paddingY}`}>
            <div className={`flex-1 ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6`}>
                <Divisorio styles={"px-8"}/>
                <div className="flex flex-row justify-between items-center w-full">
                    <h1 className="flex-1 saira-condensed-bold ss:text-[130px] text-[52px] text-white ss:leading-[100.8px] leading-[75px]">
                        COWORK <br className="sm:block hidden"/>{" "}
                        <h1 className="flex-1 saira-condensed-bold text-gradient ss:text-[130px] text-[52px] text-white ss:leading-[100.8px] leading-[75px] mt-4">
                            Fundão
                        </h1>
                    </h1>
                </div>

                <h1 className={`${styles.menu} text-white ss:leading-[100.8px] leading-[75px] w-full`}>
                    O ESPAÇO QUE TU PROCURAS.
                </h1>
                <div className="ss:flex hidden ml-20">
                    <div className="agendar-visita">
                        {authUser ? <>
                            <a onClick={() => handleOptionChange("Reservar")}><ReservarButton/></a>
                        </> : <>
                            <a onClick={() => handleOptionChange("Visita")}><VisitaButton/></a>
                        </>}
                    </div>
                </div>
            </div>

            <div className={`flex-1 flex ${styles.flexCenter} md:my-0 my-10 relative`}>
                <img src={header} alt="billing" className="w-[90%] h-[90%] relative z-[5]"/>

         */

        /* gradient start */
        /*
                <div className="absolute z-[0] w-[40%] h-[35%] top-0 gold__gradient"/>
                <div className="absolute z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-40"/>
                <div className="absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 burlyWood__gradient"/>

         */
        /* gradient end */
        /*
            </div>

            <div className={`ss:hidden ${styles.flexCenter}`}>
                {authUser ? <>
                    <a onClick={() => handleOptionChange("Reservar")}><ReservarButton/></a>
                </> : <>
                    <a onClick={() => handleOptionChange("Visita")}><VisitaButton/></a>
                </>}
            </div>
        </section>

         */
    );
};

export default Hero;
