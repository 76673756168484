import "./styles.css"

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import LandingPage from "./pages/LandingPage";
import ErrorPage from "./pages/ErrorPage";
import {useEffect, useState} from "react";
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "./firebase";
import ResetPassword from "./pages/ResetPassword";

import './pages/App.css';
import {Helmet} from "react-helmet";

const App = () => {
    const [authUser, setAuthUser] = useState(null);

    useEffect(() => {
        const listen = onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthUser(user);
            } else {
                setAuthUser(null);
            }
        });

        return () => {
            listen();
        }
    }, []);

    return (
        <div className="App">
            <Router>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>TURISMO - Alter do Chão</title>
                    <link rel="canonical" href="http://mysite.com/example"/>
                    <meta name="description" content="Aplicação Turismo"/>
                </Helmet>
                <Routes>
                    <Route exact path="/" element={<LandingPage/>}/>
                    {!authUser && <Route exact path="/reset-password" element={<ResetPassword/>}/>}
                    <Route path="*" element={<ErrorPage/>}/>
                </Routes>
            </Router>
        </div>
    );
};

export default App;
