import {onAuthStateChanged, signOut} from "firebase/auth";
import React, {useEffect, useState} from "react";
import {auth} from "../firebase";

import {closeVerde, logo, menuVerde, settings} from "../images";

import styles from "../style";

const NavbarAdmin = ({adminOptionChange, handleOptionChange, nome}) => {
    const [toggle, setToggle] = useState(false);
    const [setting, setSetting] = useState(false);
    const [authUser, setAuthUser] = useState(null);

    useEffect(() => {
        const listen = onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthUser(user);
            } else {
                setAuthUser(null);
            }
        });

        return () => {
            listen();
        }
    }, []);
    const userSignOut = () => {
        signOut(auth).then(() => {
            setAuthUser(null);
            handleOptionChange("Inicio");
            adminOptionChange("Inicio");
            // atualizar pagina
            window.location.reload();
        }).catch(error => console.log(error))
    }

    return (
        <nav className="w-full flex py-6 justify-between items-center navbar">
            <div className="ml-5">
                <img
                    src={setting ? closeVerde : settings}
                    alt="settings"
                    className="w-[28px] h-[28px] object-contain mr-8"
                    onClick={() => setSetting(!setting)}
                />

                <div
                    className={`${
                        !setting ? "hidden" : "flex"
                    } p-6 bg-verdeTropa absolute top-16 left-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}
                >
                    <ul className="list-none flex justify-end items-start flex-1 flex-col">
                        <div className={`${styles.menu} cursor-pointer mt-4`}>
                            <li className={"text-dimWhite mb-1 saira-condensed-bold text-[18px] uppercase hover:text-white"}
                                onClick={() => adminOptionChange("Inicio")}>
                                Inicio
                            </li>
                            <li className={"text-dimWhite mb-1 saira-condensed-bold text-[18px] uppercase hover:text-white"}
                                onClick={() => adminOptionChange("Clientes")}>
                                Clientes
                            </li>
                            <li className={"text-dimWhite mb-1 saira-condensed-bold text-[18px] uppercase hover:text-white"}
                                onClick={() => adminOptionChange("Criar")}>
                                Criar Utilizadores
                            </li>
                            <li className={"text-dimWhite mb-1 saira-condensed-bold text-[18px] uppercase hover:text-white"}
                                onClick={() => adminOptionChange("Reservas")}>
                                Reservas
                            </li>
                            <li className={"text-dimWhite mb-1 saira-condensed-bold text-[18px] uppercase hover:text-white"}
                                onClick={() => adminOptionChange("Agendamentos")}>
                                Agendamentos
                            </li>
                            <li className={"text-dimWhite mb-1 saira-condensed-bold text-[18px] uppercase hover:text-white"}
                                onClick={() => adminOptionChange("Planta")}>
                                Planta
                            </li>
                            <li className={"text-dimWhite mb-1 saira-condensed-bold text-[18px] uppercase hover:text-white"}
                                onClick={() => adminOptionChange("Inicio")}>
                                Inicio
                            </li>
                        </div>
                    </ul>
                </div>
            </div>
            <a href="/" className="ml-3">
                <img src={logo} alt="logo" className="w-[124px] h-[32px]"/>
            </a>
            <ul className="list-none nb:flex hidden justify-end items-center flex-1">
                <div className={`${styles.menu} list-none nb:flex hidden justify-end items-center`}>
                    <li className={"font-poppins font-normal text-black text-[18px] uppercase mr-12"}>
                        Bem-Vindo Administrador <strong>{nome}</strong>.
                    </li>
                    <li className={"text-gray-500 saira-condensed-bold text-[18px] uppercase hover:text-black mr-11 cursor-pointer"}
                        onClick={() => adminOptionChange("Inicio")}>
                        Inicio
                    </li>
                    <button
                        className={"py-0.5 px-5 verdeTropa bg-softGrey text-white saira-condensed-bold text-[18px] uppercase rounded-[10px] button-burlyWood mr-11 cursor-pointer"}
                        onClick={() => adminOptionChange("Clientes")}>
                        Clientes
                    </button>
                    <button
                        className={"py-0.5 px-5 verdeTropa bg-softGrey text-white saira-condensed-bold text-[18px] uppercase rounded-[10px] button-burlyWood mr-11 cursor-pointer"}
                        onClick={() => adminOptionChange("Reservas")}>
                        Reservas
                    </button>
                    <button onClick={userSignOut}
                            className={"py-0.5 px-5 verdeTropa bg-softGrey text-white saira-condensed-bold text-[18px] uppercase rounded-[10px] button-burlyWood mr-11 cursor-pointer"}>
                        Sair
                    </button>
                </div>
            </ul>

            <div className="nb:hidden flex flex-1 justify-end items-center">
                <img
                    src={toggle ? closeVerde : menuVerde}
                    alt="menu"
                    className="w-[28px] h-[28px] object-contain mr-8"
                    onClick={() => setToggle(!toggle)}
                />

                <div
                    className={`${
                        !toggle ? "hidden" : "flex"
                    } p-6 bg-verdeTropa absolute top-16 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}
                >
                    <ul className="list-none flex justify-end items-start flex-1 flex-col">
                        <div className={`${styles.menu} cursor-pointer mt-4`}>
                            <li className={"text-dimWhite mb-1 saira-condensed-bold text-[18px] uppercase hover:text-white"}
                                onClick={() => adminOptionChange("Inicio")}>
                                Inicio
                            </li>
                            <button
                                className={"py-0.5 px-5 mb-1 verdeTropa bg-burlyWood text-white saira-condensed-bold text-[18px] uppercase rounded-[10px] button-softGrey mr-11"}
                                onClick={() => adminOptionChange("Clientes")}>
                                Clientes
                            </button>
                            <button
                                className={"py-0.5 px-5 mb-1 verdeTropa bg-burlyWood text-white saira-condensed-bold text-[18px] uppercase rounded-[10px] button-softGrey mr-11"}
                                onClick={() => adminOptionChange("Reservas")}>
                                Reservas
                            </button>
                            <button onClick={userSignOut}
                                    className={"py-0.5 px-5 mb-1 verdeTropa bg-softGrey text-white saira-condensed-bold text-[18px] uppercase rounded-[10px] button-burlyWood"}>
                                Sair
                            </button>
                        </div>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default NavbarAdmin;
