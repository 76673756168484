import React from 'react';

import Header from './Header';
import FormCriarUser from "./FormCriarUser";

const CriarUser = () => {

  return (
      <div className="m-2 lg2:m-10 lg2:mt-24 mt-24 p-2 lg2:p-10 bg-white rounded-3xl">
      <Header category="Página" title="Criar Utilizadores" />
          <FormCriarUser />
    </div>
  );
};
export default CriarUser;
