import Header from './Header';
import React, {useEffect, useMemo, useState} from "react";
import {Avatar, Box, Typography} from "@mui/material";
import {DataGrid, gridClasses} from "@mui/x-data-grid";
import {get, query, ref} from "firebase/database";
import {database} from "../firebase";

import moment from 'moment';
import {grey} from "@mui/material/colors";
import AlterarDataUser from "./AlterarDataUser";
import styles from "../style";
import DeleteUser from "./DeleteUser";

const Clientes = () => {
        const [clientes, setClientes] = useState("")
        const [users, setUsers] = useState([]);

        const paragrafosFixo = [
            <p
                key={'Não'}
                style={{background: 'rgb(228, 106, 118)'}}
                className="cursor-pointer hover:drop-shadow-xl text-white py-0.5 px-3 rounded-lg text-xs"
            >
                Não
            </p>,
            <p
                key={'Pedido'}
                style={{background: 'rgb(254, 201, 15)'}}
                className="cursor-pointer hover:drop-shadow-xl text-white py-0.5 px-3 rounded-lg text-xs"
            >
                Pedido
            </p>,
            <p
                key={'Aceite'}
                style={{background: 'rgb(74 222 128)'}}
                className="cursor-pointer hover:drop-shadow-xl text-white py-0.5 px-3 rounded-lg text-xs"
            >
                Aceite
            </p>
        ];

        const [pageSize, setPageSize] = useState(5);
        const [rowId, setRowId] = useState(null);

        useEffect(() => {
            const usersRef = ref(database, 'UserData/');
            const todosUsersQuery = query(usersRef);

            get(todosUsersQuery)
                .then((snapshot) => {
                    const usersData = [];

                    snapshot.forEach((userSnapshot) => {
                        const userKey = userSnapshot.key;
                        const userData = userSnapshot.val();

                        let fixo = "Não";

                        if (userData.tipo === "Fixo" && userData.aceite === false) {
                            fixo = "Pedido";
                        } else if (userData.tipo === "Fixo" && userData.aceite === true) {
                            fixo = "Aceite";
                        }

                        const userPath = `UserData/${userKey}`;
                        usersData.push({
                            userKey,
                            userPath,
                            ...userData,
                            fixo,
                        });
                    });

                    setUsers(usersData);
                })
                .catch((error) => {
                    console.error(error);
                });
        }, []);

        const columns = useMemo(
            () => [
                {
                    field: 'profileImage',
                    headerName: '',
                    width: 60,
                    renderCell: (params) => <Avatar src={params.row.profileImage}/>,
                    sortable: false,
                    filterable: false,
                    headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                    align: 'center', // Alinha as células de dados à esquerda
                },
                {field: 'nome', headerName: 'Nome', width: 270, editable: true,},
                {
                    field: 'tipo',
                    headerName: 'Tipo',
                    width: 100,
                    type: 'singleSelect',
                    valueOptions: ['Temporario', 'Fixo', 'Admin'],
                    editable: true,
                    headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                    align: 'center', // Alinha as células de dados à esquerda
                },
                {
                    field: 'escolha',
                    headerName: 'Lugar',
                    width: 80,
                    type: 'singleSelect',
                    valueOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29],
                    editable: true,
                    headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                    align: 'center', // Alinha as células de dados à esquerda
                },
                {
                    headerName: 'Fixo',
                    width: 80,
                    headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                    align: 'center', // Alinha as células de dados à esquerda
                    renderCell: (params) => {
                        const value = params.row.fixo;
                        const paragraph = paragrafosFixo.find((item) => item.key === value);

                        return paragraph || null;
                    },
                },

                {
                    field: 'aceite',
                    headerName: 'Aceitar',
                    width: 100,
                    type: 'boolean',
                    editable: true,
                    headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                    align: 'center', // Alinha as células de dados à esquerda
                },
                {
                    field: 'cancelamento',
                    headerName: 'Cancelar Contrato',
                    width: 140,
                    type: 'singleSelect',
                    valueOptions: ['Recusado', 'Aceite'],
                    editable: true,
                    headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                    align: 'center', // Alinha as células de dados à esquerda
                },

                {
                    field: 'actions',
                    headerName: 'Atualizar',
                    type: 'actions',
                    headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                    align: 'center', // Alinha as células de dados à esquerda
                    renderCell: (params) => (
                        <AlterarDataUser {...{params, rowId, setRowId}} />
                    ),
                },
                {
                    field: 'actions2',
                    headerName: 'Eliminar',
                    type: 'actions2',
                    headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                    align: 'center', // Alinha as células de dados à esquerda
                    renderCell: (params) => (
                        <DeleteUser {...{params, rowId, setRowId}} />
                    ),
                },
            ],
            [rowId]
        );

        const columns2 = useMemo(
            () => [
                {
                    field: 'profileImage',
                    headerName: '',
                    width: 60,
                    renderCell: (params) => <Avatar src={params.row.profileImage}/>,
                    sortable: false,
                    filterable: false,
                    headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                    align: 'center', // Alinha as células de dados à esquerda
                },
                {field: 'nome', headerName: 'Nome', width: 170, editable: true,},
                {field: 'email', headerName: 'Email', width: 200, editable: true,},
                {field: 'contacto', headerName: 'Contacto', width: 100, editable: true,headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                    align: 'center',},
                {
                    field: 'dataNascimento',
                    headerName: 'D. Nascimento',
                    width: 110,
                    headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                    align: 'center', // Alinha as células de dados à esquerda
                    renderCell: (params) =>
                        moment(params.row.createdAt).format('DD-MM-YYYY'),
                },
                {field: 'linkedin', headerName: 'Linkedin', width: 100, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                    align: 'center',},
                {field: 'curriculo', headerName: 'Currículo', width: 100, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                    align: 'center',},
                {
                    field: 'actions',
                    headerName: 'Atualizar',
                    type: 'actions',
                    headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                    align: 'center', // Alinha as células de dados à esquerda
                    renderCell: (params) => (
                        <AlterarDataUser {...{params, rowId, setRowId}} />
                    ),
                },
                {
                    field: 'actions2',
                    headerName: 'Eliminar',
                    type: 'actions2',
                    headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                    align: 'center', // Alinha as células de dados à esquerda
                    renderCell: (params) => (
                        <DeleteUser {...{params, rowId, setRowId}} />
                    ),
                },
            ],
            [rowId]
        );

        const columns3 = useMemo(
            () => [
                {
                    field: 'profileImage',
                    headerName: '',
                    width: 60,
                    renderCell: (params) => <Avatar src={params.row.profileImage}/>,
                    sortable: false,
                    filterable: false,
                    headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                    align: 'center', // Alinha as células de dados à esquerda
                },
                {field: 'nome', headerName: 'Nome', width: 170, editable: true,},
                {field: 'nif', headerName: 'NIF', width: 100, editable: true,headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                    align: 'center',},
                {field: 'cp', headerName: 'Código-Postal', width: 120, editable: true,headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                    align: 'center'},
                {field: 'morada', headerName: 'Morada', width: 250, editable: true,},
                {field: 'localidade', headerName: 'Localidade', width: 140, editable: true,headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                    align: 'center'},
                {
                    field: 'actions',
                    headerName: 'Atualizar',
                    type: 'actions',
                    headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                    align: 'center', // Alinha as células de dados à esquerda
                    renderCell: (params) => (
                        <AlterarDataUser {...{params, rowId, setRowId}} />
                    ),
                },
                {
                    field: 'actions2',
                    headerName: 'Eliminar',
                    type: 'actions2',
                    headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                    align: 'center', // Alinha as células de dados à esquerda
                    renderCell: (params) => (
                        <DeleteUser {...{params, rowId, setRowId}} />
                    ),
                },
            ],
            [rowId]
        );

        const search = (rows) => {
            return rows.filter(
                (row) =>
                    row.nome.toLowerCase().indexOf(clientes.toLowerCase()) > -1 ||
                    row.email.toLowerCase().indexOf(clientes.toLowerCase()) > -1 ||
                    row.linkedin.toLowerCase().indexOf(clientes.toLowerCase()) > -1 ||
                    row.curriculo.toLowerCase().indexOf(clientes.toLowerCase()) > -1 ||
                    row.cp.toLowerCase().indexOf(clientes.toLowerCase()) > -1 ||
                    row.morada.toLowerCase().indexOf(clientes.toLowerCase()) > -1 ||
                    row.localidade.toLowerCase().indexOf(clientes.toLowerCase()) > -1
            );
        };

    const filteredRows = useMemo(() => search(users), [users, clientes]);

        return (
            <div className="m-2 lg2:m-10 lg2:mt-24 mt-24 p-2 lg2:p-10 bg-white rounded-3xl">
                <Header category="Página" title="Clientes"/>

                <input type="text" className="flex w-full border-2 border-gray-300 rounded-md p-2 mt-2"
                       placeholder="Pesquisar por Cliente" onChange={(e) => setClientes(e.target.value)}/>
                <Box
                    sx={{
                        maxHeight: 800,
                        width: '100%',
                        display: 'lg2:flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant="h3"
                        component="h3"
                        sx={{textAlign: 'center', mt: 3, mb: 3}}
                    >
                        <h3 className={styles.h3}>Visão Geral dos Clientes</h3>
                    </Typography>
                    <DataGrid
                        columns={columns}
                        rows={filteredRows}
                        getRowId={(row) => row.userKey}

                        rowsPerPageOptions={[5, 10, 20]}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        getRowSpacing={(params) => ({
                            top: params.isFirstVisible ? 0 : 5,
                            bottom: params.isLastVisible ? 0 : 5,
                        })}
                        sx={{
                            [`& .${gridClasses.row}`]: {
                                bgcolor: (theme) =>
                                    theme.palette.mode === 'light' ? grey[200] : grey[900],
                            },
                        }}
                        onCellEditCommit={(params) => setRowId(params.id)}
                    />
                </Box>

                <Box
                    sx={{
                        width: '100%',
                        display: 'lg2:flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant="h3"
                        component="h3"
                        sx={{textAlign: 'center', mt: 3, mb: 3}}
                    >
                        <h3 className={styles.h3}>Dados Pessoais</h3>
                    </Typography>
                    <DataGrid
                        columns={columns2}
                        rows={filteredRows}
                        getRowId={(row) => row.userKey}
                        rowsPerPageOptions={[5, 10, 20]}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        getRowSpacing={(params) => ({
                            top: params.isFirstVisible ? 0 : 5,
                            bottom: params.isLastVisible ? 0 : 5,
                        })}
                        sx={{
                            [`& .${gridClasses.row}`]: {
                                bgcolor: (theme) =>
                                    theme.palette.mode === 'light' ? grey[200] : grey[900],
                            },
                        }}
                        onCellEditCommit={(params) => setRowId(params.id)}
                    />
                </Box>

                <Box
                    sx={{
                        width: '100%',
                        display: 'lg2:flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant="h3"
                        component="h3"
                        sx={{textAlign: 'center', mt: 3, mb: 3}}
                    >
                        <h3 className={styles.h3}>Dados Fiscais</h3>
                    </Typography>
                    <DataGrid
                        columns={columns3}
                        rows={filteredRows}
                        getRowId={(row) => row.userKey}
                        rowsPerPageOptions={[5, 10, 20]}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        getRowSpacing={(params) => ({
                            top: params.isFirstVisible ? 0 : 5,
                            bottom: params.isLastVisible ? 0 : 5,
                        })}
                        sx={{
                            [`& .${gridClasses.row}`]: {
                                bgcolor: (theme) =>
                                    theme.palette.mode === 'light' ? grey[200] : grey[900],
                            },
                        }}
                        onCellEditCommit={(params) => setRowId(params.id)}
                    />
                </Box>
            </div>
        );
    }
;
export default Clientes;
