import React from 'react';
import { NavLink } from 'react-router-dom';
import { links } from "../constants";

import { useStateContext } from '../contexts/ContextProvider';

const Sidebar = ({adminOptionChange}) => {
  const { currentColor } = useStateContext();
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [selectedLinkIndex, setLinkIndex] = React.useState(0);

  const activeLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2';
  const normalLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 hover:bg-light-gray m-2';

  const optionChange = (index, linkIndex, name) => () => {
    setSelectedIndex(index);
    setLinkIndex(linkIndex);
    adminOptionChange(name);
  };

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      <div className="flex justify-between items-center">
            <a className="text-black items-center gap-3 ml-3 mt-2 flex text-xl font-extrabold tracking-tight">
              <span>Cowork Fundão Admin</span>
            </a>

          </div>
          <div className="mt-3 ">
            {links.map((item, index) => (
              <div key={item.title}>
                <p className="text-gray-400 m-3 mt-4 uppercase">
                  {item.title}
                </p>
                {item.links.map((link, linkIndex) => (
                  <NavLink
                    key={link.name}
                    onClick={optionChange(index, linkIndex, `${link.name}`)}
                    style={({ isActive }) => ({
                      backgroundColor: (selectedIndex === index && selectedLinkIndex === linkIndex) ? currentColor : '#f8eed5',
                    })}
                    className={selectedIndex === index ? activeLink : normalLink}
                   to={""}>
                    {link.icon}
                    <span className="capitalize text-black">{link.name}</span>
                  </NavLink>
                ))}
              </div>
            ))}
          </div>
    </div>
  );
};

export default Sidebar;
