import React from 'react';

import Header from './Header';
import styles, {layout} from "../style";
import {planta} from "../images";

const Planta = () => {


  return (
      <div className="m-2 lg2:m-10 lg2:mt-24 mt-24 p-2 lg2:p-10 bg-white rounded-3xl">
      <Header category="Página" title="Planta" />
          <div className={`${styles.flexCenter}`}>
              <div className={`${styles.boxWidth}`}>
                  <section id="app" className={`${layout.sectionReverse}`}>
                      <div className={`flex-1 flex ${styles.flexCenter} md:my-0 my-0 relative ml-4 mr-4`}>
                          <img src={planta} alt="billing" className="w-[100%] h-[100%] relative z-[3] rounded-[10px]"/>
                      </div>
                  </section>
              </div>
          </div>

    </div>
  );
};
export default Planta;
