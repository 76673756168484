// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDM7o_e65PPSkBMmcsXYmO9RnH26tS6ZYk",
    authDomain: "cowork-fundao.firebaseapp.com",
    databaseURL: "https://cowork-fundao-default-rtdb.firebaseio.com",
    projectId: "cowork-fundao",
    storageBucket: "cowork-fundao.appspot.com",
    messagingSenderId: "894018113418",
    appId: "1:894018113418:web:b10568e8010ab76b2f4273",
    measurementId: "G-6SCHWTEV07"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Authentification and get a reference to the database service
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const database = getDatabase(app);