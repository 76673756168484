import React, {useEffect, useMemo, useState} from 'react';

import Header from './Header';
import {Box, Typography} from "@mui/material";
import styles from "../style";
import {DataGrid, gridClasses} from "@mui/x-data-grid";
import {grey} from "@mui/material/colors";
import {get, query, ref} from "firebase/database";
import {database} from "../firebase";
import {isSameDay} from "date-fns";
import DeleteReserva from "./DeleteReserva";

const Reservas = () => {
    const [reservas, setReservas] = useState([])
    const [reservasPassadas, setReservasPassadas] = useState([])
    const [rowId, setRowId] = useState(null);
    const [pageSize, setPageSize] = useState(5);
    const currentDate = new Date();

    useEffect(() => {
        const reservasRef = ref(database, 'Reservas/');
        const todasReservasQuery = query(reservasRef);

        get(todasReservasQuery)
            .then((snapshot) => {
                const reservasData = [];

                snapshot.forEach((reservaSnapshot) => {
                    const reservaKey = reservaSnapshot.key;
                    const reservaData = reservaSnapshot.val();
                    const reservaDate = new Date(reservaData.data);

                    // Verifica se o userId corresponde ao usuário selecionado
                    if ((isSameDay(reservaDate, currentDate) || reservaDate > currentDate)
                    ) {
                        const reservaPath = `Reservas/${reservaKey}`;
                        reservasData.push({
                            reservaKey,
                            reservaPath,
                            ...reservaData,
                        });
                    }
                });

                // Ordena as reservas por data e 'CheckIn'
                reservasData.sort((a, b) => {
                    const dateA = new Date(a.data);
                    const dateB = new Date(b.data);

                    // Compara as datas
                    if (dateA < dateB) return -1;
                    if (dateA > dateB) return 1;

                    // Se as datas forem iguais, compara o 'CheckIn'
                    const checkInA = a.CheckIn.split(':');
                    const checkInB = b.CheckIn.split(':');

                    const hourA = parseInt(checkInA[0]);
                    const minuteA = parseInt(checkInA[1]);
                    const hourB = parseInt(checkInB[0]);
                    const minuteB = parseInt(checkInB[1]);

                    if (hourA < hourB) return -1;
                    if (hourA > hourB) return 1;
                    if (minuteA < minuteB) return -1;
                    if (minuteA > minuteB) return 1;

                    return 0; // Se tudo for igual, mantém a ordem original
                });

                setReservas(reservasData); // Atualiza o estado com as reservas

                if (reservasData.length === 0) {
                   console.log("Não existem reservas para hoje")
                } else {
                    buscarUsers(reservasData);
                }

            })
            .catch((error) => {
                console.error('Erro ao buscar as reservas:', error);
            });
    }, []);

    useEffect(() => {
        const reservasRef = ref(database, 'Reservas/');
        const todasReservasQuery = query(reservasRef);

        get(todasReservasQuery)
            .then((snapshot) => {
                const reservasData = [];

                snapshot.forEach((reservaSnapshot) => {
                    const reservaKey = reservaSnapshot.key;
                    const reservaData = reservaSnapshot.val();
                    const reservaDate = new Date(reservaData.data);

                    // Verifica se o userId corresponde ao usuário selecionado
                    if (reservaDate < currentDate) {
                        const reservaPath = `Reservas/${reservaKey}`;
                        reservasData.push({
                            reservaKey,
                            reservaPath,
                            ...reservaData,
                        });
                    }
                });

                // Ordena as reservas por data e 'CheckIn'
                reservasData.sort((a, b) => {
                    const dateA = new Date(a.data);
                    const dateB = new Date(b.data);

                    // Compara as datas
                    if (dateA < dateB) return -1;
                    if (dateA > dateB) return 1;

                    // Se as datas forem iguais, compara o 'CheckIn'
                    const checkInA = a.CheckIn.split(':');
                    const checkInB = b.CheckIn.split(':');

                    const hourA = parseInt(checkInA[0]);
                    const minuteA = parseInt(checkInA[1]);
                    const hourB = parseInt(checkInB[0]);
                    const minuteB = parseInt(checkInB[1]);

                    if (hourA < hourB) return -1;
                    if (hourA > hourB) return 1;
                    if (minuteA < minuteB) return -1;
                    if (minuteA > minuteB) return 1;

                    return 0; // Se tudo for igual, mantém a ordem original
                });

                setReservasPassadas(reservasData); // Atualiza o estado com as reservas

                if (reservasData.length === 0) {
                    console.log("Não existem reservas")
                } else {
                    buscarUsers2(reservasData);
                }
            })
            .catch((error) => {
                console.error('Erro ao buscar as reservas:', error);
            });
    }, []);

    const buscarUsers = (reserv) => {
        const promises = reserv.map((reserva) => {
            const userRef = ref(database, `UserData/${reserva.userId}`);
            return get(userRef).then((snapshot) => {
                const userData = snapshot.val();
                const userKey = snapshot.key;
                const userPath = `UserData/${userKey}`;
                const user = {
                    userKey,
                    userPath,
                    ...userData,
                };

                return { ...reserva, nome: user.nome };
            });
        });

        Promise.all(promises)
            .then((updatedReservas) => {
                setReservas(updatedReservas);
            })
            .catch((error) => {
                console.error('Erro ao buscar os usuários:', error);
            });
    };

    const buscarUsers2 = (reserv) => {
        const promises = reserv.map((reserva) => {
            const userRef = ref(database, `UserData/${reserva.userId}`);
            return get(userRef).then((snapshot) => {
                const userData = snapshot.val();
                const userKey = snapshot.key;
                const userPath = `UserData/${userKey}`;
                const nome = userData.nome;
                const user = {
                    userKey,
                    userPath,
                    ...userData,
                };

                return { nome, ...user };
            });
        });

        Promise.all(promises)
            .then((usersData) => {
                const updatedReservas = reserv.map((reserva, index) => ({
                    ...reserva,
                    ...usersData[index],
                }));
                setReservasPassadas(updatedReservas);
            })
            .catch((error) => {
                console.error('Erro ao buscar os usuários:', error);
            });
    };


    const columns = useMemo(
        () => [
            {
                field: 'data', headerName: 'Data', width: 100, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {
                field: 'cowork', headerName: 'Cowork', width: 130, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {field: 'nome', headerName: 'Nome', width: 150, editable: false,},
            {
                field: 'lugar', headerName: 'Lugar', width: 70, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {
                field: 'CheckIn', headerName: 'Check-In', width: 80, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {
                field: 'entrada', headerName: 'Entrada', width: 80, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {
                field: 'CheckOut', headerName: 'Check-Out', width: 90, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {
                field: 'saida', headerName: 'Saída', width: 100, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {
                field: 'status', headerName: 'Estado', width: 120, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {
                field: 'aviso', headerName: 'Aviso', width: 140, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {
                field: 'actions',
                headerName: 'Eliminar',
                type: 'actions',
                headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center', // Alinha as células de dados à esquerda
                renderCell: (params) => (
                    <DeleteReserva {...{params, rowId, setRowId}} />
                ),
            },
        ],
        [rowId]
    );

    const columns2 = useMemo(
        () => [
            {
                field: 'data', headerName: 'Data', width: 100, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {
                field: 'cowork', headerName: 'Cowork', width: 130, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {field: 'nome', headerName: 'Nome', width: 150, editable: false,},
            {
                field: 'lugar', headerName: 'Lugar', width: 70, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {
                field: 'CheckIn', headerName: 'Check-In', width: 80, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {
                field: 'entrada', headerName: 'Entrada', width: 80, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {
                field: 'CheckOut', headerName: 'Check-Out', width: 90, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {
                field: 'saida', headerName: 'Saída', width: 100, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {
                field: 'status', headerName: 'Estado', width: 120, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {
                field: 'aviso', headerName: 'Aviso', width: 140, editable: false, headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center'
            },
            {
                field: 'actions',
                headerName: 'Eliminar',
                type: 'actions',
                headerAlign: 'center', // Alinha o cabeçalho da coluna à esquerda
                align: 'center', // Alinha as células de dados à esquerda
                renderCell: (params) => (
                    <DeleteReserva {...{params, rowId, setRowId}} />
                ),
            },

        ],
        [rowId]
    );

    return (
        <div className="m-2 lg2:m-10 lg2:mt-24 mt-24 p-2 lg2:p-10 bg-white rounded-3xl">
            <Header category="Página" title="Reservas"/>

            <Box
                sx={{
                    width: '100%',
                    display: 'lg2:flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography
                    variant="h3"
                    component="h3"
                    sx={{textAlign: 'center', mt: 3, mb: 3}}
                >
                    <h3 className={styles.h3}>Reservas Atuais</h3>
                </Typography>
                <DataGrid
                    columns={columns}
                    rows={reservas}
                    getRowId={(row) => row.reservaKey}

                    rowsPerPageOptions={[5, 10, 20]}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    getRowSpacing={(params) => ({
                        top: params.isFirstVisible ? 0 : 5,
                        bottom: params.isLastVisible ? 0 : 5,
                    })}
                    sx={{
                        [`& .${gridClasses.row}`]: {
                            bgcolor: (theme) =>
                                theme.palette.mode === 'light' ? grey[200] : grey[900],
                        },
                    }}
                    onCellEditCommit={(params) => setRowId(params.id)}
                />
            </Box>

            <Box
                sx={{
                    width: '100%',
                    display: 'lg2:flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography
                    variant="h3"
                    component="h3"
                    sx={{textAlign: 'center', mt: 3, mb: 3}}
                >
                    <h3 className={styles.h3}>Histórico de Reservas</h3>
                </Typography>
                <DataGrid
                    columns={columns2}
                    rows={reservasPassadas}
                    getRowId={(row) => row.reservaKey}

                    rowsPerPageOptions={[5, 10, 20]}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    getRowSpacing={(params) => ({
                        top: params.isFirstVisible ? 0 : 5,
                        bottom: params.isLastVisible ? 0 : 5,
                    })}
                    sx={{
                        [`& .${gridClasses.row}`]: {
                            bgcolor: (theme) =>
                                theme.palette.mode === 'light' ? grey[200] : grey[900],
                        },
                    }}
                    onCellEditCommit={(params) => setRowId(params.id)}
                />
            </Box>
        </div>
    );
};
export default Reservas;
